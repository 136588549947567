import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import AuthStore from '../../components/common/AuthStore';
import { API_BASE_PATH as baseUrl } from '../../constants/env';
import Endpoints from '../../Endpoints';
const { idToken } = AuthStore;

const staggeredBaseQuery = retry(
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
            const { accessToken } = AuthStore;
            if (accessToken) {
                headers.set('Authorization', accessToken);
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        },
    }),
    {
        maxRetries: 0,
    }
);

export const docModelsApi = createApi({
    reducerPath: 'docModelsApi',
    baseQuery: staggeredBaseQuery,
    endpoints: builder => ({
        getDocumentModels: builder.mutation({
            query: () => {
                const { getDocumentModelsPath } = Endpoints;
                return {
                    url: `${getDocumentModelsPath}?user=${idToken}`,
                    method: 'GET',
                };
            },
        }),
        updateDocumentTypes: builder.mutation({
            query: ({ DocumentModelInfo }) => {
                const { updateDocTypePath } = Endpoints;
                return {
                    url: `${updateDocTypePath}?user=${idToken}`,
                    method: 'POST',
                    body: { DocumentModelInfo },
                };
            },
        }),
        updateStatusForDoc: builder.mutation({
            query: ({ DocumentID, status }) => {
                const { updateStatusForDocPath } = Endpoints;
                return {
                    url: `${updateStatusForDocPath}?documentId=${DocumentID}&status=${status}&user=${idToken}`,
                    method: 'POST',
                };
            },
        }),
        getDocumentsType: builder.mutation({
            query: () => {
                const { getDocumentsTypePath } = Endpoints;
                return {
                    url: `${getDocumentsTypePath}?user=${idToken}`,
                    method: 'GET',
                };
            },
        }),
        deleteDocumentModel: builder.mutation({
            query: ({ ModelType }) => {
                const { deleteDocumentModelsPath } = Endpoints;
                return {
                    url: `${deleteDocumentModelsPath}?user=${idToken}&model_name=${ModelType}`,
                    method: 'DELETE',
                };
            },
        }),
        getDocumentsForModel: builder.mutation({
            query: ({ currentModel }) => {
                const { getDocumentsPath } = Endpoints;
                return {
                    url: `${getDocumentsPath}?user=${idToken}&document_type=${currentModel}`,
                    method: 'GET',
                };
            },
        }),
        getAllDocuments: builder.mutation({
            query: () => {
                const { getAllDocumentsPath } = Endpoints;
                return {
                    url: `${getAllDocumentsPath}?user=${idToken}`,
                    method: 'GET',
                };
            },
        }),
        deleteDocumentForModel: builder.mutation({
            query: ({ currentId }) => {
                const { deleteDocumentPath } = Endpoints;
                return {
                    url: `${deleteDocumentPath}?user=${idToken}&document_id=${currentId}`,
                    method: 'DELETE',
                };
            },
        }),
        extractDocument: builder.mutation({
            query: ({ DocumentID }) => {
                const { extractDocPath } = Endpoints;
                return {
                    url: `${extractDocPath}?user=${idToken}&DocumentID=${DocumentID}`,
                    method: 'GET',
                };
            },
        }),
        extractDocumentInfo: builder.mutation({
            query: ({ DocumentID }) => {
                const { extractDocInfoPath } = Endpoints;
                return {
                    url: `${extractDocInfoPath}?user=${idToken}&DocumentID=${DocumentID}`,
                    method: 'GET',
                };
            },
        }),
        updateCustomizedFields: builder.mutation({
            query: ({ docData, document_id }) => {
                const { updateCustomizedFieldsPath } = Endpoints;
                return {
                    url: `${updateCustomizedFieldsPath}?user=${idToken}&document_id=${document_id}`,
                    method: 'POST',
                    body: docData,
                };
            },
        }),
        editDocumentModel: builder.mutation({
            query: ({ oldName, newName }) => {
                const { editModel: url } = Endpoints;
                return {
                    url: `${url}?user=${idToken}&new_model_name=${newName}&old_model_name=${oldName}`,
                    method: 'POST',
                };
            },
        }),
        editDocument: builder.mutation({
            query: ({ oldName, newName }) => {
                const { editDoc: url } = Endpoints;
                return {
                    url: `${url}?user=${idToken}&new_document_name=${newName}&old_document_name=${oldName}`,
                    method: 'POST',
                };
            },
        }),
    }),
});

export const {
    useGetDocumentModelsMutation,
    useUpdateDocumentTypesMutation,
    useUpdateStatusForDocMutation,
    useGetDocumentsTypeMutation,
    useDeleteDocumentModelMutation,
    useGetDocumentsForModelMutation,
    useGetAllDocumentsMutation,
    useDeleteDocumentForModelMutation,
    useExtractDocumentMutation,
    useExtractDocumentInfoMutation,
    useUpdateCustomizedFieldsMutation,
    useEditDocumentModelMutation,
    useEditDocumentMutation,
} = docModelsApi;
