import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import './Table.scss';
import { ReactComponent as Filter } from '../../assets/filter.svg';
import { Checkbox, FormControlLabel, FormGroup, Popover, TextField } from '@mui/material';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const PopoverFilter = ({ menuOpen, anchorEl, handleClose, children }) => {
    return (
        <Popover
            id="user-menu-popover"
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            {children}
        </Popover>
    );
};

PopoverFilter.propTypes = {
    menuOpen: PropTypes.any,
    anchorEl: PropTypes.any,
    handleClose: PropTypes.any,
    children: PropTypes.any,
};

const FilterComponent = ({ filterOptions, filterValue }) => {
    const [filterSelected, setFilterSelected] = React.useState(filterValue ? filterValue : []);
    const [searchText, setSearchText] = React.useState('');

    const handleCheckboxChange = event => {
        setFilterSelected(old => {
            const updatedFilter = old.filter(item => item.toLowerCase() !== event.target.name.toLowerCase());
            if (event.target.checked) {
                updatedFilter.push(event.target.name.toLowerCase());
            }
            return updatedFilter;
        });
    };

    useEffect(() => {
        if (filterOptions?.type === 'multiselect') {
            filterOptions?.filterChange(filterSelected);
        }
    }, [filterSelected]);

    const multiselect = (
        <div className="filterPopover">
            <FormGroup>
                {filterOptions?.searchable && (
                    <span style={{ height: '50px', padding: '8px' }}>
                        <TextField
                            sx={{
                                width: '100%',
                            }}
                            size="small"
                            variant="outlined"
                            placeholder="Search"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </span>
                )}
                {filterOptions?.items
                    ?.filter(
                        _ => _.label.toLowerCase().includes(searchText) || _.value.toLowerCase().includes(searchText)
                    )
                    .map((item, index) => {
                        return (
                            <>
                                <FormControlLabel
                                    key={index}
                                    className="filterItem"
                                    control={
                                        <Checkbox
                                            size="small"
                                            name={item.value}
                                            onChange={handleCheckboxChange}
                                            checked={filterSelected?.includes(item.value?.toLowerCase())}
                                        />
                                    }
                                    label={item.label}
                                    sx={{ marginRight: 0 }}
                                />
                                {/* <input type="checkbox" /> {item.label} */}
                            </>
                        );
                    })}
            </FormGroup>
        </div>
    );

    const dateRange = (
        <DateRangeCalendar
            size="small"
            value={filterValue}
            onChange={newValue => filterOptions?.filterChange(newValue)}
        />
    );

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {filterOptions?.type === 'multiselect' ? (
                multiselect
            ) : filterOptions?.type == 'date-range' ? (
                dateRange
            ) : (
                <div style={{ padding: '24px' }}>No Filter</div>
            )}
        </LocalizationProvider>
    );
};

FilterComponent.propTypes = {
    filterOptions: PropTypes.any,
    filterValue: PropTypes.any,
};

const CustomTable = ({ columns, data, sx = {}, isLoading, filters = {}, ...otherProps }) => {
    const [cols, setCols] = React.useState([]);

    useEffect(() => {
        setCols(
            columns.map(element => {
                return {
                    ...element,
                    renderHeader: () => {
                        const [menuOpen, setMenuOpen] = React.useState(false);
                        const [anchorEl, setAnchorEl] = React.useState(null);
                        const handleClick = event => {
                            setAnchorEl(event.currentTarget);
                            setMenuOpen(true);
                        };

                        const handleClose = () => {
                            setAnchorEl(null);
                            setMenuOpen(false);
                        };

                        return (
                            <span
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '18px',
                                    color: '#667085',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}>
                                {element?.headerName}
                                {element.filterOptions && (
                                    <>
                                        <Filter
                                            className={`${
                                                filters[element.field.toLowerCase()].length > 0 ? 'filterOpen' : ''
                                            }`}
                                            onClick={handleClick}
                                        />
                                        <PopoverFilter
                                            menuOpen={menuOpen}
                                            anchorEl={anchorEl}
                                            handleClose={handleClose}>
                                            <FilterComponent
                                                filterOptions={element.filterOptions}
                                                filterValue={filters[element.field.toLowerCase()]}
                                            />
                                        </PopoverFilter>
                                    </>
                                )}
                            </span>
                        );
                    },
                };
            })
        );
    }, [columns]);

    return (
        <DataGrid
            rows={data}
            columns={cols}
            loading={isLoading}
            sx={{
                '& .MuiDataGrid-container--top[role=row]': {
                    background: '#F9FAFB !important',
                },
                '& .MuiDataGrid-columnHeader': {
                    height: '48px !important',
                    background: '#F9FAFB',
                },
                '& .MuiDataGrid-columnHeaders div': {
                    height: '48px',
                },
                '& .MuiDataGrid-cell': {
                    height: `${otherProps?.options?.rowHeight ? otherProps?.options?.rowHeight : '48px'} !important`,
                    display: 'flex',
                    alignItems: 'center',
                    borderColor: '#EAECF0',
                },
                '& .MuiDataGrid-row': {
                    height: `${otherProps?.options?.rowHeight ? otherProps?.options?.rowHeight : '48px'} !important`,
                    maxHeight: `${otherProps?.options?.rowHeight ? otherProps?.options?.rowHeight : '48px'} !important`,
                    minHeight: `${otherProps?.options?.rowHeight ? otherProps?.options?.rowHeight : '48px'} !important`,
                    backgroundColor: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                },
                borderColor: '#EAECF0',
                ...sx,
            }}
            disableColumnResize
            disableColumnMenu
            disableColumnSorting
            disableVirtualization
            {...otherProps}
        />
    );
};

export default CustomTable;

CustomTable.propTypes = {
    columns: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    sx: PropTypes.any,
    otherProps: PropTypes.any,
    filters: PropTypes.any,
    isLoading: PropTypes.bool,
};
