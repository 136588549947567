import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import { ContentWithTextBox } from '../../components/common/StyledComponents';
import { DIRECTIONS, SITE_TEXT } from '../../utils/constants';
import { checkConfidence, filterKeysByBoundingBox } from '../../utils/functions';

const ReSizableHighlightContent = ({
    valId,
    confidence,
    box,
    updateOldContent,
    mainHead,
    inputText,
    updateTextForKeyValue,
    allBoundingBox,
    allText,
    disabled,
}) => {
    const { top = 1, left = 1, height = 0, width = 0 } = box || {};
    const [position, setPosition] = React.useState({ left, top, height, width });
    const resizableRef = React.useRef(null);
    const positionRef = React.useRef(position);

    React.useEffect(() => {
        positionRef.current = position;
    }, [position]);

    const onMouseDown = (e, direction) => {
        e.preventDefault();
        const parentSize = resizableRef.current.parentNode.getBoundingClientRect();
        const startPos = { x: e.clientX, y: e.clientY };
        const startSize = { width: position.width, height: position.height };
        const startPosition = { x: position.left, y: position.top };

        const onMouseMove = moveEvent => {
            const currentPos = { x: moveEvent.clientX, y: moveEvent.clientY };
            const deltaX = ((currentPos.x - startPos.x) / parentSize.width) * 100;
            const deltaY = ((currentPos.y - startPos.y) / parentSize.height) * 100;
            /* eslint-disable */
            setPosition(prevPos => {
                let newPos = { ...prevPos };
                switch (direction) {
                    case 'n':
                        const newTop = Math.max(startPosition.y + deltaY, 0);
                        const newHeight = Math.min(Math.max(startSize.height - deltaY, 1), 100 - newTop);
                        newPos = {
                            ...newPos,
                            height: newHeight,
                            top: newTop,
                        };
                        break;
                    case 's':
                        const maxHeightSouth = 100 - startPosition.y;
                        newPos = {
                            ...newPos,
                            height: Math.min(Math.max(startSize.height + deltaY, 1), maxHeightSouth),
                        };
                        break;
                    case 'w':
                        const newLeft = Math.max(startPosition.x + deltaX, 0);
                        const newWidthWest = Math.min(Math.max(startSize.width - deltaX, 1), 100 - newLeft);
                        newPos = {
                            ...newPos,
                            width: newWidthWest,
                            left: newLeft,
                        };
                        break;
                    case 'e':
                        const maxWidthEast = 100 - startPosition.x;
                        newPos = {
                            ...newPos,
                            width: Math.min(Math.max(startSize.width + deltaX, 1), maxWidthEast),
                        };
                        break;
                    default:
                        break;
                }
                return newPos;
            });
            /* eslint-enable */
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            const positionToSend = positionRef.current;
            const filteredKeys = filterKeysByBoundingBox(allBoundingBox, positionToSend);
            let concatenatedText = [];
            filteredKeys.forEach(key => {
                const { Text } = allText[key] || {};
                if (Text) {
                    concatenatedText.push(Text);
                }
            });
            updateOldContent(null, positionToSend, concatenatedText.join(' '));
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    return (
        <>
            <div
                ref={resizableRef}
                style={{
                    top: `${position.top}%`,
                    left: `${position.left}%`,
                    height: `${position.height}%`,
                    width: `${position.width}%`,
                    background: checkConfidence(confidence),
                }}
                className="highlightedBoundingBox">
                {DIRECTIONS.map(dir => (
                    <div key={dir} className={`resize-handle ${dir}`} onMouseDown={e => onMouseDown(e, dir)} />
                ))}
            </div>
            <ContentWithTextBox
                key={`contentText-${valId}`}
                confidence={confidence}
                box={position}
                className="contentWithTextBox">
                {mainHead && <h3>{mainHead}</h3>}
                <TextField
                    fullWidth
                    value={inputText}
                    onChange={({ target }) => updateTextForKeyValue(target.value)}
                    placeholder={SITE_TEXT.EnterValue}
                    inputProps={{ maxLength: 100 }}
                    disabled={disabled}
                />
            </ContentWithTextBox>
        </>
    );
};

ReSizableHighlightContent.propTypes = {
    valId: PropTypes.string.isRequired,
    mainHead: PropTypes.string,
    inputText: PropTypes.string.isRequired,
    confidence: PropTypes.number.isRequired,
    box: PropTypes.object.isRequired,
    allBoundingBox: PropTypes.object.isRequired,
    allText: PropTypes.object.isRequired,
    updateOldContent: PropTypes.func.isRequired,
    updateTextForKeyValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export default ReSizableHighlightContent;
