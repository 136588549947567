import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { API_BASE_PATH as baseUrl } from '../../constants/env';
import Endpoints from '../../Endpoints';
import AuthStore from '../../components/common/AuthStore';

const staggeredBaseQuery = retry(
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
            const { accessToken } = AuthStore;
            if (accessToken) {
                headers.set('Authorization', accessToken);
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        },
    }),
    {
        maxRetries: 0,
    }
);

export const userManagementApi = createApi({
    reducerPath: 'userManagementApi',
    baseQuery: staggeredBaseQuery,
    endpoints: builder => ({
        addUser: builder.mutation({
            query: body => {
                const { addUser: url } = Endpoints;
                return {
                    url,
                    method: 'post',
                    body,
                };
            },
        }),
        getUserDetails: builder.mutation({
            query: () => {
                const { userDetails: url } = Endpoints;
                const { idToken } = AuthStore;

                return {
                    url: `${url}?id_token=${idToken}`,
                    method: 'post',
                };
            },
        }),
        refreshToken: builder.mutation({
            query: () => {
                const { refreshToken: url } = Endpoints;
                const { refreshToken, userName } = AuthStore;

                return {
                    url: `${url}?refresh_token=${refreshToken}&email=${userName}`,
                    method: 'post',
                };
            },
        }),
        getActivityLogs: builder.mutation({
            query: () => {
                const { activityLogs: url } = Endpoints;
                const { idToken } = AuthStore;

                return {
                    url: `${url}?user=${idToken}`,
                    method: 'post',
                };
            },
        }),
        resetPassword: builder.mutation({
            query: body => {
                const { resetPassword: url } = Endpoints;
                const { idToken } = AuthStore;
                return {
                    url: `${url}?id_token=${idToken}&new_password=${body.Password}`,
                    method: 'post',
                };
            },
        }),
        deleteUser: builder.mutation({
            query: body => {
                const { deleteUser: url } = Endpoints;
                const { idToken } = AuthStore;
                return {
                    url: `${url}?id_token=${idToken}&user=${body.user}`,
                    method: 'post',
                };
            },
        }),
        updateUser: builder.mutation({
            query: body => {
                const { editUserDetails: url } = Endpoints;
                const { idToken } = AuthStore;

                return {
                    url: `${url}?id_token=${idToken}`,
                    method: 'post',
                    body,
                };
            },
        }),
    }),
});

export const {
    useAddUserMutation,
    useGetUserDetailsMutation,
    useRefreshTokenMutation,
    useGetActivityLogsMutation,
    useResetPasswordMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
} = userManagementApi;
