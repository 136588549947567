import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';

import { useGetAllDocumentsMutation, useGetDocumentsTypeMutation } from '../DocumentModels/apiService';
import { useUploadDocumentsForModelMutation, useUploadStatusMutation } from '../DocumentModels/uploadService';
import LayoutContent from '../../components/LayoutContent';
import UploadDocModal from '../../components/UploadDocModal';
import DocsTable from '../Documents/DocsTable';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';

import * as pdfjsLib from 'pdfjs-dist/webpack';
import { processPDFFile } from '../../utils/functions';
import { updateAllDocs } from '../DocumentModels/reducer';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export default function AllDocuments() {
    const { allDocuments = [], docModels = [] } = useSelector(state => state.docModelsData);
    const { documentId } = useSelector(state => state.docModelsData);

    const [getAllDocuments, { isLoading }] = useGetAllDocumentsMutation();
    const [uploadStatus] = useUploadStatusMutation();
    const [uploadDocumentsForModel, { isLoading: fileLoader }] = useUploadDocumentsForModelMutation();
    const [getDocumentsType, { isLoading: allModelsLoader }] = useGetDocumentsTypeMutation();
    const [showLoader, toggleLoader] = React.useState(true);
    const [showAddNewModel, toggleAddNewModel] = React.useState(false);

    const getCurrentDocuments = () => {
        getAllDocuments();
    };

    React.useEffect(() => {
        getCurrentDocuments();
        toggleLoader(false);
        if (!docModels.length) {
            getDocumentsType();
        }
    }, []);

    const closeModal = () => {
        toggleAddNewModel(false);
    };

    const uploadNewDocCall = async (files, document_types) => {
        let formData = new FormData();
        // formData.append('files', files);
        formData.append('document_types', document_types);

        let docInfo = {
            DocumentInfo: [],
        };

        for (let _ of files) {
            formData.append('files', _);
            const info = await processPDFFile(_);
            if (info) {
                docInfo.DocumentInfo.push(info);
            }
        }

        formData.append('docInfo', JSON.stringify(docInfo));

        uploadDocumentsForModel({ formData }).then(() => {
            closeModal();
            getCurrentDocuments();
            uploadStatus(documentId);
        });
    };

    const filteredModels = React.useMemo(() => docModels.map(({ Name }) => Name), [docModels]);

    return (
        <LayoutContent
            showLoader={isLoading || allModelsLoader}
            title={SITE_TEXT.allDocs}
            headerItemsRight={
                <div className="rightButtons">
                    <Button onClick={() => toggleAddNewModel(true)} className="secondaryBtn">
                        {SITE_TEXT.uploadText}
                    </Button>
                    <Button disabled className="secondaryBtn">
                        {SITE_TEXT.downloadDataText}
                    </Button>
                    <Button disabled>{SITE_TEXT.reviewText}</Button>
                </div>
            }>
            <div className="documentsWrap allDocs">
                <DocsTable
                    showDocModelHeader
                    apiLoading={isLoading || showLoader || allModelsLoader}
                    documents={allDocuments}
                    updateData={updateAllDocs}
                />
                {showAddNewModel && (
                    <UploadDocModal
                        showLoader={fileLoader}
                        onClose={closeModal}
                        leftButtonHandler={closeModal}
                        rightButtonHandler={uploadNewDocCall}
                        filteredModels={filteredModels}
                        showModels
                    />
                )}
            </div>
        </LayoutContent>
    );
}
