import { createSlice } from '@reduxjs/toolkit';

import { modelSettingsApi } from './apiService';

export const initialState = {
    generalSettings: {},
    fieldSettings: {},
    apiError: 0,
};

export const modelSettingsData = createSlice({
    name: 'modelSettingsData',
    initialState,
    reducers: {
        // setCurrentModel: (state, action) => {
        //     state.currentModel = action.payload;
        // },
    },
    extraReducers: builder => {
        builder
            .addMatcher(modelSettingsApi.endpoints.getModelSettings.matchRejected, state => {
                state.apiError += 1;
            })
            .addMatcher(modelSettingsApi.endpoints.getModelSettings.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                const { fieldSettings, generalSettings } = payload;
                state.generalSettings = generalSettings;
                state.fieldSettings = fieldSettings;
            });
    },
});

// Action creators are generated for each case reducer function
export const {
    // setCurrentModel,
    updateCurrentDocs,
    updateDocModels,
    updateDocs,
    updateAllDocs,
} = modelSettingsData.actions;

export default modelSettingsData.reducer;
