import { createSlice } from '@reduxjs/toolkit';

import AuthStore from '../../components/common/AuthStore';
import { loginManagementApi } from './apiService';

export const initialState = {
    errorInfo: '',
    signUpError: '',
    signUpSuccess: '',
    tempPassError: '',
    apiError: 0,
};

export const loginData = createSlice({
    name: 'loginData',
    initialState,
    reducers: {
        resetError: state => {
            state.errorInfo = '';
            state.signUpError = '';
            state.signUpSuccess = '';
            state.tempPassError = '';
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(loginManagementApi.endpoints.loginStatus.matchPending, state => {
                state.errorInfo = '';
            })
            .addMatcher(loginManagementApi.endpoints.loginStatus.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                const { Output = '', detail = '' } = data;
                state.errorInfo = detail ? detail : Output;
                state.apiError += 1;
            })
            .addMatcher(loginManagementApi.endpoints.loginStatus.matchFulfilled, (state, action) => {
                const { meta = {} } = action;
                const { arg = {} } = meta;
                const { originalArgs = {} } = arg;
                const { body = {} } = originalArgs;
                const { Email = '' } = body;
                if (action.payload.Output == 'Login Successful!!') {
                    AuthStore.userName = Email;
                    AuthStore.accessToken = `Bearer ${action.payload.access_token}`;
                    AuthStore.idToken = action.payload.id_token;
                    AuthStore.refreshToken = action.payload.refresh_token;
                    window.location.reload();
                }
            })
            .addMatcher(loginManagementApi.endpoints.signUpStatus.matchPending, state => {
                state.signUpError = null;
            })
            .addMatcher(loginManagementApi.endpoints.signUpStatus.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                const { Output = '', detail = '' } = data;
                state.signUpError = Output || detail;
                state.apiError += 1;
            })
            .addMatcher(loginManagementApi.endpoints.signUpStatus.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                const { Output = '', detail = '' } = payload;
                state.signUpSuccess = Output;

                state.signUpError = Output || detail;
            })
            .addMatcher(loginManagementApi.endpoints.updateTempPass.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                const { Output = {}, detail = {} } = data;
                state.tempPassError = detail ? detail : Output;
                state.apiError += 1;
            })
            .addMatcher(loginManagementApi.endpoints.updateTempPass.matchFulfilled, (state, action) => {
                const { meta = {} } = action;
                const { arg = {} } = meta;
                const { originalArgs = {} } = arg;
                const { UserName = '' } = originalArgs;
                if (action.payload.Output == 'Login Successful!!') {
                    AuthStore.userName = UserName;
                    AuthStore.accessToken = `Bearer ${action.payload.access_token}`;
                    AuthStore.idToken = action.payload.id_token;
                    AuthStore.refreshToken = action.payload.refresh_token;
                    window.location.reload();
                }
            })
            .addMatcher(loginManagementApi.endpoints.sendVerificationCode.matchRejected, () => {})
            .addMatcher(loginManagementApi.endpoints.sendVerificationCode.matchFulfilled, () => {})
            .addMatcher(loginManagementApi.endpoints.verifyOtp.matchRejected, () => {})
            .addMatcher(loginManagementApi.endpoints.verifyOtp.matchFulfilled, () => {});
    },
});

// Action creators are generated for each case reducer function
export const { resetError } = loginData.actions;

export default loginData.reducer;
