import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import c from 'classnames';

import { StyledTooltip, CommonSelectMenu } from '../../components/common/StyledComponents';
import { SITE_TEXT, STRING_DATA_TYPE, TABLE_DATA_TYPE } from '../../utils/constants';
import { addZeroToNUmber } from '../../utils/functions';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';

function CustomizeData({ currentPage = 1, data = {}, goToBack, handleCustomizeChanges }) {
    const [currentFields, updateFields] = React.useState({});
    const [fieldSettings, updateFieldSettings] = React.useState(null);
    const [isAnyFieldEmpty, updateEmptyField] = React.useState(false);

    React.useEffect(() => {
        updateFields(data[currentPage]);
    }, []);

    const { KEY_VALUE_PAIR = [], Table = {} } = currentFields;

    const addNewField = () => {
        const newValues = [
            ...KEY_VALUE_PAIR,
            {
                isTextField: true,
                key: {
                    text: '',
                    Confidence: 0,
                },
                value: {},
            },
        ];
        updateFields({
            ...currentFields,
            KEY_VALUE_PAIR: newValues,
        });
        if (isAnyFieldEmpty) {
            updateEmptyField(false);
        }
    };

    const updateKeyValueFiled = (fieldIndex, text) => {
        const { key } = KEY_VALUE_PAIR[fieldIndex];
        const updatedVal = {
            ...KEY_VALUE_PAIR[fieldIndex],
            key: {
                ...key,
                text,
            },
        };
        const newValues = [...KEY_VALUE_PAIR.slice(0, fieldIndex), updatedVal, ...KEY_VALUE_PAIR.slice(fieldIndex + 1)];
        updateFields({
            ...currentFields,
            KEY_VALUE_PAIR: newValues,
        });
    };

    const hideKeyValueFiled = (fieldIndex, Hidden, isDelete = false) => {
        if (isDelete) {
            const newValues = KEY_VALUE_PAIR.filter((_, oldIn) => oldIn !== fieldIndex);
            updateFields({
                ...currentFields,
                KEY_VALUE_PAIR: newValues,
            });
        } else {
            const { key } = KEY_VALUE_PAIR[fieldIndex];
            const updatedVal = {
                ...KEY_VALUE_PAIR[fieldIndex],
                key: {
                    ...key,
                    Hidden,
                },
            };
            const newValues = [
                ...KEY_VALUE_PAIR.slice(0, fieldIndex),
                updatedVal,
                ...KEY_VALUE_PAIR.slice(fieldIndex + 1),
            ];
            updateFields({
                ...currentFields,
                KEY_VALUE_PAIR: newValues,
            });
        }
    };

    const handleTableParentClick = (tableIndex, Hidden, isDelete = false) => {
        if (isDelete) {
            const { [tableIndex]: deletedProperty, ...newTable } = Table;
            if (deletedProperty) {
                updateFields({
                    ...currentFields,
                    Table: newTable,
                });
            }
        } else {
            const { Table_Info, Cell_Info } = Table[tableIndex];
            const newTableValues = {
                ...Table,
                [tableIndex]: {
                    ...Table[tableIndex],
                    Table_Info: {
                        ...Table_Info,
                        Hidden,
                    },
                    Cell_Info: Cell_Info.map(row =>
                        row.map(cell => ({
                            ...cell,
                            Hidden,
                        }))
                    ),
                },
            };
            updateFields({
                ...currentFields,
                Table: newTableValues,
            });
        }
    };

    const handleRowClick = (tableIndex, rowIndex, Hidden, isDelete = false) => {
        const { Table_Info, Cell_Info } = Table[tableIndex];
        let newTableValues = {};
        if (isDelete) {
            const newCellInfo = Cell_Info.map(row => row.filter((_, index) => index !== rowIndex));
            const isWholeTableEmpty = !newCellInfo.some(innerRow => innerRow.length);
            newTableValues = isWholeTableEmpty
                ? Object.fromEntries(Object.entries(Table).filter(([key]) => key !== tableIndex))
                : { ...Table, [tableIndex]: { Table_Info, Cell_Info: newCellInfo } };
        } else {
            const newCellInfo = Cell_Info.map(row =>
                row.map((cell, cellIndex) => ({ ...cell, Hidden: cellIndex === rowIndex ? Hidden : cell.Hidden }))
            );
            const areAllCellsHidden = newCellInfo.every(row => row.every(cell => cell.Hidden));
            newTableValues = {
                ...Table,
                [tableIndex]: {
                    Table_Info: {
                        ...Table_Info,
                        Hidden: areAllCellsHidden ? true : false,
                    },
                    Cell_Info: newCellInfo,
                },
            };
        }
        updateFields({ ...currentFields, Table: newTableValues });
    };

    const saveChanges = () => {
        const invalidFieldIndex = KEY_VALUE_PAIR?.findIndex(({ isTextField, key }) => {
            const { text = '' } = key || {};
            return isTextField && !text.trim();
        });

        if (invalidFieldIndex !== -1) {
            updateEmptyField(true);
            const element = document.getElementById(`${invalidFieldIndex}-keyValue`);
            if (element) {
                element.scrollIntoView({ top: 0, behavior: 'smooth', block: 'start', inline: 'start' });
            }
        } else {
            // eslint-disable-next-line
            const newValues = KEY_VALUE_PAIR.map(({ isTextField, ...rest }) => rest);
            const docData = {
                ...data,
                [currentPage]: {
                    ...currentFields,
                    KEY_VALUE_PAIR: newValues,
                },
            };
            handleCustomizeChanges(docData);
        }
    };

    const closeCustomizeFields = () => {
        document.body.classList.toggle('noScroll');
        goToBack();
    };

    const handleSettingsClose = () => {
        updateFieldSettings(null);
    };

    return (
        <div className="customziedFields">
            <div className="header">
                <button className="backToReview" onClick={closeCustomizeFields}>
                    <ArrowBackIcon />
                </button>
                {SITE_TEXT.CustomizedFields}

                <div className="buttonWrap">
                    <Button className="leftButton" onClick={closeCustomizeFields}>
                        {SITE_TEXT.Cancel}
                    </Button>
                    <Button className="rightButton" onClick={saveChanges}>
                        {SITE_TEXT.SaveChanges}
                    </Button>
                </div>
            </div>

            <section className="customSection">
                <div className="customContent header clearfix">
                    <div className="key">{SITE_TEXT.FieldName}</div>
                    <div className="value">{SITE_TEXT.DataType}</div>
                </div>
                {KEY_VALUE_PAIR.map(({ key, isTextField = false }, uuid) => {
                    const { text, Hidden = false } = key || {};
                    return (
                        <div
                            key={`${uuid}-keyValue`}
                            id={`${uuid}-keyValue`}
                            className={c('customContent clearfix', {
                                Hidden,
                                inValid: isAnyFieldEmpty && isTextField && !text.trim(),
                            })}>
                            <div className="key">
                                <StyledTooltip title={SITE_TEXT.Delete}>
                                    <IndeterminateCheckBoxOutlinedIcon
                                        onClick={() => hideKeyValueFiled(uuid, Hidden, true)}
                                    />
                                </StyledTooltip>

                                <StyledTooltip title={Hidden ? SITE_TEXT.Show : SITE_TEXT.Hide}>
                                    {Hidden ? (
                                        <VisibilityOffOutlinedIcon onClick={() => hideKeyValueFiled(uuid, !Hidden)} />
                                    ) : (
                                        <RemoveRedEyeOutlinedIcon onClick={() => hideKeyValueFiled(uuid, !Hidden)} />
                                    )}
                                </StyledTooltip>

                                {isTextField ? (
                                    <TextField
                                        fullWidth
                                        value={text}
                                        onChange={({ target }) => updateKeyValueFiled(uuid, target.value)}
                                        placeholder={SITE_TEXT.EnterField}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                ) : (
                                    <div className="ellipsisPro">{text}</div>
                                )}
                            </div>
                            <div className="value">
                                <CommonSelectMenu disabled={Hidden} fullWidth answer="1" options={STRING_DATA_TYPE} />
                                <SettingsIcon
                                    onClick={() =>
                                        updateFieldSettings({
                                            ...key,
                                            dataType: STRING_DATA_TYPE,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    );
                })}
                <div className="customContent buttonKey clearfix">
                    <div className="key">
                        <Button fullWidth className="addField" onClick={addNewField}>
                            {SITE_TEXT.AddField}
                        </Button>
                    </div>
                </div>
                {Object.keys(Table).map((tableInner, uuid) => {
                    const { Cell_Info = [], Table_Info } = Table[tableInner] || {};
                    const { Hidden = false } = Table_Info || {};
                    const text = `Table ${addZeroToNUmber(uuid + 1)}`;
                    return (
                        <div className="tableContent clearfix" key={`${tableInner}-table`}>
                            <div className={c('customContent clearfix', { Hidden })}>
                                <div className="key">
                                    <StyledTooltip title={SITE_TEXT.Delete}>
                                        <IndeterminateCheckBoxOutlinedIcon
                                            onClick={() => handleTableParentClick(tableInner, Hidden, true)}
                                        />
                                    </StyledTooltip>
                                    <StyledTooltip title={Hidden ? SITE_TEXT.Show : SITE_TEXT.Hide}>
                                        {Hidden ? (
                                            <VisibilityOffOutlinedIcon
                                                onClick={() => handleTableParentClick(tableInner, !Hidden)}
                                            />
                                        ) : (
                                            <RemoveRedEyeOutlinedIcon
                                                onClick={() => handleTableParentClick(tableInner, !Hidden)}
                                            />
                                        )}
                                    </StyledTooltip>
                                    <div className="ellipsisPro">{text}</div>
                                </div>
                                <div className="value">
                                    <CommonSelectMenu
                                        disabled={Hidden}
                                        fullWidth
                                        answer="1"
                                        options={TABLE_DATA_TYPE}
                                    />
                                    <SettingsIcon
                                        onClick={() =>
                                            updateFieldSettings({
                                                ...Table_Info,
                                                text,
                                                dataType: TABLE_DATA_TYPE,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="rowInner clearfix">
                                {Cell_Info[0].map((rowInner, rowIndex) => {
                                    const { Text, Hidden: rowHidden } = rowInner;
                                    return (
                                        <div
                                            key={`${rowIndex}-rowData`}
                                            className={c('customContent clearfix', { Hidden: rowHidden })}>
                                            <div className="key">
                                                <StyledTooltip title={SITE_TEXT.Delete}>
                                                    <IndeterminateCheckBoxOutlinedIcon
                                                        onClick={() =>
                                                            handleRowClick(tableInner, rowIndex, rowHidden, true)
                                                        }
                                                    />
                                                </StyledTooltip>
                                                <StyledTooltip title={rowHidden ? SITE_TEXT.Show : SITE_TEXT.Hide}>
                                                    {rowHidden ? (
                                                        <VisibilityOffOutlinedIcon
                                                            onClick={() =>
                                                                handleRowClick(tableInner, rowIndex, !rowHidden)
                                                            }
                                                        />
                                                    ) : (
                                                        <RemoveRedEyeOutlinedIcon
                                                            onClick={() =>
                                                                handleRowClick(tableInner, rowIndex, !rowHidden)
                                                            }
                                                        />
                                                    )}
                                                </StyledTooltip>
                                                <div className="ellipsisPro">{Text}</div>
                                            </div>
                                            <div className="value">
                                                <CommonSelectMenu
                                                    disabled={rowHidden}
                                                    fullWidth
                                                    answer="1"
                                                    options={STRING_DATA_TYPE}
                                                />
                                                <SettingsIcon
                                                    onClick={() =>
                                                        updateFieldSettings({
                                                            ...rowInner,
                                                            text: Text,
                                                            dataType: STRING_DATA_TYPE,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </section>
            <Drawer
                anchor="right"
                open={!!fieldSettings}
                onClose={handleSettingsClose}
                classes={{
                    root: 'fieldSettingsDrawer',
                }}
                PaperProps={{
                    sx: {
                        height: '100%',
                        top: 0,
                        width: '35%',
                    },
                }}>
                {fieldSettings ? (
                    <aside className="filterContent">
                        <h2>
                            {SITE_TEXT.Settings}
                            <IconButton aria-label="close" className="closeButton" onClick={handleSettingsClose}>
                                <CloseIcon className="CloseIcon" alt="" />
                            </IconButton>
                        </h2>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.FieldName}</h3>
                            <div className="textContent">{fieldSettings.text}</div>
                        </div>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.DataType}</h3>
                            <div className="dropDownContent">
                                <CommonSelectMenu fullWidth answer="1" options={fieldSettings.dataType} />
                            </div>
                        </div>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.ConfidenceScore}</h3>
                            <div className="textContent">{fieldSettings.Confidence}</div>
                        </div>
                    </aside>
                ) : null}
            </Drawer>
        </div>
    );
}

CustomizeData.propTypes = {
    currentPage: PropTypes.number,
    data: PropTypes.object,
    goToBack: PropTypes.func.isRequired,
    handleCustomizeChanges: PropTypes.func.isRequired,
};

export default CustomizeData;
