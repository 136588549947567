import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import AuthStore from '../../components/common/AuthStore';
import { API_BASE_PATH as baseUrl } from '../../constants/env';
import Endpoints from '../../Endpoints';

const { idToken } = AuthStore;

const staggeredBaseQuery = retry(
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
            const { accessToken } = AuthStore;
            if (accessToken) {
                headers.set('Authorization', accessToken);
            }
            return headers;
        },
    }),
    {
        maxRetries: 1,
    }
);

export const docModelsUploadApi = createApi({
    reducerPath: 'docModelsUploadApi',
    baseQuery: staggeredBaseQuery,
    endpoints: builder => ({
        uploadDocumentsForModel: builder.mutation({
            query: ({ formData }) => {
                const { doUploadPath } = Endpoints;
                return {
                    url: `${doUploadPath}?user=${idToken}`,
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        uploadStatus: builder.mutation({
            query: documentId => {
                const { documentStatus } = Endpoints;

                return {
                    url: `${documentStatus}?id_token=${idToken}`,
                    method: 'POST',
                    body: documentId,
                };
            },
        }),
    }),
});

export const { useUploadDocumentsForModelMutation, useUploadStatusMutation } = docModelsUploadApi;
