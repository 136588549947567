import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { setCurrentModel, updateCurrentDocs, updateDocModels } from './reducer';
import { useDeleteDocumentModelMutation, useEditDocumentModelMutation } from './apiService';
import ConfirmModal from '../../components/ConfirmModal';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import addDocModel from '../../assets/addDocModel.svg';
import { SITE_TEXT } from '../../utils/constants';
import { TextField } from '@mui/material';
import CustomTable from '../../components/common/Table';

export default function DataTable({ apiLoading = false, currentModel, toggleAddNewModel, docModels, addNewDisabled }) {
    const [deleteDocumentModel, { isLoading }] = useDeleteDocumentModelMutation();
    const [editDocumentModel] = useEditDocumentModelMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentId, setModelId] = React.useState(null);
    const [settingsEl, setSettingsEl] = React.useState(null);
    const [showConfirmModal, toggleConfirmModal] = React.useState(false);
    const [modelEditStateId, setModelEditStateId] = React.useState(null);

    const redirectToDoc = (name, id) => {
        if (currentModel !== name) {
            dispatch(setCurrentModel(name));
            dispatch(updateCurrentDocs([]));
        }
        navigate(`/documents/${id}`);
    };

    const hightLightModel = (e, id) => {
        setSettingsEl(e.currentTarget);
        setModelId(id);
    };

    const closeSettingsModel = () => {
        setSettingsEl(null);
        setModelId(null);
    };

    const renameModel = id => {
        setModelEditStateId(id);
        closeSettingsModel();
    };

    const openDeleteModal = () => {
        toggleConfirmModal(true);
        setSettingsEl(null);
    };

    const closeModal = () => {
        toggleConfirmModal(false);
        closeSettingsModel();
    };

    const deleteSelectedModel = () => {
        const { Name: ModelType = '' } = docModels?.find(({ id }) => id === parseInt(currentId)) || {};
        deleteDocumentModel({ currentId, ModelType }).then(() => {
            toggleConfirmModal(false);
            closeSettingsModel();
        });
    };

    const updateModalName = (newName, oldName, id) => {
        // Update the model name
        if (oldName == newName) {
            setModelEditStateId(null);
        }
        editDocumentModel({ oldName, newName }).then(() => {
            setModelEditStateId(null);
            const data = docModels.map(_ => {
                return {
                    ..._,
                    Name: _.id == id ? newName : _.Name,
                };
            });
            dispatch(updateDocModels(data));
        });
    };

    const columns = [
        {
            field: 'Status',
            flex: 2,
            headerName: 'Status',
            renderCell: params => {
                const { DocNumber, Status } = params.row;
                return (
                    <div className={`tableCellInCustomTable statusWrap ${Status}`}>
                        {Status}
                        {DocNumber && <span> ({DocNumber})</span>}
                    </div>
                );
            },
        },
        {
            field: 'Name',
            flex: 4,
            headerName: 'Model Name',
            renderCell: params => {
                const { id, Name, DocumentModelRename } = params.row;
                const inEditState = modelEditStateId === id;
                return (
                    <div
                        className="tableCellInCustomTable nameWrap"
                        style={{ width: '100%' }}
                        onClick={() => {
                            if (!inEditState) redirectToDoc(Name, id);
                        }}>
                        {inEditState ? (
                            <TextField
                                fullWidth
                                name="ModalName"
                                defaultValue={DocumentModelRename ? DocumentModelRename : Name}
                                onBlur={({ target }) => {
                                    updateModalName(target.value, Name, id);
                                }}
                                onKeyUp={event => {
                                    if (event.key == 'Enter') {
                                        updateModalName(event.target.value, Name, id);
                                    }
                                }}
                                onKeyDown={event => {
                                    if (event.key == ' ') event.stopPropagation();
                                }}
                                placeholder={SITE_TEXT.ENTER_MODEL_NAME}
                                inputProps={{ maxLength: 100 }}
                                className="modalRename"
                            />
                        ) : DocumentModelRename ? (
                            DocumentModelRename
                        ) : (
                            Name
                        )}
                    </div>
                );
            },
        },
        {
            field: 'ModelType',
            flex: 2,
            headerName: 'Model Type',
        },
        {
            field: 'CreatedOn',
            flex: 2,
            headerName: 'Created On',
            renderCell: params => {
                const { CreatedOn } = params.row;
                return <div className="tableCellInCustomTable">{moment(CreatedOn).format('MMM DD, YYYY')}</div>;
            },
        },
        {
            field: 'CreatedBy',
            flex: 2,
            headerName: 'Created by',
        },
        {
            field: '',
            width: 40,
            headerName: '',
            renderCell: params => {
                const { id } = params.row;
                return (
                    <div className="tableCellInCustomTable">
                        <div className="verticalIcon" onClick={e => hightLightModel(e, id)}>
                            <MoreVertIcon />
                        </div>
                        <Popover
                            id="settingsEl"
                            // open={Boolean(settingsEl)}
                            open={currentId === id}
                            anchorEl={settingsEl}
                            onClose={closeSettingsModel}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            className="settingsPopOver">
                            <div onClick={() => renameModel(currentId)}>
                                <img src={editIcon} alt="" />
                                {SITE_TEXT.RenameModel}
                            </div>
                            <div onClick={openDeleteModal}>
                                <img src={trashIcon} alt="" />
                                {SITE_TEXT.Delete}
                            </div>
                        </Popover>
                    </div>
                );
            },
        },
    ];

    const customOption = {
        rowHeight: '75px',
    };

    return (
        <TableContainer component={Paper} className="modelsDataTable">
            {!apiLoading && !docModels.length ? (
                <div className="emptyContent modelEmptyState">
                    <img src={addDocModel} alt="" />
                    <h2>{SITE_TEXT.noModelText}</h2>
                    <Button onClick={() => toggleAddNewModel(true)} disabled={addNewDisabled}>
                        <AddCircleOutlineIcon />
                        {SITE_TEXT.AddNewModelText}
                    </Button>
                </div>
            ) : (
                <CustomTable data={docModels} columns={columns} isLoading={apiLoading} options={customOption} />
            )}
            {showConfirmModal && (
                <ConfirmModal
                    showLoader={isLoading}
                    onClose={closeModal}
                    modalHeader={SITE_TEXT.DELETE_MODEL_HEADER}
                    modalDescription={SITE_TEXT.DELETE_MODEL_BODY}
                    leftButtonText={SITE_TEXT.Cancel}
                    leftButtonHandler={closeModal}
                    rightButtonText={SITE_TEXT.Delete}
                    rightButtonHandler={deleteSelectedModel}
                />
            )}
        </TableContainer>
    );
}

DataTable.propTypes = {
    apiLoading: PropTypes.bool,
    currentModel: PropTypes.string.isRequired,
    toggleAddNewModel: PropTypes.func.isRequired,
    docModels: PropTypes.array.isRequired,
    addNewDisabled: PropTypes.bool.isRequired,
};
