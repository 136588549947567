import React from 'react';
import './../index.scss';
import SignupStepper from './SignupStepper';
import c from 'classnames';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import Loader from '../../../components/Loader';
import { DocumentTypes, SITE_TEXT } from '../../../utils/constants';
import './../index.scss';
import AutoComplete from '../../../components/common/AutoComplete';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUpStep3 = ({
    signUpError,
    body,
    handleData,
    isLoading,
    errors,
    updateErrors,
    signupStep,
    callSignUpApi,
    signupErrorNew,
    updateSignUp,
}) => {
    const goToSignUp = () => updateSignUp(false);

    const [captchaVerify, setCaptchaVerify] = React.useState(false);
    const [showCaptchaError, setShowCaptchaError] = React.useState(false);

    const onSubmit = e => {
        e.preventDefault();
        const { DocumentType, NoOfDocPerMonth } = body;

        // DocumentType: '',
        // Custom: '',
        // NoOfDocPerMonth: null,
        // AdditionalInfo: '',

        updateErrors({
            DocumentType: !DocumentType,
            NoOfDocPerMonth: !NoOfDocPerMonth,
        });
        if (!captchaVerify) {
            setShowCaptchaError(true);
        }
        if (DocumentType && NoOfDocPerMonth && captchaVerify) {
            // setSignupStep(3);
            callSignUpApi();
        }
    };

    const onCaptchaChange = value => {
        setShowCaptchaError(false);
        if (value) {
            setCaptchaVerify(true);
        }
    };

    return (
        <div className="signUpStepTwo">
            <div className="stepsWrapper">
                <SignupStepper step={signupStep} />
            </div>
            <div className="wrapper">
                <div className="formWrapper loginWrapper">
                    <h1>Step 3/3 - {SITE_TEXT.EnterCompanyDetails}</h1>
                    <form onSubmit={onSubmit}>
                        {isLoading && <Loader position="fixed" />}

                        <div
                            className={c('inputWrap autoComplete', {
                                errorWrap: errors.DocumentType || errors.passNoMatch,
                            })}>
                            <label>{SITE_TEXT.DocumentType}</label>
                            <AutoComplete
                                updateValue={value => {
                                    return handleData({ value, name: 'DocumentType' });
                                }}
                                options={DocumentTypes}
                                name="DocumentType"
                                placeholder={SITE_TEXT.ChooseDocumentPlaceholder}
                                value={body.DocumentType ? body.DocumentType : []}
                            />
                            {errors.DocumentType && <div className="warning">{SITE_TEXT.InvalidDocument}</div>}
                        </div>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.NoOfDocPerMonth || errors.passNoMatch,
                            })}>
                            <label>{SITE_TEXT.NoOfDoc}</label>
                            <TextField
                                fullWidth
                                name="NoOfDocPerMonth"
                                value={body.NoOfDocPerMonth}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.NoOfDocPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.NoOfDocPerMonth && <div className="warning">{SITE_TEXT.InvalidNoOfDoc}</div>}
                        </div>
                        <div className={c('inputWrap')}>
                            <label>{SITE_TEXT.AdditionalInfoText}</label>
                            <TextField
                                fullWidth
                                name="AdditionalInfo"
                                value={body.AdditionalInfo}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.AdditionalInfoPlaceholder}
                                inputProps={{ maxLength: 100 }}
                                multiline
                                minRows={4}
                            />
                        </div>
                        <div style={{ marginBottom: '18px' }}>
                            <ReCAPTCHA sitekey="6Letd9kpAAAAAO0zkkvb9MO8AgBdiRbdjTR3_g8i" onChange={onCaptchaChange} />
                            {showCaptchaError && <div className="warning">Captcha is required</div>}
                        </div>
                        <Button type="submit" onClick={onSubmit} fullWidth>
                            {SITE_TEXT.FinishText}
                        </Button>

                        {signUpError && <h2>{signUpError}</h2>}
                        {signupErrorNew && (
                            <>
                                <h2>{signupErrorNew}</h2>{' '}
                                <div className="infoWrap">
                                    <span onClick={goToSignUp}>Login here</span>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUpStep3;

SignUpStep3.propTypes = {
    updateSignUp: PropTypes.func.isRequired,
    signUpError: PropTypes.any,
    body: PropTypes.any.isRequired,
    handleData: PropTypes.any.isRequired,
    setSignupStep: PropTypes.any.isRequired,
    signUpStatus: PropTypes.any.isRequired,
    isLoading: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    updateErrors: PropTypes.any.isRequired,
    signupStep: PropTypes.number.isRequired,
    callSignUpApi: PropTypes.number.isRequired,
    signupErrorNew: PropTypes.string.isRequired,
};
