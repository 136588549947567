import React, { useEffect } from 'react';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';
import c from 'classnames';
import { Button, TextField } from '@mui/material';
import Avatar from '../../components/common/Avatar';
import { useSelector } from 'react-redux';
import PasswordField from '../../components/common/PasswordField';
import { isPasswordValid } from '../../utils/functions';
import { useResetPasswordMutation } from './apiService';
import Toastr from '../../components/common/Toastr';

const AccountSettings = () => {
    const { user, passwordChangeToast } = useSelector(state => state.userData);
    const [resetPassword] = useResetPasswordMutation();

    const [openToast, setOpenToast] = React.useState(false);
    const [editPass, setEditPass] = React.useState(false);

    const [body, updateParams] = React.useState({
        FirstName: '',
        LastName: '',
        Email: '',
        Password: '',
        RePassword: '',
        Photo: '',
    });

    const [errors, updateErrors] = React.useState({
        FirstName: false,
        LastName: false,
        Email: false,
        Password: false,
        RePassword: false,
        Photo: false,
    });

    useEffect(() => {
        updateParams({
            FirstName: user?.FirstName,
            LastName: user?.LastName,
            Email: user?.Email,
            Password: '',
            RePassword: '',
            Photo: '',
        });
    }, [user]);

    useEffect(() => {
        if (passwordChangeToast.msg) {
            setOpenToast(true);
        }
    }, [passwordChangeToast]);

    const handleData = ({ name, value }) => {
        const handlePassError = name === 'Password' || name === 'RePassword';
        const additionalError = handlePassError ? { passNoMatch: false } : {};
        updateParams({
            ...body,
            [name]: value,
        });
        updateErrors({
            ...errors,
            ...additionalError,
            [name]: false,
        });
    };

    const changePassword = async e => {
        e.preventDefault();
        const { Password, RePassword } = body;

        const passNoMatch = Password !== RePassword;
        const invalidPass = !isPasswordValid(Password);

        updateErrors({
            Password: !Password,
            RePassword: !RePassword,
            passNoMatch,
            invalidPass,
        });

        if (Password && RePassword && !passNoMatch && !invalidPass) {
            await resetPassword({ Password: body.Password });
            // if (resp?.data?.Message == 'Password Updated Successfully!!')
            setEditPass(false);
        }
    };

    const editPassword = () => {
        setEditPass(true);
    };

    return (
        <div className="settingsContentWrapper">
            <div id="subHead" className="subHead">
                <h2>{SITE_TEXT.AccountSettingsText}</h2>
            </div>
            <div className="mainContainer">
                <div className="halfWidthWrap clearfix">
                    <div className="inputWrap">
                        <label>{SITE_TEXT.ProfilePictureText}</label>
                        <Avatar
                            img={body.Photo}
                            name={`${body?.FirstName ? body?.FirstName?.slice(0, 1) : ''}${
                                body?.LastName ? body?.LastName?.slice(0, 1) : ''
                            }`}
                        />
                    </div>
                    <div></div>
                </div>
                <div className="halfWidthWrap clearfix">
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.FirstName,
                        })}>
                        <label>{SITE_TEXT.NameText}</label>
                        <TextField
                            fullWidth
                            name="FirstName"
                            value={body.FirstName}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.FnamePlaceholder}
                            inputProps={{ maxLength: 100 }}
                            disabled
                        />
                        {errors.FirstName && <div className="warning">{SITE_TEXT.InvalidFName}</div>}
                    </div>
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.LastName,
                        })}>
                        <label>&nbsp;</label>
                        <TextField
                            fullWidth
                            name="LastName"
                            value={body.LastName}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.LnamePlaceholder}
                            inputProps={{ maxLength: 100 }}
                            disabled
                        />
                        {errors.LastName && <div className="warning">{SITE_TEXT.InvalidLName}</div>}
                    </div>
                </div>
                <div className="halfWidthWrap clearfix">
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Email,
                        })}>
                        <label>{SITE_TEXT.EmailText}</label>
                        <TextField
                            fullWidth
                            name="Email"
                            value={body.Email}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.EmailPlaceholder}
                            inputProps={{ maxLength: 100 }}
                            disabled
                        />
                        {errors.Email && <div className="warning">{SITE_TEXT.InvalidEmail}</div>}
                    </div>
                    <div></div>
                </div>
                <div className="halfWidthWrap clearfix" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div className={c('inputWrap')} style={{ marginBottom: 0 }}>
                        <label>{SITE_TEXT.PasswordText}</label>
                        <TextField
                            fullWidth
                            name="Password"
                            value="xxxxxxxxxxxxxxxx"
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.PasswordPlaceholder}
                            inputProps={{ maxLength: 100 }}
                            disabled
                        />
                    </div>
                    <div>
                        {!editPass && (
                            <Button type="submit" className="btnChange" onClick={editPassword}>
                                {SITE_TEXT.Edit}
                            </Button>
                        )}
                    </div>
                </div>
                {editPass && (
                    <>
                        <div className="halfWidthWrap clearfix">
                            <div
                                className={c('inputWrap', {
                                    errorWrap: errors.Password,
                                })}
                                style={{ marginBottom: 0 }}>
                                <label>{SITE_TEXT.NewPassword}</label>
                                <PasswordField
                                    fullWidth
                                    type="password"
                                    name="Password"
                                    value={body.Password}
                                    onChange={({ target }) => handleData(target)}
                                    placeholder={SITE_TEXT.EnterNewPassword}
                                    inputProps={{ maxLength: 100 }}
                                />
                                {errors.Password && <div className="warning">{SITE_TEXT.NewPasswordError}</div>}
                            </div>
                            <div></div>
                        </div>
                        <div className="halfWidthWrap clearfix">
                            <div
                                className={c('inputWrap', {
                                    errorWrap: errors.RePassword || errors.passNoMatch,
                                })}
                                style={{ marginBottom: 0 }}>
                                <label>{SITE_TEXT.RepeatNewPassword}</label>
                                <PasswordField
                                    fullWidth
                                    type="password"
                                    name="RePassword"
                                    value={body.RePassword}
                                    onChange={({ target }) => handleData(target)}
                                    placeholder={SITE_TEXT.EnterRepeatNewPassword}
                                    inputProps={{ maxLength: 100 }}
                                />
                                {errors.RePassword && <div className="warning">{SITE_TEXT.RepeatPasswordError}</div>}
                                {!errors.RePassword && errors.passNoMatch && (
                                    <div className="warning">{SITE_TEXT.InvalidPassMatchChangePass}</div>
                                )}

                                {!errors.RePassword && errors.invalidPass && (
                                    <div className="warning">{SITE_TEXT.InvalidPassMatchRegexChangePass}</div>
                                )}
                            </div>
                            <div></div>
                        </div>
                        <div className="halfWidthWrap clearfix footBtnChangePass">
                            <Button type="cancel" variant="outlined" onClick={changePassword}>
                                {SITE_TEXT.Cancel}
                            </Button>
                            <Button type="submit" onClick={changePassword}>
                                {SITE_TEXT.UpdatePassword}
                            </Button>
                            <div></div>
                        </div>
                    </>
                )}
            </div>
            <Toastr
                message={passwordChangeToast.msg}
                open={openToast}
                setOpen={setOpenToast}
                type={passwordChangeToast.type}
                autoHideDuration={4000}
            />
        </div>
    );
};

export default AccountSettings;
