import React, { useEffect, useState } from 'react';

import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { StyledTooltip } from '../../components/common/StyledComponents';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from '../../components/common/Switch';
import { SITE_TEXT } from '../../utils/constants';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { Button, Drawer, IconButton, TextField } from '@mui/material';
import Toastr from '../../components/common/Toastr';
import { CustomSelect } from '../../components/common/Select';
import { useUpdateFieldSettingsMutation } from './apiService';
import CloseIcon from '@mui/icons-material/Close';

const TYPES = [{ key: 'String', value: 'String' }];

const ModelFieldSettings = ({ model }) => {
    const [updateFieldSettings] = useUpdateFieldSettingsMutation();

    const { fieldSettings } = useSelector(state => state.modelSettingsData);

    const [fields, setFields] = useState([]);
    const [selectedField, setSelectedField] = useState(null);

    const [openToast, setOpenToast] = React.useState(false);
    const [settingChangeToast, setSettingChangeToast] = React.useState({ msg: '', type: 'success' });

    const hideUnhideField = (index, Hidden) => {
        setFields(prevState => {
            const updatedFields = [...prevState];
            const updatedField = { ...updatedFields[index], Hidden: Hidden };
            updatedFields[index] = updatedField;
            return updatedFields;
        });
    };

    const removeField = index => {
        setFields(prevState => {
            const updatedFields = [...prevState];
            updatedFields.splice(index, 1);
            return updatedFields;
        });
    };

    const handleChange = (name, value, index) => {
        setFields(prevState => {
            const updatedFields = [...prevState];
            const updatedField = { ...updatedFields[index], [name]: value };
            updatedFields[index] = updatedField;
            return [...updatedFields];
        });
    };

    const addField = () => {
        setFields([
            ...fields,
            {
                Field: '',
                Type: 'String',
                Hidden: false,
                Mandatory: false,
                Confidence: 0,
                ValidationRule: '',
                CustomPostProcessing: '',
            },
        ]);
    };

    useEffect(() => {
        setFields(fieldSettings?.Fields ? fieldSettings.Fields : []);
    }, [fieldSettings]);

    const saveFieldSettings = () => {
        // check if all the items of fields state have values in all keys if no set error
        const errorFields = fields.filter(({ Field, Type }) => !Field || !Type);
        if (errorFields.length) {
            setSettingChangeToast({ msg: 'Fill all fields   ', type: 'error' });
            setOpenToast(true);
            return;
        }

        const payload = {
            modelName: model.Name,
            owner: model.CreatedBy,
            fields,
        };
        // API call to save field settings
        updateFieldSettings(payload).then(res => {
            if (res.error) {
                setSettingChangeToast({ msg: SITE_TEXT.APIERROR, type: 'error' });
                setOpenToast(true);
            } else {
                setSettingChangeToast({
                    msg: res?.data?.Message ? res.data.Message : SITE_TEXT.SettingsUpdateSuccess,
                    type: 'success',
                });
                setOpenToast(true);
            }
        });
        handleSettingsClose();
    };

    const handleSettingsClose = () => {
        setSelectedField(null);
    };

    return (
        <div className="settingsContentWrapper">
            <div id="subHead" className="subHead">
                <h2>{SITE_TEXT.FieldSettings}</h2>
            </div>
            <div className="mainContainer fieldSettingContainer">
                <div className="fieldContainer">
                    <div className="field title">
                        <span className="key">{SITE_TEXT.FieldName}</span>
                        <span className="value">{SITE_TEXT.DataType}</span>
                    </div>
                    {fields?.map((_, i) => {
                        const { Field, Hidden = false, Type } = _ || {};

                        return (
                            <div className="field" key={i}>
                                <div className="key">
                                    <StyledTooltip title={SITE_TEXT.Delete}>
                                        <IndeterminateCheckBoxOutlinedIcon onClick={() => removeField(i)} />
                                    </StyledTooltip>
                                    <StyledTooltip title={Hidden ? SITE_TEXT.Show : SITE_TEXT.Hide}>
                                        {Hidden ? (
                                            <VisibilityOffOutlinedIcon onClick={() => hideUnhideField(i, !Hidden)} />
                                        ) : (
                                            <RemoveRedEyeOutlinedIcon onClick={() => hideUnhideField(i, !Hidden)} />
                                        )}
                                    </StyledTooltip>
                                    <TextField
                                        defaultValue={Field}
                                        value={Field}
                                        size="small"
                                        onChange={({ target }) => handleChange('Field', target.value, i)}
                                        placeholder="Field Name"
                                    />
                                </div>
                                <div className="value">
                                    <CustomSelect
                                        disabled={Hidden}
                                        fullWidth
                                        options={TYPES}
                                        answer={Type}
                                        showLabel={false}
                                        handleChange={value => handleChange('Type', value, i)}
                                    />
                                    <SettingsIcon onClick={() => setSelectedField(i)} />
                                </div>
                            </div>
                        );
                    })}
                    <Button
                        sx={{ width: '40%', marginLeft: '32px', marginTop: '24px' }}
                        className="addField"
                        onClick={addField}>
                        {SITE_TEXT.AddField}
                    </Button>
                </div>

                <Button
                    sx={{ width: '40%', marginLeft: '32px', marginTop: '24px' }}
                    className="primaryBtn"
                    onClick={saveFieldSettings}>
                    {SITE_TEXT.SaveChanges}
                </Button>
            </div>
            <Toastr
                message={settingChangeToast.msg}
                open={openToast}
                setOpen={setOpenToast}
                type={settingChangeToast.type}
                autoHideDuration={4000}
            />
            <Drawer
                anchor="right"
                open={!!selectedField || selectedField === 0}
                onClose={handleSettingsClose}
                classes={{
                    root: 'fieldSettingsDrawer',
                }}
                PaperProps={{
                    sx: {
                        height: '100%',
                        top: 0,
                        width: '35%',
                    },
                }}>
                {selectedField || selectedField === 0 ? (
                    <aside className="filterContent">
                        <h2>
                            {SITE_TEXT.Settings}
                            <IconButton aria-label="close" className="closeButton" onClick={handleSettingsClose}>
                                <CloseIcon className="CloseIcon" alt="" />
                            </IconButton>
                        </h2>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.FieldName}</h3>
                            <TextField
                                defaultValue={fields[selectedField]?.Field}
                                value={fields[selectedField]?.Field}
                                size="small"
                                onChange={({ target }) => handleChange('Field', target.value, selectedField)}
                                placeholder={SITE_TEXT.EnterField}
                            />
                        </div>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.DataType}</h3>
                            <div className="dropDownContent">
                                <CustomSelect
                                    disabled={fields[selectedField]?.Hidden}
                                    fullWidth
                                    options={TYPES}
                                    answer={fields[selectedField]?.Type}
                                    showLabel={false}
                                    handleChange={value => handleChange('Type', value, selectedField)}
                                />
                            </div>
                        </div>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.ConfidenceScore}</h3>
                            <TextField
                                defaultValue={fields[selectedField]?.Confidence}
                                value={fields[selectedField]?.Confidence}
                                size="small"
                                type="number"
                                onChange={({ target }) => handleChange('Confidence', target.value, selectedField)}
                                placeholder={SITE_TEXT.ConfidenceScorePlaceholder}
                            />
                        </div>
                        <div
                            className="rowDrawer"
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>{SITE_TEXT.MandatoryField}</h3>
                            <Switch
                                checked={fields[selectedField]?.Mandatory}
                                onChange={({ target }) => handleChange('Mandatory', target.checked, selectedField)}
                            />
                        </div>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.ValidationRules}</h3>
                            <TextField
                                defaultValue={fields[selectedField]?.ValidationRule}
                                value={fields[selectedField]?.ValidationRule}
                                size="small"
                                onChange={({ target }) => handleChange('ValidationRule', target.value, selectedField)}
                                placeholder={SITE_TEXT.ValidationRulesPlaceholder}
                                rows={4}
                                multiline
                            />
                        </div>
                        <div className="rowDrawer">
                            <h3>{SITE_TEXT.CustomPostProcessing}</h3>
                            <TextField
                                defaultValue={fields[selectedField]?.CustomPostProcessing}
                                value={fields[selectedField]?.CustomPostProcessing}
                                size="small"
                                onChange={({ target }) =>
                                    handleChange('CustomPostProcessing', target.value, selectedField)
                                }
                                placeholder={SITE_TEXT.CustomPostProcessingPlaceholder}
                                rows={4}
                                multiline
                            />
                        </div>
                        <div>
                            <Button
                                sx={{ width: '40%', marginLeft: '32px', marginTop: '24px' }}
                                className="primaryBtn"
                                onClick={saveFieldSettings}>
                                {SITE_TEXT.SaveChanges}
                            </Button>
                        </div>
                    </aside>
                ) : null}
            </Drawer>
        </div>
    );
};
export default ModelFieldSettings;

ModelFieldSettings.propTypes = {
    model: PropTypes.any.isRequired,
};
