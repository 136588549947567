import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import BG from '../../assets/loginBG.svg';

import './index.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { SITE_TEXT } from '../../utils/constants';
import { isPasswordValid } from '../../utils/functions';
import Loader from '../../components/Loader';
import { useUpdateTempPassMutation } from './apiService';
import { useSelector } from 'react-redux';
import PasswordField from '../../components/common/PasswordField';

const UpdatePasswordDialog = ({ open, UserName, TemporaryPassword }) => {
    const [updateTempPass, { isLoading }] = useUpdateTempPassMutation();

    const { tempPassError } = useSelector(state => state.loginData);

    const [body, updateParams] = React.useState({
        Password: '',
        RePassword: '',
    });

    const [errors, updateErrors] = React.useState({
        Password: false,
        RePassword: false,
        passNoMatch: false,
        invalidPass: false,
    });

    const handleData = ({ name, value }) => {
        const handlePassError = name === 'Password' || name === 'RePassword';
        const additionalError = handlePassError ? { passNoMatch: false, invalidPass: false } : {};
        updateParams(old => ({
            ...old,
            [name]: value,
        }));
        updateErrors(old => ({
            ...old,
            ...additionalError,
            [name]: false,
        }));
    };

    const submit = async e => {
        e.preventDefault();
        const { Password, RePassword } = body;
        const passNoMatch = Password !== RePassword;
        const invalidPass = !isPasswordValid(Password);
        updateErrors({
            Password: !Password,
            RePassword: !RePassword,
            passNoMatch,
            invalidPass,
        });
        if (Password && RePassword && !passNoMatch && !invalidPass) {
            const temp = {
                UserName,
                TemporaryPassword,
                NewPassword: Password,
            };
            updateTempPass(temp);
        }
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth className="addUserDialog">
            <DialogTitle style={{ padding: 0 }}>
                <img src={BG} style={{ width: '100%', height: '100%' }} />
            </DialogTitle>
            <DialogContent className="dialogContainer" style={{ paddingBottom: 0 }}>
                {isLoading && <Loader position="fixed" />}

                <div className="text">
                    <h3>{SITE_TEXT.Welcone}</h3>
                    <p>{SITE_TEXT.WelcomeCreatePass}</p>
                </div>
                <div
                    className={c('inputWrap', {
                        errorWrap: errors.Password,
                    })}>
                    <label>{SITE_TEXT.PasswordText}</label>
                    <PasswordField
                        fullWidth
                        type="password"
                        name="Password"
                        value={body.Password}
                        onChange={({ target }) => handleData(target)}
                        placeholder={SITE_TEXT.PasswordPlaceholder}
                        inputProps={{ maxLength: 100 }}
                    />
                    {errors.Password && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                </div>
                <div
                    className={c('inputWrap', {
                        errorWrap: errors.RePassword || errors.passNoMatch,
                    })}>
                    <label>{SITE_TEXT.RePasswordText}</label>
                    <TextField
                        fullWidth
                        type="password"
                        name="RePassword"
                        value={body.RePassword}
                        onChange={({ target }) => handleData(target)}
                        placeholder={SITE_TEXT.RePasswordText}
                        inputProps={{ maxLength: 100 }}
                    />
                    {errors.RePassword && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                    {!errors.RePassword && errors.passNoMatch && (
                        <div className="warning">{SITE_TEXT.InvalidPassMatch}</div>
                    )}
                    {!errors.RePassword && errors.invalidPass && (
                        <div className="warning">{SITE_TEXT.InvalidPassMatchRegex}</div>
                    )}
                    {tempPassError && <h2 className="error">{tempPassError}</h2>}
                </div>
            </DialogContent>
            <DialogActions style={{ padding: '24px', paddingTop: 0 }}>
                <Button fullWidth onClick={submit}>
                    {SITE_TEXT.Confirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdatePasswordDialog;

UpdatePasswordDialog.propTypes = {
    open: PropTypes.any.isRequired,
    UserName: PropTypes.string.isRequired,
    TemporaryPassword: PropTypes.string.isRequired,
};
