import React from 'react';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import c from 'classnames';
import { useDropzone } from 'react-dropzone';

import Loader from '../Loader';
import { CommonSelectMenu } from '../../components/common/StyledComponents';
import UploadIcon from '../../assets/UploadIcon.png';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';

export default function UploadDocModal({
    showLoader = false,
    onClose,
    leftButtonHandler,
    rightButtonHandler,
    filteredModels = [],
    showModels = false,
}) {
    const [fileUploaded, updateFile] = React.useState([]);
    const [modelSelected, updateSelectedModel] = React.useState('0');

    const onDrop = React.useCallback(acceptedFiles => {
        const filesToSend = acceptedFiles;
        if (acceptedFiles && acceptedFiles.length) {
            updateFile(filesToSend);
        }
    }, []);

    const { getRootProps, getInputProps, open, isDragActive, isDragReject } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        maxFiles: 10,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
        },
    });

    const sendUploadedFile = () => {
        const modelIndex = parseInt(modelSelected, 10);
        const docModelName = showModels ? filteredModels[modelIndex - 1] : null;
        rightButtonHandler(fileUploaded, docModelName);
    };

    return (
        <Modal
            open
            classes={{ root: 'uploadDocModalRoot' }}
            onClose={onClose}
            aria-labelledby="confirm-user-action-modal"
            aria-describedby="confirm-user-action-modal"
        >
            <section className="modalContent">
                {showLoader && <Loader />}
                <h2>
                    {SITE_TEXT.UploadFilesHeader}
                    <IconButton aria-label="close" className="closeButton" onClick={onClose}>
                        <CloseIcon className="CloseIcon" alt="" />
                    </IconButton>
                </h2>

                <section
                    className={c('dragDropFile', { 'drag-active': isDragActive, 'drag-reject': isDragReject })}
                    {...getRootProps()}
                    onClick={open}
                >
                    <input {...getInputProps()} />
                    <img alt="UploadIcon" src={UploadIcon} />
                    {fileUploaded?.length ? (
                        fileUploaded.map((_,i)=><p className="fileUploaded" key={i}>{_.name}</p>)
                    ) : (
                        <React.Fragment>
                            <h3>{SITE_TEXT.dNdFiles}</h3>
                            <p>{SITE_TEXT.supportedDocs}</p>
                        </React.Fragment>
                    )}
                </section>
                {showModels && (
                    <CommonSelectMenu
                        fullWidth
                        question_text={SITE_TEXT.selectModel}
                        answer={modelSelected}
                        options={filteredModels}
                        handleChange={value => updateSelectedModel(value)}
                    />
                )}

                <div className="buttonWrap">
                    <Button className="leftButton" onClick={leftButtonHandler}>
                        {SITE_TEXT.Cancel}
                    </Button>
                    <Button
                        className={c('rightButton', {
                            disabled: !fileUploaded || (showModels && modelSelected === '0'),
                        })}
                        onClick={sendUploadedFile}
                    >
                        {SITE_TEXT.uploadText}
                    </Button>
                </div>
            </section>
        </Modal>
    );
}

UploadDocModal.propTypes = {
    showLoader: PropTypes.bool,
    showModels: PropTypes.bool,
    errorList: PropTypes.array,
    filteredModels: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    leftButtonHandler: PropTypes.func.isRequired,
    rightButtonHandler: PropTypes.func.isRequired,
};
