import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import c from 'classnames';

import DocumentModels from './containers/DocumentModels';
import Documents from './containers/Documents';
import ErrorHandler from './components/ErrorHandler';
import LoginContainer from './containers/Login';
import ReviewDocument from './containers/ReviewDocument';
import AllDocuments from './containers/AllDocuments';
import AuthStore from './components/common/AuthStore';
import Settings from './containers/Settings';
import UploadStatusPopover from './containers/UploadStatusPopover';
import ActivityLogs from './containers/ActivityLogs';
import { useUploadStatusMutation } from './containers/DocumentModels/uploadService';
import Toastr from './components/common/Toastr';
import { SITE_TEXT } from './utils/constants';
import { ThemeProvider, createTheme } from '@mui/material';
import ModelSettings from './containers/ModelSettings';

const App = () => {
    const { accessToken } = AuthStore;
    const message = '404 - Page not found';

    const [uploadStatus] = useUploadStatusMutation();

    const { user, apiError: userApiError, tokenError } = useSelector(state => state.userData);
    const { documentId, apiError: docApiError } = useSelector(state => state.docModelsData);
    const { apiError: loginApiError } = useSelector(state => state.loginData);
    const { apiError: modelSettngError } = useSelector(state => state.modelSettingsData);

    const [timeout, setTimeoutV] = React.useState(null);

    const [openErrorToast, setOpenErrorToast] = React.useState(false);

    const updateStatusCall = async () => {
        if (documentId?.length > 0) {
            const data = await uploadStatus(documentId);
            const status = data.data.Documents.flatMap(_ => _.Status);
            if (status.includes('Processing') || status.includes('In_Queue')) {
                setTimeoutV(
                    setTimeout(() => {
                        updateStatusCall(documentId);
                    }, 5000)
                );
            }
        }
    };

    useEffect(() => {
        clearTimeout(timeout);
        updateStatusCall(documentId);
    }, [documentId]);

    useEffect(() => {
        if (userApiError || docApiError || loginApiError || modelSettngError) setOpenErrorToast(true);
    }, [userApiError, docApiError, loginApiError, modelSettngError]);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#04B2A4',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <div
                className={c('mainContentWrapper', {
                    accessToken,
                })}>
                <BrowserRouter>
                    {!accessToken ? (
                        <LoginContainer />
                    ) : (
                        <>
                            <Routes>
                                <Route path="/" element={<DocumentModels />} exact />
                                <Route path="/documents/:modelId" element={<Documents />} exact />
                                <Route path="/documents/:modelId/settings" element={<ModelSettings />} exact />
                                <Route path="/reviewDocument/:DocumentID" element={<ReviewDocument />} exact />
                                <Route path="/myDocuments" element={<AllDocuments />} exact />
                                <Route path="/settings" element={<Settings />} exact />
                                <Route path="*" element={<ErrorHandler errorData={{ message }} />} />
                                {user?.role?.toLowerCase() !== 'member' && (
                                    <Route path="activity-log" element={<ActivityLogs />} />
                                )}
                            </Routes>
                            <UploadStatusPopover />
                        </>
                    )}
                </BrowserRouter>

                <Toastr
                    message={tokenError ? 'Please wait while we refresh your session token.' : SITE_TEXT.APIERROR}
                    open={openErrorToast}
                    setOpen={setOpenErrorToast}
                    type={tokenError ? 'warn' : 'error'}
                    autoHideDuration={4000}
                />
            </div>
        </ThemeProvider>
    );
};

export default App;
