import React from 'react';
import c from 'classnames';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { CommonSelectMenu } from '../../components/common/StyledComponents';
import Loader from '../Loader';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';

export default function AddNewDocModal({
    errorList = null,
    onClose,
    leftButtonHandler,
    rightButtonHandler,
    showLoader,
    modelsMeta,
}) {
    const [DocumentName, updateDocumentName] = React.useState('');
    const [geographySelected, updateSelectedGeography] = React.useState(0);
    const [modelSelected, updateSelectedModel] = React.useState(0);
    const [modelOptions, updateModelOptions] = React.useState([]);
    const [DocumentType, setDocumentType] = React.useState(null);
    const { dropdown = {}, Geography = [] } = modelsMeta;

    const selectCurrentModel = newModel => {
        updateModelOptions(dropdown[newModel]);
        updateSelectedModel('0');
        setDocumentType(newModel);
    };

    const disabled = React.useMemo(() => {
        return !DocumentName || !geographySelected || !modelSelected;
    }, [DocumentName, geographySelected, modelSelected]);

    const sendData = () => {
        const DocumentGeography = Geography[geographySelected - 1];
        const DocumentCategory = modelOptions[modelSelected - 1];
        rightButtonHandler([
            {
                DocumentType: DocumentCategory,
                DocumentName: `${DocumentName} - ${DocumentCategory}`,
                DocumentCategory: DocumentType,
                DocumentGeography,
            },
        ]);
    };

    return (
        <Modal
            open
            classes={{ root: 'addModalRoot' }}
            onClose={onClose}
            aria-labelledby="confirm-user-action-modal"
            aria-describedby="confirm-user-action-modal"
        >
            <section className="modalContent">
                {showLoader && <Loader />}
                <h2>
                    {SITE_TEXT.AddNewModel}
                    <IconButton aria-label="close" className="closeButton" onClick={onClose}>
                        <CloseIcon className="CloseIcon" alt="" />
                    </IconButton>
                </h2>
                <p>{SITE_TEXT.AddModelDesc}</p>
                {errorList && (
                    <ul className="errorInfo">
                        {errorList.map((errorMessage, errIndex) => (
                            <li key={errIndex}>{errorMessage}</li>
                        ))}
                    </ul>
                )}
                <section className="dataSection">
                    {Object.keys(dropdown).map(name => {
                        return (
                            <Button
                                key={name}
                                className={c({
                                    activeButton: name === DocumentType,
                                })}
                                onClick={() => selectCurrentModel(name)}
                            >
                                {name}
                            </Button>
                        );
                    })}
                </section>

                <CommonSelectMenu
                    fullWidth
                    question_text={SITE_TEXT.Geography}
                    answer={geographySelected}
                    options={Geography}
                    handleChange={value => updateSelectedGeography(value)}
                />
                {DocumentType && (
                    <React.Fragment>
                        <CommonSelectMenu
                            fullWidth
                            question_text={SITE_TEXT.ChooseModel}
                            answer={modelSelected}
                            options={modelOptions}
                            handleChange={value => updateSelectedModel(value)}
                        />
                        <div className="inputWrap">
                            <label>{SITE_TEXT.ENTER_MODEL_NAME}</label>
                            <TextField
                                fullWidth
                                value={DocumentName}
                                onChange={({ target }) => updateDocumentName(target.value)}
                                placeholder={SITE_TEXT.ENTER_MODEL_NAME}
                                inputProps={{ maxLength: 100 }}
                            />
                        </div>
                    </React.Fragment>
                )}
                <div className="buttonWrap">
                    <Button className="leftButton" onClick={leftButtonHandler}>
                        {SITE_TEXT.Cancel}
                    </Button>
                    <Button className={c('rightButton', { disabled })} onClick={sendData}>
                        {SITE_TEXT.Confirm}
                    </Button>
                </div>
            </section>
        </Modal>
    );
}

AddNewDocModal.propTypes = {
    errorList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    modelsMeta: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    leftButtonHandler: PropTypes.func.isRequired,
    rightButtonHandler: PropTypes.func.isRequired,
    showLoader: PropTypes.bool.isRequired,
};
