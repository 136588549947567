/* eslint-disable indent */
import React from 'react';
import { SITE_TEXT } from '../../utils/constants';
import loginHead from '../../assets/logo.svg';
import { Button, TextField } from '@mui/material';
import c from 'classnames';
import { isPasswordValid, validateEmail } from '../../utils/functions';
import { useSendVerificationCodeMutation, useVerifyOtpMutation } from './apiService';
import Toastr from '../../components/common/Toastr';
import Loader from '../../components/Loader';
import OTPInput from 'react-otp-input';
import PasswordField from '../../components/common/PasswordField';
import PropTypes from 'prop-types';

const ForgotPassword = ({ setShowForgetPass }) => {
    const [forgotPasswordStep, setForgotPasswordStep] = React.useState(0);
    const [email, setEmail] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [toast, setToast] = React.useState({ msg: '', type: 'success' });
    const [openToast, setOpenToast] = React.useState(false);

    const [sendVerificationCode, { isLoading: verifyLoading }] = useSendVerificationCodeMutation();
    const [verifyOtp, { isLoading: otpLoading }] = useVerifyOtpMutation();

    const [errors, setErrors] = React.useState({
        email: false,
        otp: false,
        password: false,
        confirmPassword: false,
    });

    const handleData = ({ name, value }) => {
        setErrors({
            ...errors,
            [name]: false,
        });

        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'otp':
                setOtp(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        if (forgotPasswordStep === 0) {
            const emailError = !validateEmail(email);
            setErrors({
                email: emailError,
            });
            if (emailError) {
                return;
            }
            sendVerificationCode({ email })
                .then(res => {
                    if (res.error) {
                        setOpenToast(true);
                        setToast({
                            msg: res.error?.data ? res.error?.data?.detail : SITE_TEXT?.SomethingWentWrong,
                            type: 'error',
                        });
                        return;
                    }
                    setForgotPasswordStep(1);
                })
                .catch(err => {
                    setOpenToast(true);
                    setToast({
                        msg: err?.data?.message || SITE_TEXT?.SomethingWentWrong,
                        type: 'error',
                    });
                });
        } else if (forgotPasswordStep === 1) {
            // verify otp
            setForgotPasswordStep(2);
        } else if (forgotPasswordStep === 2) {
            const passNoMatch = password !== confirmPassword;
            const invalidPass = !isPasswordValid(password);
            setErrors({
                password: !password,
                confirmPassword: !confirmPassword,
                passNoMatch,
                invalidPass,
            });
            if (password && confirmPassword && !passNoMatch && !invalidPass) {
                verifyOtp({ email, verification_code: otp, new_password: password })
                    .then(res => {
                        if (res.error) {
                            setOpenToast(true);
                            setToast({
                                msg: res.error?.data?.detail || SITE_TEXT?.SomethingWentWrong,
                                type: 'error',
                            });
                            if (res.error?.data?.detail === 'Invalid verification code.') {
                                setForgotPasswordStep(1);
                                setErrors({
                                    ...errors,
                                    otp: true,
                                });
                            }

                            return;
                        }
                        setForgotPasswordStep(3);
                        // success tastr
                        setOpenToast(true);
                        setToast({
                            msg: 'updated successfully',
                            type: 'success',
                        });
                        setShowForgetPass(false);
                    })
                    .catch(err => {
                        setOpenToast(true);
                        setToast({
                            msg: err?.data?.message ? err?.data?.message : SITE_TEXT?.APIERROR,
                            type: 'error',
                        });
                    });
            }
        }
    };

    return (
        <main className="wrapper">
            {(verifyLoading || otpLoading) && <Loader position="fixed" />}
            <div className="loginWrapper">
                <div className="loginHead">
                    <img src={loginHead} alt="" />
                </div>
                <div>
                    {' '}
                    <div className="forgotHeader">
                        {forgotPasswordStep == 0
                            ? SITE_TEXT.VerifyEmail
                            : forgotPasswordStep === 1
                            ? SITE_TEXT?.EnterVerificationCode
                            : SITE_TEXT.ResetPass}
                    </div>
                    <div className="forgotSubHeader">
                        {forgotPasswordStep == 0
                            ? SITE_TEXT.VerifyEmailSubHeader
                            : forgotPasswordStep === 1
                            ? SITE_TEXT?.VerificationCodeSubHeader1 + email + SITE_TEXT?.VerificationCodeSubHeader2
                            : ''}
                    </div>
                </div>
                <div>
                    {forgotPasswordStep == 0 ? (
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Email,
                            })}>
                            <label>{SITE_TEXT.EmailText}</label>
                            <TextField
                                fullWidth
                                name="email"
                                value={email}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.EmailPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.email && <div className="warning">{SITE_TEXT.InvalidEmail}</div>}
                        </div>
                    ) : forgotPasswordStep === 1 ? (
                        <>
                            <OTPInput
                                value={otp}
                                onChange={e => {
                                    setErrors({
                                        ...errors,
                                        otp: false,
                                    });
                                    setOtp(e);
                                }}
                                numInputs={6}
                                containerStyle={{
                                    display: 'flex',
                                    gap: '24px',
                                    justifyContent: 'center',
                                }}
                                inputStyle={{
                                    borderColor: 'red !important',
                                }}
                                renderInput={props => (
                                    <input
                                        {...props}
                                        className={`otpInput ${errors.otp ? 'errorWrap' : ''}`}
                                        // eslint-disable-next-line react/prop-types
                                        style={{ borderColor: props.value ? '#36C1B6' : '#d0d5dd' }}
                                    />
                                )}
                            />
                            {errors?.otp && (
                                <div
                                    style={{
                                        color: 'red',
                                        fontSize: '14px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                        marginTop: '12px',
                                    }}>
                                    {toast.msg}
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            <div
                                className={c('inputWrap', {
                                    errorWrap: errors.password,
                                })}>
                                <label>{SITE_TEXT.PasswordText}</label>
                                <PasswordField
                                    fullWidth
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={({ target }) => handleData(target)}
                                    placeholder={SITE_TEXT.PasswordPlaceholder}
                                    inputProps={{ maxLength: 100 }}
                                />
                                {errors.password && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                            </div>
                            <div
                                className={c('inputWrap', {
                                    errorWrap: errors.RePassword || errors.passNoMatch,
                                })}>
                                <label>{SITE_TEXT.RePasswordText}</label>
                                <PasswordField
                                    fullWidth
                                    type="password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={({ target }) => handleData(target)}
                                    placeholder={SITE_TEXT.RePasswordText}
                                    inputProps={{ maxLength: 100 }}
                                />
                                {errors.confirmPassword && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                                {!errors.confirmPassword && errors.passNoMatch && (
                                    <div className="warning">{SITE_TEXT.InvalidPassMatch}</div>
                                )}

                                {!errors.RePassword && errors.invalidPass && (
                                    <div className="warning">{SITE_TEXT.InvalidPassMatchRegex}</div>
                                )}
                            </div>{' '}
                        </div>
                    )}
                </div>
                <div>
                    <Button onClick={handleSubmit} fullWidth>
                        {forgotPasswordStep == 0
                            ? SITE_TEXT.SendVerificationCode
                            : forgotPasswordStep === 1
                            ? SITE_TEXT?.VerifyText
                            : SITE_TEXT.UpdatePassAndLogin}
                    </Button>
                </div>
            </div>
            <Toastr
                message={toast.msg}
                open={openToast}
                setOpen={setOpenToast}
                type={toast.type}
                autoHideDuration={4000}
            />
        </main>
    );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
    setShowForgetPass: PropTypes.func,
};
