const colors = {
    $white: '#ffffff',
    $black: '#000000',
    $green: '#32d583',
    $yellow: '#fdB022',
    $red: '#f97066',
    $blue: '#0679c3',
};

export default colors;
