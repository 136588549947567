import { createSlice } from '@reduxjs/toolkit';

import { docModelsApi } from './apiService';
import { docModelsUploadApi } from './uploadService';

export const initialState = {
    errorInfo: null,
    isLoading: false,
    loadingCount: 0,
    docModels: [],
    modelsMeta: {},
    modelsMetaFetched: false,
    documents: [],
    allDocuments: [],
    currentModel: '',
    uploadStatusList: [],
    documentId: [],
    apiError: 0,
};

export const docModelsData = createSlice({
    name: 'docModelsData',
    initialState,
    reducers: {
        setCurrentModel: (state, action) => {
            state.currentModel = action.payload;
        },
        updateCurrentDocs: (state, action) => {
            state.documents = action.payload;
        },
        updateDocModels: (state, action) => {
            state.docModels = action.payload;
        },
        updateAllDocs: (state, action) => {
            state.allDocuments = action.payload;
        },
        updateDocs: (state, action) => {
            state.documents = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(docModelsApi.endpoints.getDocumentModels.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                state.errorInfo = data;
                state.apiError += 1;
            })
            .addMatcher(docModelsApi.endpoints.getDocumentModels.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                state.modelsMeta = payload;
                state.modelsMetaFetched = true;
            })
            .addMatcher(docModelsApi.endpoints.deleteDocumentForModel.matchFulfilled, (state, action) => {
                const { meta = {} } = action;
                const { arg = {} } = meta;
                const { originalArgs = {} } = arg;
                const { currentId } = originalArgs;
                const { documents = [], allDocuments = [] } = state;
                state.documents = documents.filter(({ DocumentID }) => DocumentID !== parseInt(currentId, 10));
                state.allDocuments = allDocuments.filter(({ DocumentID }) => DocumentID !== parseInt(currentId, 10));
            })
            .addMatcher(docModelsApi.endpoints.deleteDocumentModel.matchFulfilled, (state, action) => {
                const { meta = {} } = action;
                const { arg = {} } = meta;
                const { originalArgs = {} } = arg;
                const { currentId } = originalArgs;
                const { docModels } = state;
                state.docModels = docModels.filter(({ id }) => id !== currentId);
            })
            .addMatcher(docModelsApi.endpoints.getDocumentsType.matchPending, state => {
                state.errorInfo = null;
            })
            .addMatcher(docModelsApi.endpoints.getDocumentsType.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                state.errorInfo = data;
                state.apiError += 1;
            })
            .addMatcher(docModelsApi.endpoints.getDocumentsType.matchFulfilled, (state, action) => {
                const { payload } = action;
                const { documents } = payload || {};
                state.docModels = documents || [];
            })
            .addMatcher(docModelsApi.endpoints.getAllDocuments.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                state.errorInfo = data;
                state.apiError += 1;
            })
            .addMatcher(docModelsApi.endpoints.getAllDocuments.matchFulfilled, (state, action) => {
                const { payload } = action;
                const { documents } = payload || {};
                state.allDocuments = documents || [];
            })
            .addMatcher(docModelsApi.endpoints.getDocumentsForModel.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                state.errorInfo = data;
                state.apiError += 1;
            })
            .addMatcher(docModelsApi.endpoints.getDocumentsForModel.matchFulfilled, (state, action) => {
                const { payload } = action;
                const { documents } = payload || {};
                state.documents = documents || [];
            })
            .addMatcher(docModelsUploadApi.endpoints.uploadDocumentsForModel.matchFulfilled, (state, action) => {
                const { payload } = action;
                const { document_id = [] } = payload || {};
                state.documentId = [...state.documentId, ...document_id];
            })
            .addMatcher(docModelsUploadApi.endpoints.uploadStatus.matchFulfilled, (state, action) => {
                const { payload } = action;
                const { Documents } = payload;
                state.uploadStatusList = Documents ? Documents : [];
            })
            .addMatcher(docModelsApi.endpoints.editDocumentModel.matchFulfilled, () => {})
            .addMatcher(docModelsApi.endpoints.editDocumentModel.matchRejected, state => {
                state.apiError += 1;
            })
            .addMatcher(docModelsApi.endpoints.editDocument.matchFulfilled, () => {})
            .addMatcher(docModelsApi.endpoints.editDocument.matchRejected, state => {
                state.apiError += 1;
            });
    },
});

// Action creators are generated for each case reducer function
export const { setCurrentModel, updateCurrentDocs, updateDocModels, updateDocs, updateAllDocs } = docModelsData.actions;

export default docModelsData.reducer;
