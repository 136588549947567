import { createSlice } from '@reduxjs/toolkit';

import { userManagementApi } from './apiService';
import AuthStore from '../../components/common/AuthStore';

export const initialState = {
    userAddError: '',
    userAddSuccess: '',
    user: {},
    teamList: [],
    activityLogs: [],
    apiError: 0,
    tokenError: 0,
    passwordChangeToast: { msg: '', type: '' },
    loadingRefreshToken: false,
    // userAccountInfo: {},
    // passwordChangeError: '',
    // passwordChangeSuccess: '',
};

export const userData = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        resetError: state => {
            state.userAddError = '';
            state.userAddSuccess = '';
            state.activityLogs = [];
            state.passwordChangeToast = { msg: '', type: '' };

            // state.teamList = [];
            // state.userAccountInfo = {};
            // state.passwordChangeError = '';
            // state.passwordChangeSuccess = '';
        },
        setTeamList: (state, action) => {
            state.teamList = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(userManagementApi.endpoints.addUser.matchRejected, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                const { detail = '' } = data;
                state.userAddError = detail;
                state.apiError += 1;
            })
            .addMatcher(userManagementApi.endpoints.addUser.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                const { data = {} } = payload;
                const { detail = '' } = data;
                state.userAddSuccess = detail;
            })
            .addMatcher(userManagementApi.endpoints.getUserDetails.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                state.user = payload.account_settings;
                state.teamList = payload.team;
            })
            .addMatcher(userManagementApi.endpoints.getUserDetails.matchRejected, () => {})
            .addMatcher(userManagementApi.endpoints.refreshToken.matchPending, state => {
                state.loadingRefreshToken = true;
            })
            .addMatcher(userManagementApi.endpoints.refreshToken.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                AuthStore.accessToken = `Bearer ${payload.access_token}`;
                AuthStore.idToken = payload.id_token;
                state.loadingRefreshToken = false;
                window.location.reload();
            })
            .addMatcher(userManagementApi.endpoints.refreshToken.matchPending, state => {
                state.tokenError += 1;
            })
            .addMatcher(userManagementApi.endpoints.refreshToken.matchRejected, state => {
                AuthStore.deleteAccessToken();
                AuthStore.deleteIdToken();
                AuthStore.deleteUserName();
                AuthStore.deleteRefreshToken();
                AuthStore.deleteDocumentId();
                window.location.reload();
                state.loadingRefreshToken = false;
            })
            .addMatcher(userManagementApi.endpoints.getActivityLogs.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                state.activityLogs = payload.activity_log;
            })
            .addMatcher(userManagementApi.endpoints.resetPassword.matchFulfilled, (state, action) => {
                const { payload = {} } = action;
                state.passwordChangeToast = { msg: payload.Message, type: 'success' };
            })
            .addMatcher(userManagementApi.endpoints.resetPassword.matchRejected, state => {
                state.passwordChangeToast = {
                    msg: 'Password change failed. Please try after sometime.',
                    type: 'error',
                };
            });
    },
});

// Action creators are generated for each case reducer function
export const { resetError, setTeamList } = userData.actions;

export default userData.reducer;
