import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import c from 'classnames';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';

import { useDeleteDocumentForModelMutation, useEditDocumentMutation } from '../DocumentModels/apiService';
import ConfirmModal from '../../components/ConfirmModal';
import editIcon from '../../assets/edit.svg';
import trashIcon from '../../assets/trash.svg';
import { DOCUMENT_TABS, DOC_STATUS, SITE_TEXT } from '../../utils/constants';
import CustomTable from '../../components/common/Table';
import { TextField } from '@mui/material';

const DocsTable = ({
    apiLoading = false,
    documents = [],
    // showDocModelHeader = false,
    updateData = () => {},
}) => {
    const [deleteDocumentForModel, { isLoading }] = useDeleteDocumentForModelMutation();

    const [editDocument] = useEditDocumentMutation();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [currentId, setCurrentModelId] = React.useState(null);
    const [settingsEl, setSettingsElement] = React.useState(null);
    const [showConfirmModal, toggleConfirmModal] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(0);
    const [docEditStateId, setDocEditStateId] = React.useState(null);

    const { uploadStatusList } = useSelector(state => state.docModelsData);

    // React.useEffect(() => {
    //     const upperHead = document.getElementById('upperHead');
    //     const docNavigation = document.getElementById('docNavigation');
    //     let heightToReduce = 0;
    //     if (upperHead) {
    //         heightToReduce += upperHead.offsetHeight;
    //     }
    //     if (docNavigation) {
    //         heightToReduce += docNavigation.offsetHeight;
    //     }
    //     const docsTableContent = document.getElementById('docsTable');
    //     // if (docsTableContent) {
    //     //     docsTableContent.style.height = `calc(100vh - ${heightToReduce}px)`;
    //     // }
    // }, []);

    // const modifiedTableHeaders = React.useMemo(() => {
    //     if (!showDocModelHeader) {
    //         return DOCUMENT_TABLE_HEADERS.filter(header => header !== DOCUMENT_TABLE_HEADERS[2]);
    //     }
    //     return DOCUMENT_TABLE_HEADERS;
    // }, [showDocModelHeader]);

    const [tableData, setTableData] = React.useState([]);

    const { allDocLen: docInTabs, filteredDocs: currentTabDocs } = React.useMemo(() => {
        const allDocLen = [
            documents.length,
            documents.filter(({ Status }) => Status === DOC_STATUS[1]).length,
            documents.filter(({ Status }) => Status === DOC_STATUS[2]).length,
            documents.filter(({ Status }) => Status === DOC_STATUS[3] || Status === DOC_STATUS[6]).length,
            documents.filter(({ Status }) => Status === DOC_STATUS[4]).length,
            documents.filter(({ Status }) => Status === DOC_STATUS[5]).length,
        ];

        /* eslint-disable */
        const filteredDocs = currentTab
            ? documents.filter(({ Status }) => {
                  if (currentTab === 3) {
                      return Status === DOC_STATUS[currentTab] || Status === DOC_STATUS[6];
                  } else {
                      return Status === DOC_STATUS[currentTab];
                  }
              })
            : documents;
        /* eslint-enable */
        return {
            allDocLen,
            filteredDocs,
        };
    }, [documents, currentTab]);

    const handleReviewDoc = documentID => {
        navigate(`/reviewDocument/${documentID}`);
    };

    const handleHighlightModel = (e, id) => {
        setSettingsElement(e.currentTarget);
        setCurrentModelId(id);
    };

    const closeSettingsModel = () => {
        setSettingsElement(null);
        setCurrentModelId(null);
    };

    const handleRenameDoc = id => {
        setDocEditStateId(id);
        closeSettingsModel();
    };

    const handleDeleteModel = () => {
        toggleConfirmModal(true);
    };

    const closeDeleteModel = () => {
        toggleConfirmModal(false);
        closeSettingsModel();
    };

    const deleteSelectedDoc = () => {
        deleteDocumentForModel({ currentId }).then(() => {
            toggleConfirmModal(false);
            closeSettingsModel();
        });
    };

    const updateDocName = (newName, oldName, id) => {
        // Update the model name
        if (oldName == newName) {
            setDocEditStateId(null);
        }
        editDocument({ oldName, newName }).then(() => {
            setDocEditStateId(null);
            const data = tableData.map(_ => {
                return {
                    ..._,
                    DocumentName: _.DocumentID == id ? newName : _.DocumentName,
                };
            });
            dispatch(updateData(data));
        });
    };

    useEffect(() => {
        setTableData(currentTabDocs);
    }, [currentTabDocs]);

    useEffect(() => {
        const updatedDocs = currentTabDocs.map((doc, i) => {
            const updatedStatus = uploadStatusList?.find(_ => _?.DocumentID === doc?.DocumentID)?.Status;
            if (updatedStatus !== undefined) {
                return { ...doc, Status: updatedStatus, id: i };
            }
            return { ...doc, id: i };
        });
        setTableData(updatedDocs);
    }, [uploadStatusList, currentTabDocs]);

    // const renderTableRow = dataRecord => (
    //     <TableRow
    //         key={dataRecord.DocumentID}
    //         className={c({
    //             highLightRow: dataRecord.DocumentID === currentId,
    //         })}>
    //         <TableCell>
    //             <div className={`statusWrap ${dataRecord.Status}`}>{removeUnderscore(dataRecord.Status)}</div>
    //         </TableCell>
    //         <TableCell
    //             onClick={() => {
    //                 if (dataRecord?.Status !== 'Failed') handleReviewDoc(dataRecord.DocumentID);
    //             }}>
    //             {dataRecord.DocumentName}
    //         </TableCell>
    //         {showDocModelHeader && <TableCell>{dataRecord.DocumentType}</TableCell>}
    //         <TableCell>{moment(dataRecord.UploadDate).format('MMM DD, YYYY')}</TableCell>
    //         <TableCell>{dataRecord.UploadedBy}</TableCell>
    //         <TableCell>
    //             <div className="verticalIcon" onClick={e => handleHighlightModel(e, dataRecord.DocumentID)}>
    //                 <MoreVertIcon />
    //             </div>
    //             <Popover
    //                 id="settingsEl"
    //                 // open={Boolean(settingsEl)}
    //                 open={currentId === dataRecord.DocumentID}
    //                 anchorEl={settingsEl}
    //                 onClose={closeSettingsModel}
    //                 anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    //                 transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    //                 className="settingsPopOver">
    //                 <div onClick={handleRenameDoc}>
    //                     <img src={editIcon} alt="" />
    //                     {SITE_TEXT.RenameDocument}
    //                 </div>
    //                 <div onClick={handleDeleteModel}>
    //                     <img src={trashIcon} alt="" />
    //                     {SITE_TEXT.Delete}
    //                 </div>
    //             </Popover>
    //         </TableCell>
    //     </TableRow>
    // );

    // const renderEmptyContent = () => (
    //     <div className="emptyContent">
    //         <img src={addNewDoc} alt="" />
    //         <h2>{SITE_TEXT.noDocText}</h2>
    //         <p>{SITE_TEXT.uploadDocsText}</p>
    //     </div>
    // );

    const renderConfirmModal = () => (
        <ConfirmModal
            showLoader={isLoading}
            onClose={closeDeleteModel}
            modalHeader={SITE_TEXT.DELETE_DOC_HEADER}
            modalDescription={SITE_TEXT.DELETE_DOC_BODY}
            leftButtonText={SITE_TEXT.Cancel}
            leftButtonHandler={closeDeleteModel}
            rightButtonText={SITE_TEXT.Delete}
            rightButtonHandler={deleteSelectedDoc}
        />
    );
    const columns = [
        {
            field: 'Status',
            flex: 2,
            headerName: 'Status',
            renderCell: params => {
                const { DocNumber, Status } = params.row;
                return (
                    <div className={`tableCellInCustomTable statusWrap ${Status}`}>
                        {Status}
                        {DocNumber && <span> ({DocNumber})</span>}
                    </div>
                );
            },
        },
        {
            field: 'DocumentName',
            flex: 3,
            headerName: 'File Name',
            renderCell: params => {
                const { Status, DocumentID, DocumentName, DocumentRename } = params.row;
                const inEditState = docEditStateId === DocumentID;

                return (
                    <>
                        <div
                            className="tableCellInCustomTable nameWrap"
                            style={{ width: '100%' }}
                            onClick={() => {
                                if (Status !== 'Failed' && !inEditState) handleReviewDoc(DocumentID);
                            }}>
                            {inEditState ? (
                                <TextField
                                    fullWidth
                                    name="ModalName"
                                    // value={Name}
                                    defaultValue={DocumentRename ? DocumentRename : DocumentName}
                                    onBlur={({ target }) => {
                                        updateDocName(target.value, DocumentName, DocumentID);
                                    }}
                                    onKeyUp={event => {
                                        if (event.key == 'Enter') {
                                            updateDocName(event.target.value, DocumentName, DocumentID);
                                        }
                                    }}
                                    onKeyDown={event => {
                                        if (event.key == ' ') event.stopPropagation();
                                    }}
                                    placeholder={SITE_TEXT.ENTER_MODEL_NAME}
                                    inputProps={{ maxLength: 100 }}
                                    className="modalRename"
                                />
                            ) : DocumentRename ? (
                                DocumentRename
                            ) : (
                                DocumentName
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            field: 'UploadDate',
            flex: 2,
            headerName: 'Upload Date',
            renderCell: params => {
                const { UploadDate } = params.row;
                return <div className="tableCellInCustomTable">{moment(UploadDate).format('MMM DD, YYYY')}</div>;
            },
        },
        {
            field: 'UploadedBy',
            flex: 2,
            headerName: 'Uploaded By',
        },
        {
            field: '',
            width: 40,
            headerName: '',
            renderCell: params => {
                const { DocumentID } = params.row;
                return (
                    <div className="tableCellInCustomTable">
                        <div className="verticalIcon" onClick={e => handleHighlightModel(e, DocumentID)}>
                            <MoreVertIcon />
                        </div>
                        <Popover
                            id="settingsEl"
                            // open={Boolean(settingsEl)}
                            open={currentId === DocumentID}
                            anchorEl={settingsEl}
                            onClose={closeSettingsModel}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            className="settingsPopOver">
                            <div onClick={() => handleRenameDoc(DocumentID)}>
                                <img src={editIcon} alt="" />
                                {SITE_TEXT.RenameDocument}
                            </div>
                            <div onClick={handleDeleteModel}>
                                <img src={trashIcon} alt="" />
                                {SITE_TEXT.Delete}
                            </div>
                        </Popover>
                    </div>
                );
            },
        },
    ];

    const customOption = {
        rowHeight: '75px',
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}>
            <ul id="docNavigation" className="docNavigation clearfix">
                {DOCUMENT_TABS.map((headerVal, headerIndx) => {
                    const currentDocsLen = docInTabs[headerIndx];
                    return (
                        <li
                            onClick={() => setCurrentTab(headerIndx)}
                            className={c({
                                highLightRow: headerIndx === currentTab,
                            })}
                            key={headerIndx}>
                            {headerVal}
                            {currentDocsLen ? <span className="docInTabs">{currentDocsLen}</span> : null}
                        </li>
                    );
                })}
            </ul>
            <div style={{ flexGrow: 1 }}>
                <CustomTable data={tableData} columns={columns} isLoading={apiLoading} options={customOption} />
            </div>

            {/* <TableContainer component={Paper} className="docsTable" id="docsTable">
                <Table aria-label="docsTable">
                    <TableHead>
                        <TableRow>
                            {modifiedTableHeaders.map((headerVal, headerIndx) => (
                                <TableCell key={headerIndx}>{headerVal}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map(renderTableRow)}
                        {apiLoading && (
                            <>
                                {['', '', '', '', '', ''].map((_, index) => {
                                    return (
                                        <TableRow key={index} sx={{ height: '73px' }}>
                                            <TableCell>
                                                <Skeleton width="80%" height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton width="100%" height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton width="100%" height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton width="100%" height={20} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton width="100%" height={20} />
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer> */}

            {/* {!apiLoading && !tableData.length && renderEmptyContent()} */}
            {showConfirmModal && renderConfirmModal()}
        </div>
    );
};

DocsTable.propTypes = {
    apiLoading: PropTypes.bool,
    showDocModelHeader: PropTypes.bool,
    documents: PropTypes.array.isRequired,
    updateData: PropTypes.any,
};

export default DocsTable;
