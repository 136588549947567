import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

import {
    useGetDocumentModelsMutation,
    useGetDocumentsTypeMutation,
    useUpdateDocumentTypesMutation,
} from './apiService';

import LayoutContent from '../../components/LayoutContent';
import AddNewDocModal from '../../components/AddNewDocModal';
import DataTable from './DataTable';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';

export default function DocumentModels() {
    const [getDocumentModels, { isLoading: modelLoader }] = useGetDocumentModelsMutation();
    const [getDocumentsType, { isLoading }] = useGetDocumentsTypeMutation();
    const [updateDocumentTypes, { isLoading: updateDocModelLoader }] = useUpdateDocumentTypesMutation();
    const { docModels, currentModel, modelsMeta, modelsMetaFetched } = useSelector(state => state.docModelsData);
    const [showAddNewModel, toggleAddNewModel] = React.useState(false);
    const [errorList, updateError] = React.useState(null);
    const [showLoader, toggleLoader] = React.useState(true);
    const addNewDisabled = React.useMemo(() => !Object.keys(modelsMeta).length, [modelsMeta]);

    const closeModal = () => {
        updateError(null);
        toggleAddNewModel(false);
    };

    const updateUserDocModels = DocumentModelInfo => {
        updateError(null);
        updateDocumentTypes({ DocumentModelInfo }).then(({ error }) => {
            if (error) {
                const { data } = error || {};
                const { message = '' } = data || {};
                updateError([message]);
            } else {
                closeModal();
                getDocumentsType();
            }
        });
    };

    React.useEffect(() => {
        if (!modelsMetaFetched) {
            getDocumentModels();
        }
        if (!docModels.length) {
            getDocumentsType();
        }
        toggleLoader(false);
    }, []);

    return (
        <LayoutContent
            showLoader={isLoading || modelLoader}
            title={SITE_TEXT.docModels}
            headerItemsRight={
                docModels.length ? (
                    <Button onClick={() => toggleAddNewModel(true)} disabled={addNewDisabled}>
                        <AddCircleOutlineIcon />
                        {SITE_TEXT.NewModel}
                    </Button>
                ) : null
            }>
            <div className="manageDocuments">
                <DataTable
                    apiLoading={modelLoader || isLoading || showLoader}
                    currentModel={currentModel}
                    toggleAddNewModel={toggleAddNewModel}
                    docModels={!(modelLoader || isLoading) ? docModels : []}
                    addNewDisabled={addNewDisabled}
                />
                {showAddNewModel && (
                    <AddNewDocModal
                        modelsMeta={modelsMeta}
                        showLoader={updateDocModelLoader}
                        onClose={closeModal}
                        leftButtonHandler={closeModal}
                        rightButtonHandler={updateUserDocModels}
                        errorList={errorList}
                    />
                )}
            </div>
        </LayoutContent>
    );
}
