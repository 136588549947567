import React, { useEffect } from 'react';
import LayoutContent from '../../components/LayoutContent';
import c from 'classnames';
import { ACTIVITY_LOGS_TABS, SITE_TEXT } from '../../utils/constants';
import './index.scss';
import { useSelector } from 'react-redux';
import { useGetActivityLogsMutation } from '../Settings/apiService';
import CustomTable from '../../components/common/Table';
import moment from 'moment';

const ActivityLogs = () => {
    const [getActivityLogs, { isLoading }] = useGetActivityLogsMutation();
    const { activityLogs, teamList } = useSelector(state => state.userData);

    const [currentTab, setCurrentTab] = React.useState(0);
    const [rows, setRows] = React.useState([]);

    const [logTypeFilterItems, setLogTypeFilterItems] = React.useState([
        { label: 'Credit deduction', value: 'Credit deduction' },
        { label: 'Credit added', value: 'Credit added' },
        { label: 'Create document type', value: 'Create document type' },
        { label: 'Delete document type', value: 'Delete document type' },
        { label: 'Document upload', value: 'Document upload' },
        { label: 'Document delete', value: 'Document delete' },
        { label: 'Document download', value: 'Document download' },
        { label: 'Document approve', value: 'Document approve' },
        { label: 'Document reject', value: 'Document reject' },
        { label: 'Document flagged', value: 'Document flagged' },
        { label: 'Create user', value: 'Create user' },
        { label: 'Delete user', value: 'Delete user' },
        { label: 'User role change', value: 'User role change' },
    ]);

    const [filters, setFilters] = React.useState({
        type: [],
        user: [],
        date: [],
    });

    useEffect(() => {
        getActivityLogs();
    }, []);

    useEffect(() => {
        setRows(
            activityLogs?.map((_, i) => {
                return {
                    ..._,
                    id: i,
                };
            })
        );
    }, [activityLogs]);

    const columns = [
        {
            field: 'Description',
            flex: 1,
            headerName: 'Description',
            renderCell: params => (
                <div
                    style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#1D2939',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {params.row.Description}
                </div>
            ),
        },
        {
            headerName: 'Type',
            field: 'Type',
            flex: 0.4,
            renderCell: params => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#1D2939',
                    }}>
                    {params.row.Type}
                </div>
            ),
            filterOptions: {
                type: 'multiselect',
                searchable: true,
                items: logTypeFilterItems,
                filterChange: e => {
                    setFilters({ ...filters, type: e });
                },
            },
        },
        {
            headerName: 'User',
            field: 'User',
            flex: 0.4,
            renderCell: params => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#667085',
                    }}>
                    {params.row.User}
                </div>
            ),
            filterOptions: {
                type: 'multiselect',
                searchable: false,
                items: teamList?.map(_ => ({
                    label: _.Name,
                    value: _.Email,
                })),
                filterChange: e => {
                    setFilters({ ...filters, user: e });
                },
            },
        },
        {
            headerName: 'Date',
            field: 'Date',
            flex: 0.4,
            renderCell: params => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#667085',
                    }}>
                    {params.row.Date}
                </div>
            ),
            filterOptions: {
                type: 'date-range',
                filterChange: e => {
                    setFilters({ ...filters, date: e });
                },
            },
        },
    ];

    const { allActivityLen: activityInTabs, filterActivity: currentTabActivity } = React.useMemo(() => {
        const allActivityLen = [
            rows.length,
            rows.filter(row => {
                return row.Type?.toLowerCase().includes(ACTIVITY_LOGS_TABS[1]?.toLowerCase());
            }).length,
            rows.filter(row => {
                return row.Type?.toLowerCase().includes(ACTIVITY_LOGS_TABS[2]?.toLowerCase());
            }).length,
            rows.filter(row => {
                return row.Type?.toLowerCase().includes(ACTIVITY_LOGS_TABS[3]?.toLowerCase());
            }).length,
        ];

        let filteredRow = rows.filter(row => {
            const isTypeMatch = filters.type.length === 0 || filters.type.includes(row.Type.toLowerCase());
            const isUserMatch = filters.user.length === 0 || filters.user.includes(row.User.toLowerCase());
            let isDateMatch = true;
            if (filters.date.length > 0 && filters?.date[0] && filters?.date[1]) {
                const start = moment(filters.date[0], 'YYYY-MM-DD');
                const end = moment(filters.date[1], 'YYYY-MM-DD').add({ hours: 23, minutes: 59 });

                const date = moment(row.Date);

                isDateMatch = date.isBetween(start, end, undefined, '[]'); // '[]' includes start and end dates
            }
            return isTypeMatch && isUserMatch && isDateMatch;
        });

        /* eslint-disable */
        const filterActivity = currentTab
            ? filteredRow.filter(row => {
                  return row.Type?.toLowerCase().includes(ACTIVITY_LOGS_TABS[currentTab]?.toLowerCase());
              })
            : filteredRow;

        /* eslint-enable */
        return {
            allActivityLen,
            filterActivity,
        };
    }, [rows, currentTab, filters]);

    useEffect(() => {
        const uniqueTypes = new Set(currentTabActivity.map(item => item.Type));
        // Convert the Set back to an array before returning
        let uniqueArr = Array.from(uniqueTypes);
        setLogTypeFilterItems(old => {
            uniqueArr.forEach(item => {
                if (!old.find(_ => _.value === item)) {
                    old.push({ label: item, value: item });
                }
            });
            return [...old];
        });
    }, [currentTabActivity]);

    return (
        <LayoutContent title={SITE_TEXT.ActivityLogs}>
            <div className="settingsWrap heightss">
                <div className="activityLogWrapper">
                    <ul id="docNavigation" className="docNavigation clearfix">
                        {ACTIVITY_LOGS_TABS.map((headerVal, headerIndx) => {
                            const currentDocsLen = activityInTabs[headerIndx];
                            return (
                                <li
                                    onClick={() => setCurrentTab(headerIndx)}
                                    className={c({
                                        highLightRow: headerIndx === currentTab,
                                    })}
                                    key={headerIndx}>
                                    {headerVal}
                                    {currentDocsLen ? <span className="docInTabs">{currentDocsLen}</span> : null}
                                </li>
                            );
                        })}
                    </ul>
                    <div
                        id="activitylogTable"
                        style={{ minHeight: '500px', height: 'calc(100% - 68px)', width: '100%', borderRadius: '8px' }}>
                        <CustomTable
                            data={currentTabActivity}
                            columns={columns}
                            isLoading={isLoading}
                            filters={filters}
                        />
                    </div>
                </div>
            </div>
        </LayoutContent>
    );
};

export default ActivityLogs;
