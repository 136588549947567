import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import c from 'classnames';

const MenuItemWrap = styled(({ displayProp, ...rest }) => displayProp && <MenuItem {...rest} />)`
    display: ${({ displayProp }) => `${displayProp}`};
`;

export const CustomSelect = ({
    id = 'selectId',
    answer = 0,
    question_text = '',
    options = [],
    handleChange = () => {},
    select_option_text = 'Select an option',
    showLabel = true,
    fullWidth = false,
    disabled = false,
}) => (
    <div
        className={c('selectWrap', {
            emptySelect: answer === 0,
        })}>
        {showLabel && <InputLabel id={id}>{question_text}</InputLabel>}
        <Select
            displayEmpty
            disabled={disabled}
            fullWidth={fullWidth}
            id={`${id}-select`}
            labelId={id}
            value={answer}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                classes: {
                    paper: 'userOptionsPaper',
                },
            }}
            onChange={({ target }) => handleChange(target.value)}>
            {[{ key: select_option_text }, ...options].map((optionValue, optionValueInd) => {
                const { key, value } = optionValue;
                return (
                    <MenuItemWrap
                        key={`${id}-${optionValueInd}`}
                        classes={{
                            root: 'userOptions',
                        }}
                        value={value}
                        displayProp={!optionValueInd ? 'none' : 'flex'}>
                        <span className="key">{key}</span>
                    </MenuItemWrap>
                );
            })}
        </Select>
    </div>
);

CustomSelect.propTypes = {
    id: PropTypes.string,
    answer: PropTypes.string,
    question_text: PropTypes.string,
    select_option_text: PropTypes.string,
    showLabel: PropTypes.bool,
    fullWidth: PropTypes.bool,
    showCircle: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    handleChange: PropTypes.func,
};
