import PropTypes from 'prop-types';
import React from 'react';
import './../index.scss';
import { SITE_TEXT } from '../../../utils/constants';
import loginHead from '../../../assets/logo.svg';
import { Button } from '@mui/material';
import OTPInput from 'react-otp-input';
import Loader from '../../../components/Loader';
import { useResendCodeMutation } from '../apiService';

const VerifyAccount = ({ body, otp, setOtp, handleVerify, otpError }) => {
    const [resendCode, { isLoading }] = useResendCodeMutation();

    const resend = () => {
        resendCode({
            body: {
                email: body.Email,
            },
        });
    };

    return (
        <main className="wrapper">
            <div className="loginWrapper">
                <div className="loginHead">
                    <img src={loginHead} alt="" />
                </div>
                {isLoading && <Loader position="fixed" />}
                <div className="verifyContainer">
                    <div>
                        <div className="header">{SITE_TEXT.VerifyAccount}</div>
                        <div className="subHeader">
                            We’ve emailed you a six digit code to confirm your account at {body.Email}. Please enter the
                            code here to verify the email.
                        </div>
                    </div>
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        containerStyle={{
                            display: 'flex',
                            gap: '24px',
                            justifyContent: 'center',
                        }}
                        renderInput={props => (
                            <input
                                {...props}
                                className="otpInput"
                                // eslint-disable-next-line react/prop-types
                                style={{ borderColor: props.value ? '#36C1B6' : '#d0d5dd' }}
                            />
                        )}
                    />
                    <div>
                        <Button type="submit" fullWidth onClick={handleVerify}>
                            {SITE_TEXT.VerifyText}
                        </Button>
                        <div className="resend">
                            Didn&apos;t recieve the code? <span onClick={resend}>Resend</span>
                        </div>
                        {otpError && <h2>{otpError}</h2>}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default VerifyAccount;

VerifyAccount.propTypes = {
    body: PropTypes.any.isRequired,
    otp: PropTypes.any.isRequired,
    setOtp: PropTypes.any.isRequired,
    handleVerify: PropTypes.any.isRequired,
    otpError: PropTypes.any.isRequired,
};
