import React from 'react';
import PropTypes from 'prop-types';

import loginHead from '../../../assets/logo.svg';
import checkIcon from '../../../assets/checkIcon.svg';

const SignupStepper = ({ step }) => {
    const steps = [
        {
            step: 1,
            text: 'Create the account',
        },
        {
            step: 2,
            text: 'Enter your company details',
        },
        {
            step: 3,
            text: 'Tell us about your use case',
        },
    ];

    return (
        <div className="signupStepperWrapper">
            <div className="signupStepperContainer">
                <img src={loginHead} alt="" className="logo" />
                <div className="stepperContainer">
                    {steps.map((_, i) => {
                        return (
                            <div key={i} className="stepperItem">
                                <div
                                    className={`stepperCount ${
                                        _.step == step ? 'activeStep' : _.step < step ? 'prevStep' : 'nextStep'
                                    }`}>
                                    {_.step < step ? <img src={checkIcon} alt="" /> : _.step}
                                </div>
                                <div
                                    className={`stepperText ${
                                        _.step == step ? 'activeStep' : _.step < step ? 'prevStep' : 'nextStep'
                                    }`}>
                                    {_.text}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SignupStepper;

SignupStepper.propTypes = {
    step: PropTypes.number.isRequired,
};
