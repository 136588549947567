import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';

import { useOtpStatusMutation, useSignUpStatusMutation } from '../apiService';
import { resetError } from '../reducer';
import loginHead from '../../../assets/logo.svg';
import './../index.scss';
import SignupStep1 from './SignupStep1';
import SignUpStep2 from './SignUpStep2';
import SignUpStep3 from './SignUpStep3';
import VerifyAccount from './VerifyAccount';

export default function SignUpContent({
    updateSignUp,
    signupStep,
    setSignupStep,
    otpAuthenticated,
    setOtpAuthenticated,
    email,
}) {
    const dispatch = useDispatch();
    const { signUpError, signUpSuccess } = useSelector(state => state.loginData);
    const [signUpStatus, { isLoading }] = useSignUpStatusMutation();
    const [otpStatus, { isLoading: otpLoading }] = useOtpStatusMutation();

    const [body, updateParams] = React.useState({
        FirstName: '',
        LastName: '',
        Email: email ? email : '',
        Company: '',
        Password: '',
        RePassword: '',
        Designation: '',
        Phone: '',
        Country: '',
        DocumentType: '',
        Custom: '',
        NoOfDocPerMonth: null,
        AdditionalInfo: '',
        GoogleId: '',
        SignUpMethod: '',
    });

    const [errors, updateErrors] = React.useState({
        FirstName: false,
        LastName: false,
        Email: false,
        Company: false,
        Password: false,
        RePassword: false,
        passNoMatch: false,
        Designation: false,
        Phone: false,
        Country: false,
        DocumentType: false,
        Custom: false,
        NoOfDocPerMonth: false,
        AdditionalInfo: false,
        invalidPass: false,
    });

    const [otp, setOtp] = React.useState();
    const [otpError, setOtpError] = React.useState('');
    const [signupErrorNew, setSignupError] = React.useState('');

    React.useEffect(() => {
        dispatch(resetError());
    }, []);

    const handleData = ({ name, value }) => {
        const handlePassError = name === 'Password' || name === 'RePassword';
        const additionalError = handlePassError ? { passNoMatch: false, invalidPass: false } : {};
        updateParams(old => ({
            ...old,
            [name]: value,
        }));
        updateErrors(old => ({
            ...old,
            ...additionalError,
            [name]: false,
        }));
    };

    const callSignUpApi = async () => {
        let tempBody = {
            FirstName: body.FirstName,
            LastName: body.LastName,
            Email: body.Email,
            Number: body.Phone,
            Password: body.Password,
            Company: body.Company,
            JobRole: body.Designation,
            Region: body.Country,
            DocumentNumber: body.NoOfDocPerMonth,
            AdditionalInfo: body.AdditionalInfo,
            DocumentType: body.DocumentType?.map(_ => _.value),
            GoogleId: body.GoogleId,
            SignUpMethod: body.SignUpMethod,
        };

        let resp = await signUpStatus({ body: tempBody });
        if (resp?.data?.Output === 'Successfully Signed up!!') {
            setSignupStep(4);
        }
        if (resp?.data?.detail === 'User with this email already exists.') {
            setSignupError(resp?.data?.detail);
        }
    };

    const submitOtp = async () => {
        let resp = await otpStatus({
            body: {
                Email: body.Email,
                confirmation_code: otp,
            },
        });
        if (resp.error) {
            setOtpError(resp.error.data.detail);
        }
        if (resp?.data?.Output === 'User Authenticated Successfully!!') {
            setOtpAuthenticated(true);
        }
    };

    const goToSignUp = () => {
        updateSignUp(false);
    };

    if (otpAuthenticated) {
        return (
            <main className="wrapper">
                <div className="loginWrapper">
                    <div className="loginHead">
                        <img src={loginHead} alt="" />
                    </div>
                    <section className="success">
                        <div className="checkOuter">
                            <div>
                                <CheckIcon />
                            </div>
                        </div>
                        <h2 className="signUpSuccess">{signUpSuccess}</h2>
                        <div className="infoWrap">
                            Go back to <span onClick={goToSignUp}>Login here</span>
                        </div>
                    </section>
                </div>
            </main>
        );
    }

    return signupStep == 1 ? (
        <SignupStep1
            updateSignUp={updateSignUp}
            signUpError={signUpError}
            body={body}
            // updateParams={updateParams}
            handleData={handleData}
            setSignupStep={setSignupStep}
            signUpStatus={signUpStatus}
            isLoading={isLoading}
            errors={errors}
            updateErrors={updateErrors}
        />
    ) : signupStep == 2 ? (
        <SignUpStep2
            updateSignUp={updateSignUp}
            signUpError={signUpError}
            body={body}
            handleData={handleData}
            signupStep={signupStep}
            setSignupStep={setSignupStep}
            signUpStatus={signUpStatus}
            isLoading={isLoading}
            errors={errors}
            updateErrors={updateErrors}
        />
    ) : signupStep == 3 ? (
        <SignUpStep3
            updateSignUp={updateSignUp}
            signUpError={signUpError}
            body={body}
            handleData={handleData}
            signupStep={signupStep}
            signupErrorNew={signupErrorNew}
            signUpStatus={signUpStatus}
            isLoading={isLoading}
            errors={errors}
            updateErrors={updateErrors}
            callSignUpApi={callSignUpApi}
        />
    ) : (
        <VerifyAccount
            body={body}
            otp={otp}
            setOtp={setOtp}
            handleVerify={submitOtp}
            isLoading={otpLoading}
            otpError={otpError}
        />
    );
}

SignUpContent.propTypes = {
    updateSignUp: PropTypes.func.isRequired,
    signupStep: PropTypes.func.isRequired,
    setSignupStep: PropTypes.func.isRequired,
    otpAuthenticated: PropTypes.func.isRequired,
    setOtpAuthenticated: PropTypes.func.isRequired,
    email: PropTypes.string,
};
