import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import c from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import {
    useExtractDocumentMutation,
    useExtractDocumentInfoMutation,
    useDeleteDocumentForModelMutation,
    useUpdateCustomizedFieldsMutation,
    useUpdateStatusForDocMutation,
} from '../DocumentModels/apiService';

import PdfViewer from './PdfViewer';
import DataTable from './DataTable';
import Loader from '../../components/Loader';
import ConfirmModal from '../../components/ConfirmModal';
import CustomizeData from './CustomizeData';
import {
    HighlighContent,
    HighlighTable,
    DefaultBoxes,
    SelectMenu,
    StyledTooltip,
} from '../../components/common/StyledComponents';
import ReSizableHighlighContent from './ReSizableHighlighContent';

import trashIcon from '../../assets/trash.svg';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { ReactComponent as GridIcon } from '../../assets/grid.svg';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-move.svg';
import { ReactComponent as InfoIcon } from '../../assets/infoIcon.svg';

import { SITE_TEXT, DOC_STATUS } from '../../utils/constants';
import { checkConfidence, addZeroToNUmber, donwloadCSV, downloadJson, getAllPageNumber } from '../../utils/functions';
import './index.scss';

function ReviewDocument() {
    const navigate = useNavigate();
    const { DocumentID } = useParams();

    const [extractedInfo, updateDocInfo] = React.useState({});
    const [docStatus, setDocStatus] = React.useState('');

    const [scaleFactor, setScaleFactor] = React.useState(1);
    const [numPages, setNumPages] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [expandedList, updateExpandedList] = React.useState([0]);
    const [selectedTable, updateTable] = React.useState(null);
    const [selectedKeyValue, updateKeyValue] = React.useState(null);
    const [settingsEl, setSettingsElement] = React.useState(null);
    const [buttonsEl, setButtonsEl] = React.useState(null);
    const [showConfirmModal, toggleConfirmModal] = React.useState(false);
    const [showDeleteModal, toggleDeleteModal] = React.useState(false);
    const [showCustomizedFields, toggleCustomizedFields] = React.useState(false);

    const handleScaleUp = () => setScaleFactor(prevScale => prevScale + 0.1);

    const handleScaleDown = () => setScaleFactor(prevScale => prevScale - 0.1);

    const [extractDocument] = useExtractDocumentMutation();
    const [extractDocumentInfo, { data }] = useExtractDocumentInfoMutation();
    const [deleteDocumentForModel, { isLoading }] = useDeleteDocumentForModelMutation();
    const [updateStatusForDoc, { isLoading: updateStatusLoader }] = useUpdateStatusForDocMutation();
    const [updateCustomizedFields, { isLoading: customizedLoader }] = useUpdateCustomizedFieldsMutation();
    const { Output = {} } = data || {};
    const { BB = {}, Text = {} } = Output;
    const { DocumentPath, Data = {}, ContentType, DocumentName = '' } = extractedInfo;
    const dataInUse = Data[currentPage] || {};
    const { Table = {}, KEY_VALUE_PAIR = [], PageClassification } = dataInUse;
    const { Value } = PageClassification || {};
    const { tableId, Table_Info, Cell_Info } = selectedTable || {};
    const { valId, valueInfo, hideDefaultBox = false, mainHead, keyForRender } = selectedKeyValue || {};
    const { text: inputText } = valueInfo || {};

    const setValuesForDoc = () => {
        extractDocument({ DocumentID }).then(({ data }) => {
            const { Output = {} } = data || {};
            updateDocInfo(Output);
            setDocStatus(Output?.Status);
        });
        extractDocumentInfo({ DocumentID });
    };

    React.useEffect(() => {
        setValuesForDoc();
    }, []);

    const docHasTable = React.useMemo(() => Object.keys(Table).some(tableInner => !Table[tableInner].Hidden), [Table]);

    const { cBB: allBoundingBox = {}, cText: allText = {} } = React.useMemo(() => {
        return {
            cBB: BB[currentPage],
            cText: Text[currentPage],
        };
    }, [currentPage, Output]);

    const docNameInUse = React.useMemo(() => DocumentName.split('.')[0], [DocumentName]);

    const fieldsForValidation = React.useMemo(
        () => KEY_VALUE_PAIR.filter(({ key }) => key.Confidence < 70),
        [KEY_VALUE_PAIR]
    );

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    const highLightTable = tableInfo => {
        updateKeyValue(null);
        updateTable(tableInfo);
    };

    const highLightKeyValCell = (newKeyInfo, hideTable = true) => {
        const { valId: newValId } = newKeyInfo || {};
        if (valId !== newValId) {
            updateKeyValue(newKeyInfo);
            if (hideTable) {
                updateTable(null);
            }
        }
    };

    const highlightFieldToVerify = indexToHighlight => {
        const { value: valueInfo } = fieldsForValidation[indexToHighlight - 1];
        highLightKeyValCell({ valueInfo, valId: `invalid=${indexToHighlight}`, hideDefaultBox: true });
    };

    const deHighLightAll = () => {
        updateTable(null);
        setSettingsElement(null);
        highLightKeyValCell(null);
    };

    const goToBack = () => {
        navigate(-1);
    };

    const handleCustomizedFields = () => {
        toggleCustomizedFields(true);
        deHighLightAll();
        document.body.classList.toggle('noScroll');
    };

    const handleSaveFields = () => {
        toggleCustomizedFields(false);
    };

    const closeModal = () => {
        toggleDeleteModal(false);
        toggleConfirmModal(false);
        toggleCustomizedFields(false);
        setSettingsElement(null);
    };

    const downloadCsvData = () => {
        donwloadCSV({ data: Data, fileName: docNameInUse });
        closeModal();
    };

    const downloadJsonData = () => {
        downloadJson(extractedInfo, docNameInUse);
        closeModal();
    };

    const deleteSelectedDoc = () => {
        deleteDocumentForModel({ currentId: DocumentID }).then(() => {
            goToBack();
        });
    };

    const downloadOriginalFile = async () => {
        try {
            const response = await fetch(DocumentPath);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = DocumentName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            throw new Error(error);
        }

        // window.open(DocumentPath, '_blank');
        closeModal();
    };

    const updateStatus = status => {
        setButtonsEl(null);
        saveChanges(Data).then(() => {
            updateStatusForDoc({ DocumentID, status }).then(() => {
                goToBack();
            });
        });
    };

    const nextPage = () => {
        if (currentPage < numPages) {
            deHighLightAll();
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            deHighLightAll();
            setCurrentPage(currentPage - 1);
        }
    };

    const saveChanges = data => {
        const docData = { data };
        setButtonsEl(null);
        return updateCustomizedFields({ docData, document_id: DocumentID }).then(() => {
            handleSaveFields();
        });
    };

    const updateTextForKeyValue = text => {
        const updatedValueInfo = {
            ...valueInfo,
            text,
        };
        const newInfo = cloneDeep(extractedInfo);
        if (!selectedTable) {
            newInfo.Data[currentPage].KEY_VALUE_PAIR[valId].value = updatedValueInfo;
        } else {
            const { first, last } = getAllPageNumber(valId);
            const oldValue = newInfo.Data[currentPage].Table[tableId].Cell_Info[first][last];
            newInfo.Data[currentPage].Table[tableId].Cell_Info[first][last] = {
                ...oldValue,
                ...updatedValueInfo,
                Text: text,
            };
            const newSelTable = cloneDeep(selectedTable);
            const oldCell = selectedTable.Cell_Info[first][last];
            newSelTable.Cell_Info[first][last] = {
                ...oldCell,
                Text: text,
            };
            updateTable(newSelTable);
        }
        updateKeyValue({
            ...selectedKeyValue,
            valueInfo: updatedValueInfo,
        });
        updateDocInfo(newInfo);
    };

    const updateOldContent = (keyToUse, BoundingBox, textFromApi = '') => {
        let text = textFromApi;
        if (keyToUse !== null) {
            const { Text = '' } = allText[keyToUse] || {};
            text = Text;
        }
        const newInfo = cloneDeep(extractedInfo);
        const updatedValueInfo = {
            ...valueInfo,
            BoundingBox,
            Confidence: 100,
            text,
        };
        if (!selectedTable) {
            newInfo.Data[currentPage].KEY_VALUE_PAIR[valId].value = updatedValueInfo;
            newInfo.Data[currentPage].KEY_VALUE_PAIR[valId].key.Confidence = 100;
        } else {
            const { first, last } = getAllPageNumber(valId);
            const oldValue = newInfo.Data[currentPage].Table[tableId].Cell_Info[first][last];
            newInfo.Data[currentPage].Table[tableId].Cell_Info[first][last] = {
                ...oldValue,
                ...updatedValueInfo,
                Text: text,
            };
            const newSelTable = cloneDeep(selectedTable);
            const oldCell = selectedTable.Cell_Info[first][last];
            newSelTable.Cell_Info[first][last] = {
                ...oldCell,
                ...updatedValueInfo,
                Text: text,
            };
            updateTable(newSelTable);
            highLightKeyValCell(
                {
                    valueInfo: {
                        BoundingBox,
                        Confidence: 100,
                    },
                    valId,
                },
                false
            );
        }
        const keyToRender = keyToUse && keyForRender !== `${keyToUse}-${valId}` ? `${keyToUse}-${valId}` : keyForRender;
        updateKeyValue({
            ...selectedKeyValue,
            valueInfo: updatedValueInfo,
            keyForRender: keyToRender,
        });
        updateDocInfo(newInfo);
    };

    const handleCustomizeChanges = data => {
        const newInfo = cloneDeep(extractedInfo);
        newInfo.Data = data;
        updateDocInfo(newInfo);
        saveChanges(data);
        document.body.classList.toggle('noScroll');
    };

    const isImage = ContentType !== 'pdf';

    return (
        <main className={c('reviewDocument', { selectedTable })}>
            {(customizedLoader || updateStatusLoader) && <Loader />}
            {DocumentPath && data ? (
                <React.Fragment>
                    <div className="leftWrapperWithHeader">
                        <header>
                            <button className="backButton" onClick={goToBack}>
                                <ArrowBackIcon />
                            </button>
                            {fieldsForValidation.length ? (
                                <SelectMenu
                                    options={fieldsForValidation}
                                    handleChange={highlightFieldToVerify}
                                    showLabel={false}
                                    select_option_text={`${fieldsForValidation.length} ${SITE_TEXT.FieldsVerification}`}
                                />
                            ) : null}
                        </header>
                        <div
                            className="leftContentWrapper clearfix"
                            style={{ paddingBottom: Cell_Info ? '40vh' : '0' }}>
                            <section className="pageInfo">
                                <div className="upperInfo">
                                    {SITE_TEXT.Page}
                                    <br />
                                    <span>{currentPage}</span>
                                    <br />/ {numPages}
                                </div>
                                <div className="midInfo">
                                    <span onClick={handleScaleUp} className={c({ disabled: scaleFactor >= 1.5 })}>
                                        <ZoomInIcon />
                                    </span>
                                    <span className="scaleFactor">{Math.round(scaleFactor * 100)}%</span>
                                    <span onClick={handleScaleDown} className={c({ disabled: scaleFactor <= 0.6 })}>
                                        <ZoomOutIcon />
                                    </span>
                                </div>
                            </section>
                            <div
                                className={c('fileContent', { isImage })}
                                style={{ transform: `translateX(-50%) scale(${scaleFactor})` }}>
                                {isImage ? (
                                    <img src={DocumentPath} alt="Image" />
                                ) : (
                                    <PdfViewer
                                        pdfUrl={DocumentPath}
                                        currentPage={currentPage}
                                        numPages={numPages}
                                        setNumPages={setNumPages}
                                    />
                                )}
                                {Table_Info && (
                                    <HighlighTable
                                        key={tableId}
                                        confidence={Table_Info.Confidence}
                                        box={Table_Info.BoundingBox}
                                    />
                                )}

                                {!hideDefaultBox && valId ? (
                                    <React.Fragment>
                                        {Object.keys(allBoundingBox).map(dBoxKey => {
                                            const { BoundingBox } = allBoundingBox[dBoxKey];
                                            return (
                                                <DefaultBoxes
                                                    key={`dBoxKey-${dBoxKey}`}
                                                    boundingbox={BoundingBox}
                                                    onClick={() => updateOldContent(dBoxKey, BoundingBox)}
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                ) : null}

                                {valueInfo && !hideDefaultBox && (
                                    <ReSizableHighlighContent
                                        key={keyForRender || valId}
                                        valId={valId}
                                        confidence={valueInfo.Confidence}
                                        box={valueInfo.BoundingBox}
                                        updateOldContent={updateOldContent}
                                        mainHead={mainHead}
                                        inputText={inputText}
                                        updateTextForKeyValue={updateTextForKeyValue}
                                        allBoundingBox={allBoundingBox}
                                        allText={allText}
                                        disabled={docStatus == 'Approved'}
                                    />
                                )}
                                {valueInfo && hideDefaultBox && (
                                    <HighlighContent
                                        key={valId}
                                        confidence={valueInfo.Confidence}
                                        box={valueInfo.BoundingBox}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <section
                        id="rightContent"
                        className="rightContent clearfix"
                        style={{ height: Cell_Info ? '60vh' : '100vh' }}>
                        <div>
                            <div className="headContent">
                                <div className="leftInfo ellipsisPro">
                                    {!isImage && (
                                        <div className="arrowContent">
                                            <span onClick={prevPage} className={c({ disabled: currentPage === 1 })}>
                                                <Arrow />
                                            </span>
                                            {currentPage} / {numPages}
                                            <span
                                                onClick={nextPage}
                                                className={c({ disabled: currentPage === numPages })}>
                                                <Arrow />
                                            </span>
                                        </div>
                                    )}
                                    {Value && (
                                        <StyledTooltip backgroundColor="#000" color="#fff" title={Value}>
                                            <InfoIcon className="infoIcon" />
                                        </StyledTooltip>
                                    )}
                                    {docNameInUse}
                                </div>
                                <div
                                    className={c('verticalIcon', { settingsEl })}
                                    onClick={({ currentTarget }) => setSettingsElement(currentTarget)}>
                                    <SettingsIcon />
                                </div>
                                <Popover
                                    id="settingsEl"
                                    open={Boolean(settingsEl)}
                                    anchorEl={settingsEl}
                                    onClose={() => setSettingsElement(null)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    className="settingsPopOver reviewPopOver">
                                    <div onClick={handleCustomizedFields}>
                                        <EditIcon />
                                        {SITE_TEXT.CustomizedFields}
                                    </div>
                                    <div onClick={() => toggleConfirmModal(true)}>
                                        <DownloadIcon />
                                        {SITE_TEXT.Download}
                                    </div>
                                    <div onClick={() => toggleDeleteModal(true)}>
                                        <img src={trashIcon} alt="" />
                                        {SITE_TEXT.Delete}
                                    </div>
                                </Popover>
                            </div>
                            <div className="fieldAndTableContainer">
                                {KEY_VALUE_PAIR.some(({ key = {} }) => !key.Hidden) ? (
                                    <Accordion
                                        classes={{ root: c('accordionRoot', { docHasTable }) }}
                                        expanded={expandedList.includes(0)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            onClick={() => updateExpanded(0)}
                                            classes={{ content: 'accordionHead' }}>
                                            {SITE_TEXT.Fields}
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: 'clearfix cellWrap' }}>
                                            {KEY_VALUE_PAIR.map((keyInfo, uuid) => {
                                                const { key, value } = keyInfo;
                                                const { text, Hidden } = key || {};
                                                const { text: vText, Confidence: ValueConfidence } = value || {};
                                                const background = checkConfidence(ValueConfidence);
                                                return (
                                                    <div
                                                        key={uuid}
                                                        className={c('valueTab', {
                                                            activeTable: parseInt(valId, 10) === uuid,
                                                            Hidden,
                                                        })}
                                                        onClick={() =>
                                                            highLightKeyValCell({
                                                                valueInfo: value,
                                                                valId: `${uuid}`,
                                                                mainHead: text,
                                                            })
                                                        }>
                                                        <div className="key">
                                                            <span style={{ background }} className="circle" />
                                                            {text}
                                                        </div>
                                                        <div className="value">{vText}</div>
                                                    </div>
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null}

                                {docHasTable ? (
                                    <Accordion
                                        classes={{ root: c('accordionRoot', { docHasTable }) }}
                                        expanded={expandedList.includes(1)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            onClick={() => updateExpanded(1)}
                                            classes={{ content: 'accordionHead' }}>
                                            {SITE_TEXT.Tables}
                                        </AccordionSummary>
                                        <AccordionDetails classes={{ root: 'clearfix tableWrap' }}>
                                            {Object.keys(Table).map((tableInner, uuid) => {
                                                const { Cell_Info = [], Table_Info } = Table[tableInner] || {};
                                                const { Confidence, Hidden } = Table_Info || {};
                                                if (Hidden) return null;
                                                const background = checkConfidence(Confidence);
                                                const firstRowLen = Cell_Info[0].length;
                                                return (
                                                    <div
                                                        key={uuid}
                                                        className={c('valueTab', {
                                                            activeTable: tableId === tableInner,
                                                        })}
                                                        onClick={() => {
                                                            if (Cell_Info.length * firstRowLen)
                                                                highLightTable({
                                                                    Table_Info,
                                                                    Cell_Info,
                                                                    tableId: tableInner,
                                                                });
                                                        }}>
                                                        <div className="key">
                                                            <span style={{ background }} className="circle" />
                                                            <span className="tHead">
                                                                Table {addZeroToNUmber(uuid + 1)}
                                                            </span>
                                                            <span className="rectangle">Page 01</span>
                                                        </div>
                                                        <div className="value">
                                                            <span>{Cell_Info.length * firstRowLen}</span>
                                                            <GridIcon />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                ) : null}
                            </div>
                        </div>

                        <section className="buttonContent">
                            {docStatus === 'Approved' ? (
                                <Button onClick={() => updateStatus(DOC_STATUS[1])}>
                                    <RemoveRedEyeOutlinedIcon />
                                    {SITE_TEXT.ReviewAgain}
                                </Button>
                            ) : (
                                <>
                                    <Button onClick={() => updateStatus(DOC_STATUS[3])}>
                                        <CheckIcon />
                                        {SITE_TEXT.ApproveFile}
                                    </Button>
                                    <Button onClick={() => saveChanges(Data)}>{SITE_TEXT.SaveChanges}</Button>
                                    <div className="verticalIcon" onClick={e => setButtonsEl(e.currentTarget)}>
                                        <MoreVertIcon />
                                    </div>
                                </>
                            )}
                            <Popover
                                id="buttonsEl"
                                open={Boolean(buttonsEl)}
                                anchorEl={buttonsEl}
                                onClose={() => setButtonsEl(null)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                className="settingsPopOver buttonsPopOver">
                                <div onClick={() => updateStatus(DOC_STATUS[2])}>{SITE_TEXT.FlagFile}</div>
                                <div onClick={() => updateStatus(DOC_STATUS[6])}>{SITE_TEXT.ApproveFileWithError}</div>
                                <div onClick={() => updateStatus(DOC_STATUS[4])}>{SITE_TEXT.RejectFile}</div>
                            </Popover>
                        </section>
                    </section>
                    {Cell_Info && (
                        <React.Fragment>
                            <DataTable
                                key={tableId}
                                highLightKeyValCell={highLightKeyValCell}
                                closeTable={() => updateTable(null)}
                                tableData={Cell_Info}
                            />
                            <div id="bottomDummy" className="bottomDummy" />
                        </React.Fragment>
                    )}
                    {showConfirmModal && (
                        <ConfirmModal
                            modalBaseClass="downloadModal"
                            onClose={closeModal}
                            modalHeader={SITE_TEXT.ChooseFormat}
                            leftButtonText={SITE_TEXT.DownloadCSV}
                            leftButtonHandler={downloadCsvData}
                            centerBtnText={SITE_TEXT.DownloadJSON}
                            centerButtonHandler={downloadJsonData}
                            rightButtonText={SITE_TEXT.OriginalFileDownload}
                            rightButtonHandler={downloadOriginalFile}
                        />
                    )}

                    {showDeleteModal && (
                        <ConfirmModal
                            showLoader={isLoading}
                            onClose={closeModal}
                            modalHeader={SITE_TEXT.DELETE_DOC_HEADER}
                            modalDescription={SITE_TEXT.DELETE_DOC_BODY}
                            leftButtonText={SITE_TEXT.Cancel}
                            leftButtonHandler={closeModal}
                            rightButtonText={SITE_TEXT.Delete}
                            rightButtonHandler={deleteSelectedDoc}
                        />
                    )}
                    {showCustomizedFields && (
                        <CustomizeData
                            data={Data}
                            goToBack={closeModal}
                            currentPage={currentPage}
                            handleCustomizeChanges={handleCustomizeChanges}
                        />
                    )}
                </React.Fragment>
            ) : (
                <Loader />
            )}
        </main>
    );
}

export default ReviewDocument;
