import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import c from 'classnames';

import colors from '../../sass/color';
import { checkConfidence } from '../../utils/functions';

export const HighlighTable = styled.div.attrs(({ confidence, box, ...props }) => {
    const { top = 1, left = 1, height = 0, width = 0 } = box || {};
    const color = checkConfidence(confidence);
    const background = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
        color.slice(5, 7),
        16
    )}, 0.03)`;

    return {
        style: {
            border: `1px solid ${color}`,
            background,
            top: `${top}%`,
            left: `${left}%`,
            height: `${height}%`,
            width: `${width}%`,
        },
        ...props,
    };
})`
    position: absolute;
`;

export const DefaultBoxes = styled.div.attrs(({ boundingbox, ...props }) => {
    const { top = 1, left = 1, height = 0, width = 0 } = boundingbox || {};
    return {
        style: {
            top: `${top}%`,
            left: `${left}%`,
            height: `${height}%`,
            width: `${width}%`,
        },
        ...props,
    };
})`
    position: absolute;

    &:hover {
        cursor: pointer;
        border: 2px dashed ${colors.$blue};
    }
`;

export const HighlighContent = styled.div.attrs(({ confidence, box, ...props }) => {
    const { top = 1, left = 1, height = 0, width = 0 } = box || {};
    return {
        style: {
            background: checkConfidence(confidence),
            top: `${top}%`,
            left: `${left}%`,
            height: `${height}%`,
            width: `${width}%`,
        },
        ...props,
    };
})`
    position: absolute;
    opacity: 0.5;
`;

export const ContentWithTextBox = styled.div.attrs(({ confidence, box, ...props }) => {
    const { top = 1, left = 1, height = 0, width } = box || {};
    const heightInUse = parseInt(top + height + 1.5, 10);
    const borderColor = checkConfidence(confidence);
    return {
        style: {
            border: `1px solid ${borderColor}`,
            top: `${heightInUse}%`,
            left: `${left}%`,
            width: `${width}%`,
        },
        ...props,
    };
})``;

export const MenuItemWrap = styled(({ displayProp, ...rest }) => displayProp && <MenuItem {...rest} />)`
    display: ${({ displayProp }) => `${displayProp}`};
`;

export const SelectMenu = ({
    id = 'selectId',
    answer = 0,
    question_text = '',
    options = [],
    handleChange = () => {},
    select_option_text = 'Select an option',
    showLabel = true,
    fullWidth = false,
    disabled = false,
}) => (
    <div
        className={c('selectWrap', {
            emptySelect: answer === 0,
        })}
    >
        {showLabel && <InputLabel id={id}>{question_text}</InputLabel>}
        <Select
            displayEmpty
            disabled={disabled}
            fullWidth={fullWidth}
            id={`${id}-select`}
            labelId={id}
            value={answer}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                classes: {
                    paper: 'userOptionsPaper',
                },
            }}
            onChange={({ target }) => handleChange(target.value)}
        >
            {[{ key: { text: select_option_text } }, ...options].map((optionValue, optionValueInd) => {
                const { key, value } = optionValue;
                const { text, Confidence } = key || {};
                const background = checkConfidence(Confidence);
                const { text: vText } = value || {};
                return (
                    <MenuItemWrap
                        key={`${id}-${optionValueInd}`}
                        classes={{
                            root: 'userOptions',
                        }}
                        value={optionValueInd}
                        displayProp={!optionValueInd ? 'none' : 'flex'}
                    >
                        <span className="key">
                            <span style={{ background }} className="circle" />
                            {text}
                        </span>
                        <span className="value">{vText}</span>
                    </MenuItemWrap>
                );
            })}
        </Select>
    </div>
);

SelectMenu.propTypes = {
    id: PropTypes.string,
    answer: PropTypes.string,
    question_text: PropTypes.string,
    select_option_text: PropTypes.string,
    showLabel: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    handleChange: PropTypes.func,
};

export const CommonSelectMenu = ({
    id = 'selectIdCommon',
    answer = 0,
    question_text = '',
    options = [],
    handleChange = () => {},
    select_option_text = 'Select an option',
    fullWidth = false,
    disabled = false,
}) => (
    <div
        className={c('selectWrap', {
            emptySelect: answer === 0,
        })}
    >
        {question_text && <InputLabel id={id}>{question_text}</InputLabel>}
        <Select
            displayEmpty
            fullWidth={fullWidth}
            disabled={disabled}
            id={`${id}-select`}
            labelId={id}
            value={answer}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                classes: {
                    paper: 'userOptionsPaper',
                },
            }}
            onChange={({ target }) => handleChange(target.value)}
        >
            {[select_option_text, ...options].map((optionText, optionValueInd) => (
                <MenuItemWrap
                    key={`${id}-${optionValueInd}`}
                    classes={{
                        root: 'userOptions',
                    }}
                    value={optionValueInd}
                    displayProp={!optionValueInd ? 'none' : 'flex'}
                >
                    {optionText}
                </MenuItemWrap>
            ))}
        </Select>
    </div>
);

CommonSelectMenu.propTypes = {
    id: PropTypes.string,
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    question_text: PropTypes.string,
    options: PropTypes.array,
    handleChange: PropTypes.func,
    select_option_text: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

export const StyledTooltip = styled(({ className, backgroundColor, color, ...props }) => (
    <Tooltip
        {...props}
        arrow
        className={className}
        placement="top"
        enterTouchDelay={0}
        classes={{ popper: className, backgroundColor, color }}
    />
))(({ backgroundColor = '#fff', color = '#333' }) => {
    return {
        [`& .${tooltipClasses.tooltip}`]: {
            color,
            backgroundColor, // Use the backgroundColor prop
            boxShadow: '0 0 1px 0px rgba(5, 28, 44, 0.15), 0 8px 16px -1px rgba(5, 28, 44, 0.20)',
            fontWeight: 400,
            fontSize: 14,
            padding: '8px 16px',
            borderRadius: 8,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: backgroundColor,
        },
    };
});
