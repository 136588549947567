import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as Success } from '../../assets/success_icon.svg';
import { ReactComponent as Error } from '../../assets/error_icon.svg';
import { ReactComponent as Warn } from '../../assets/info_icon.svg';
import { ReactComponent as X } from '../../assets/x2.svg';

const Toastr = ({ message, open, setOpen, type = 'warn', autoHideDuration = 2000, sx = {}, zIndex = 4 }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
            autoHideDuration={autoHideDuration}
            message={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: '16px',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                        {type === 'success' ? <Success /> : type === 'error' ? <Error /> : <Warn />}
                        {message}
                    </div>
                    <X style={{ cursor: 'pointer' }} onClick={handleClose} />
                </div>
            }
            key={'top-right'}
            sx={{
                '.MuiPaper-root': {
                    background: '#101828',
                    borderLeft: `4px solid ${
                        type === 'success' ? '#12B76A' : type === 'error' ? '#D92D20' : '#DC6803'
                    }`,
                    borderRadius: '8px',
                    width: '100%',
                    zIndex: zIndex,
                },
                '.MuiSnackbarContent-message': {
                    width: '100%',
                },
                ...sx,
            }}
        />
    );
};

export default Toastr;

Toastr.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    type: PropTypes.string,
    autoHideDuration: PropTypes.any,
    sx: PropTypes.object,
    zIndex: PropTypes.number,
};
