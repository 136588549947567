import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import AuthStore from '../../components/common/AuthStore';
import { API_BASE_PATH as baseUrl } from '../../constants/env';
import Endpoints from '../../Endpoints';

const staggeredBaseQuery = retry(
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
            const { accessToken } = AuthStore;
            if (accessToken) {
                headers.set('Authorization', accessToken);
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        },
    }),
    {
        maxRetries: 0,
    }
);

export const modelSettingsApi = createApi({
    reducerPath: 'modelSettingsApi',
    baseQuery: staggeredBaseQuery,
    endpoints: builder => ({
        getModelSettings: builder.mutation({
            query: ({ modelName, owner }) => {
                const { getModelSettings: url } = Endpoints;
                const { idToken } = AuthStore;

                return {
                    url: `${url}?id_token=${idToken}&owner=${owner}&model_name=${modelName}`,
                    method: 'POST',
                };
            },
        }),
        updateModelSettings: builder.mutation({
            query: settings => {
                const { modelSettings: url } = Endpoints;
                const { idToken } = AuthStore;
                return {
                    url: `${url}?id_token=${idToken}`,
                    method: 'POST',
                    body: settings,
                };
            },
        }),
        updateFieldSettings: builder.mutation({
            query: settings => {
                const { fieldSettings: url } = Endpoints;
                const { idToken } = AuthStore;

                return {
                    url: `${url}?id_token=${idToken}`,
                    method: 'POST',
                    body: settings,
                };
            },
        }),
    }),
});

export const { useGetModelSettingsMutation, useUpdateFieldSettingsMutation, useUpdateModelSettingsMutation } =
    modelSettingsApi;
