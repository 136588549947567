import React from 'react';
import './../index.scss';
import SignupStepper from './SignupStepper';
import c from 'classnames';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import Loader from '../../../components/Loader';
import { SITE_TEXT } from '../../../utils/constants';
import './../index.scss';
import PhoneNumber from '../../../components/common/PhoneNumberField';

const SignUpStep2 = ({ signUpError, body, handleData, setSignupStep, isLoading, errors, updateErrors, signupStep }) => {
    const onSubmit = e => {
        e.preventDefault();
        const { Company, Designation, Country, Phone } = body;

        updateErrors({
            Company: !Company,
            Designation: !Designation,
            Phone: !Phone,
            Country: !Country,
        });
        if (Company && Designation && Country && Phone) {
            setSignupStep(3);
        }
    };

    return (
        <div className="signUpStepTwo">
            <div className="stepsWrapper">
                <SignupStepper step={signupStep} />
            </div>
            <div className="wrapper">
                <div className="formWrapper loginWrapper">
                    <h1>Step 2/3 - {SITE_TEXT.EnterCompanyDetails}</h1>
                    <form onSubmit={onSubmit}>
                        {isLoading && <Loader position="fixed" />}

                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Company || errors.passNoMatch,
                            })}>
                            <label>{SITE_TEXT.CompanyText}</label>
                            <TextField
                                fullWidth
                                name="Company"
                                value={body.Company}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.CompanyText}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Company && <div className="warning">{SITE_TEXT.InvalidCompany}</div>}
                        </div>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Designation,
                            })}>
                            <label>{SITE_TEXT.DesignationText}</label>
                            <TextField
                                fullWidth
                                name="Designation"
                                value={body.Designation}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.DesignationPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Designation && <div className="warning">{SITE_TEXT.InvalidDesignation}</div>}
                        </div>
                        {!body.Phone && (
                            <div
                                className={c('inputWrap', {
                                    errorWrap: errors.Phone || errors.passNoMatch,
                                })}>
                                <label>{SITE_TEXT.PhoneText}</label>
                                <PhoneNumber
                                    fullWidth
                                    name="Phone"
                                    value={body.Phone}
                                    onChange={({ target }) => handleData(target)}
                                    placeholder={SITE_TEXT.PhonePlaceholder}
                                    inputProps={{ maxLength: 100 }}
                                />
                                {errors.Phone && <div className="warning">{SITE_TEXT.InvalidPhone}</div>}
                            </div>
                        )}
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.Country,
                            })}>
                            <label>{SITE_TEXT.CountryText}</label>
                            <TextField
                                fullWidth
                                name="Country"
                                value={body.Country}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.CountryPlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.Country && <div className="warning">{SITE_TEXT.InvalidCountry}</div>}
                        </div>
                        <Button type="submit" onClick={onSubmit} fullWidth>
                            {SITE_TEXT.NextText}
                        </Button>

                        {signUpError && <h2>{signUpError}</h2>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUpStep2;

SignUpStep2.propTypes = {
    updateSignUp: PropTypes.func.isRequired,
    signUpError: PropTypes.any.isRequired,
    body: PropTypes.any.isRequired,
    handleData: PropTypes.any.isRequired,
    setSignupStep: PropTypes.any.isRequired,
    signUpStatus: PropTypes.any.isRequired,
    isLoading: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    updateErrors: PropTypes.any.isRequired,
    signupStep: PropTypes.number.isRequired,
};
