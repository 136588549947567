import React, { useEffect } from 'react';
import LayoutContent from '../../components/LayoutContent';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { SITE_TEXT } from '../../utils/constants';
import { useGetDocumentsTypeMutation } from '../DocumentModels/apiService';
import { setCurrentModel } from '../DocumentModels/reducer';
import { ReactComponent as Arrow } from '../../assets/arrow-move.svg';
import GeneralModelSettings from './GeneralModelSettings';
import ModelFieldSettings from './ModelFieldSettings';
import { useGetModelSettingsMutation } from './apiService';
import Loader from '../../components/Loader';

const ModelSettings = () => {
    const { currentModel } = useSelector(state => state.docModelsData);
    // const [getDocumentsForModel] = useGetDocumentsForModelMutation();
    const [getDocumentsType] = useGetDocumentsTypeMutation();

    const [getModelSettings, { isLoading }] = useGetModelSettingsMutation();

    const { modelId } = useParams();
    const dispatch = useDispatch();

    const [model, setModel] = React.useState(null);

    // const getCurrentDocuments = modelName => {
    //     getDocumentsForModel({ currentModel: modelName });
    // };
    const SETTINGS_ROUTE = [
        {
            id: 'generalSettings',
            name: 'General Settings',
        },
        {
            id: 'fieldSettings',
            name: 'Field Settings',
        },
    ];

    const [selectedSettingsTab, setSelectedSettingsTab] = React.useState(SETTINGS_ROUTE[1].id);

    useEffect(() => {
        getDocumentsType().then(({ data = {} }) => {
            const { documents: docModelsData } = data;
            const model = docModelsData.find(({ id }) => id === parseInt(modelId)) || {};
            setModel(model);
            if (model?.Name) {
                dispatch(setCurrentModel(model.Name));
                // getCurrentDocuments(Name);
                getModelSettings({ modelName: model.Name, owner: model.CreatedBy });
            }
        });
    }, []);

    return (
        <LayoutContent>
            <div className="settingsWrap">
                <div id="upperHead" className="upperHead clearfix settingsTitle">
                    <h1>
                        <NavLink className="menuItems" to={`/documents/${modelId}`}>
                            {currentModel}
                        </NavLink>
                        <Arrow className="headerArrow" height={24} width={24} />
                        <span>{SITE_TEXT.ModelSettings}</span>
                    </h1>
                </div>
                <div className="settingsWrapper">
                    <aside>
                        <div>
                            {SETTINGS_ROUTE.map(tabKey => {
                                const { id, name } = tabKey;
                                return (
                                    <React.Fragment key={id}>
                                        <NavLink
                                            className={() => (selectedSettingsTab === id ? 'active' : '')}
                                            onClick={() => {
                                                setSelectedSettingsTab(id);
                                            }}>
                                            {name}
                                        </NavLink>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </aside>
                    {model?.Name && (
                        <div className="settingsContent">
                            {isLoading && <Loader position="relative" />}
                            {selectedSettingsTab === 'generalSettings' ? (
                                <GeneralModelSettings model={model} />
                            ) : selectedSettingsTab === 'fieldSettings' ? (
                                <ModelFieldSettings model={model} />
                            ) : null}
                        </div>
                    )}
                </div>
            </div>
        </LayoutContent>
    );
};

export default ModelSettings;
