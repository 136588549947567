import colors from '../sass/color';
import * as XLSX from 'xlsx';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z0-9]{2,}$/; /* eslint-disable-line */

export const removeUnderscore = text => text.replace(/_/g, ' ');

export const addZeroToNUmber = (num = 0) => {
    if (num >= 100) {
        return num;
    }
    return ('0' + num).slice(-2);
};

export const validateEmail = (email = '') => {
    if (email.toLowerCase().match(emailRegex)) {
        return true;
    } else {
        return false;
    }
};

export const isPasswordValid = password => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
};

export const checkConfidence = (confidence = 0) => {
    if (confidence > 90) {
        return colors.$green;
    } else if (confidence >= 70 && confidence <= 90) {
        return colors.$yellow;
    } else {
        return colors.$red;
    }
};

function sortByPageNumber(metadata) {
    metadata.sort((a, b) => {
        const m = a.split(' ');
        const n = b.split(' ');
        let pageNumberA = Number(m[m.length - 1]);
        let pageNumberB = Number(n[n.length - 1]);
        return pageNumberA - pageNumberB;
    });
    return metadata;
}

const findAndMergeTable = (tableData, table, tableMeta) => {
    let nextPage = tableData.Custom_Info?.next_table.Page;
    let tableNo = tableData.Custom_Info?.next_table.Table;

    let nextPageTable = table[nextPage].filter(data => {
        if (data.Custom_Info?.previous_table?.Table === tableNo) {
            data.repeated = true;
            return true;
        }
        return false;
    });

    if (nextPageTable[0].Custom_Info?.next_table) {
        nextPageTable[0] = findAndMergeTable(nextPageTable[0], table, tableMeta);
    }
    const temp = {
        ...tableData,
        // eslint-disable-next-line no-unsafe-optional-chaining
        Cell_Info: [...tableData.Cell_Info, ...nextPageTable[0]?.Cell_Info],
        page: [...tableData.page, ...nextPageTable[0].page],
    };

    nextPageTable.visited = true;

    return temp;
};

const getFormattedTableData = data => {
    // let table = mergeTables(data);
    let table = {};
    Object.keys(data).forEach(key => {
        if (data[key].Table) {
            Object.keys(data[key].Table).forEach(tableKey => {
                table[key] = [...(table[key] ? table[key] : []), { ...data[key].Table[tableKey], page: key }];
            });
        }
    });
    let tableMeta = [];

    Object.keys(table).forEach(key => {
        table[key].forEach(tableData => {
            if (tableData?.Custom_Info?.next_table) {
                const temp = findAndMergeTable(tableData, table, tableMeta);
                tableMeta.push(temp);
            } else {
                tableMeta.push(tableData);
            }
        });
    });

    const finalTable = tableMeta.filter(data => !data.repeated);

    return finalTable;
};

export const donwloadCSV = ({ data, fileName }) => {
    const wb = XLSX.utils.book_new(); // Create a new workbook

    Object.keys(data).forEach(key => {
        const keyValuePair = data[key].KEY_VALUE_PAIR;

        // adding key value pair to excel
        if (keyValuePair?.length > 0) {
            // Create data in the format needed for the Excel sheet
            const data = [
                ['Key', 'Value'], // Column headers
                ...keyValuePair.map(keyValueData => [keyValueData.key.text, keyValueData.value.text]), // Data from key-value pair
            ];
            const ws = XLSX.utils.aoa_to_sheet(data); // Convert array of arrays to sheet

            ws['!cols'] = [
                { wch: 40 }, // "Key" column width
                { wch: 60 }, // "Value" column width
            ];

            XLSX.utils.book_append_sheet(wb, ws, `Metadata on Page ${key}`); // Append sheet to workbook with the title 'Key-values'
        }
    });

    const Table = getFormattedTableData(data);

    // adding table data to excel
    Table.forEach((tableData, index) => {
        const { Cell_Info: array2D } = tableData;
        let data = array2D.map(row => row.map(cell => (cell ? cell.Text : '')));

        const ws = XLSX.utils.aoa_to_sheet(data); // Convert array of arrays to sheet

        ws['!cols'] = Array(data[0].length).fill({ wch: 25 });

        const tableName = `Table ${index + 1} ${
            Array.isArray(tableData.page)
                ? 'from ' + tableData.page[0] + ' to ' + tableData.page[tableData.page.length - 1]
                : 'on Page ' + tableData.page
        }`;

        XLSX.utils.book_append_sheet(wb, ws, tableName); // Append sheet to workbook
    });

    wb.SheetNames = sortByPageNumber(wb.SheetNames);

    // Write the workbook to a file and trigger download
    XLSX.writeFile(wb, `${fileName + new Date().getTime()}.xlsx`);
};

export const downloadJson = (jsonData, fileName) => {
    const jsonString = JSON.stringify(jsonData, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};

export const getAllPageNumber = (till = '') => {
    const allPageNumber = till.split('-');
    const first = parseInt(allPageNumber[0], 10);
    const last = parseInt(allPageNumber[1], 10);
    return {
        first,
        last,
    };
};

export const filterKeysByBoundingBox = (keys, boundingBox) => {
    return Object.keys(keys).filter(key => {
        const keyBox = keys[key].BoundingBox;
        const overlapLeft = Math.max(boundingBox.left, keyBox.left);
        const overlapTop = Math.max(boundingBox.top, keyBox.top);
        const overlapRight = Math.min(boundingBox.left + boundingBox.width, keyBox.left + keyBox.width);
        const overlapBottom = Math.min(boundingBox.top + boundingBox.height, keyBox.top + keyBox.height);

        // Calculate overlap width and height
        const overlapWidth = overlapRight - overlapLeft;
        const overlapHeight = overlapBottom - overlapTop;

        // Ensure overlap width and height are positive; if not, there's no overlap
        if (overlapWidth > 0 && overlapHeight > 0) {
            // Calculate area of overlap
            const overlapArea = overlapWidth * overlapHeight;

            // Calculate area of the key's bounding box
            const keyBoxArea = keyBox.width * keyBox.height;

            // Check if overlap is more than 50% of the key's bounding box area
            if (overlapArea / keyBoxArea > 0.5) {
                return true;
            }
        }
        return false;
    });
};

export const processPDFFile = file =>
    new Promise((resolve, reject) => {
        if (file && file.type === 'application/pdf') {
            const fileReader = new FileReader();

            fileReader.onload = async e => {
                try {
                    const typedarray = new Uint8Array(e.target.result);
                    const pdfDoc = await pdfjsLib.getDocument({ data: typedarray }).promise;
                    resolve({ DocumentName: file.name, Pages: pdfDoc.numPages });
                } catch (error) {
                    reject(error);
                }
            };

            fileReader.onerror = error => reject(error);

            fileReader.readAsArrayBuffer(file);
        } else {
            resolve(null);
        }
    });
