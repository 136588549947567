class AuthStore {
    constructor() {
        if (!AuthStore.instance) {
            this._data = localStorage;
            AuthStore.instance = this;
        }

        return AuthStore.instance;
    }

    set accessToken(value) {
        this._data.setItem('accessToken', value);
    }

    get accessToken() {
        return this._data.getItem('accessToken');
    }

    deleteAccessToken() {
        this._data.removeItem('accessToken');
    }

    set idToken(value) {
        this._data.setItem('idToken', value);
    }

    get idToken() {
        return this._data.getItem('idToken');
    }

    deleteIdToken() {
        this._data.removeItem('idToken');
    }

    set refreshToken(value) {
        this._data.setItem('refreshToken', value);
    }

    get refreshToken() {
        return this._data.getItem('refreshToken');
    }

    deleteRefreshToken() {
        this._data.removeItem('refreshToken');
    }

    set userName(value) {
        this._data.setItem('userName', value);
    }

    get userName() {
        return this._data.getItem('userName');
    }

    deleteUserName() {
        this._data.removeItem('userName');
    }
}

const instance = new AuthStore();
Object.freeze(instance);

export default instance;
