import React, { useEffect } from 'react';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';
import c from 'classnames';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch } from '../../components/common/Switch';
import { useUpdateModelSettingsMutation } from './apiService';
import Toastr from '../../components/common/Toastr';
// import AutoComplete from '../../components/common/AutoComplete';

const GeneralModelSettings = ({ model }) => {
    // const { user } = useSelector(state => state.userData);

    const [updateModelSettings] = useUpdateModelSettingsMutation();
    const { generalSettings } = useSelector(state => state.modelSettingsData);

    const [openToast, setOpenToast] = React.useState(false);
    const [settingChangeToast, setSettingChangeToast] = React.useState({ msg: '', type: 'success' });

    // const [usersList, setUsersList] = React.useState([]);

    const [body, updateParams] = React.useState({
        Name: model.Name,
        ID: model.id,
        NotifyUser: false,
        OrientationCorrection: false,
        TitlCorrection: false,
        AutoSplit: false,
    });

    const [errors, updateErrors] = React.useState({
        Name: false,
        ID: false,
    });

    // useEffect(() => {
    //     updateParams({
    //         Name: model.Name,
    //         ID: model.id,
    //         NotifyUser: model.NotifyUser,
    //         OrientationCorrection: model.OrientationCorrection,
    //         TitlCorrection: model.TitlCorrection,
    //         AutoSplit: model.AutoSplit,
    //     });
    // }, [user]);
    useEffect(() => {
        updateParams(old => ({
            Name: generalSettings?.ModelName ? generalSettings.ModelName : old.Name,
            ID: generalSettings?.ModelId ? generalSettings.ModelId : old.id,
            NotifyUser: generalSettings?.Notify ? generalSettings.Notify : old.NotifyUser,
            OrientationCorrection: generalSettings?.Orientation
                ? generalSettings.Orientation
                : old.OrientationCorrection,
            TitlCorrection: generalSettings?.Tilt ? generalSettings.Tilt : old.TitlCorrection,
            AutoSplit: generalSettings?.AutoSplit ? generalSettings.AutoSplit : old.AutoSplit,
        }));
    }, [generalSettings]);

    // useEffect(() => {
    //     setUsersList(
    //         teamList.map(item => {
    //             return {
    //                 key: item.Name,
    //                 value: item.Email,
    //             };
    //         })
    //     );
    // }, [teamList]);

    const handleData = ({ name, value }) => {
        const handlePassError = name === 'Password' || name === 'RePassword';
        const additionalError = handlePassError ? { passNoMatch: false } : {};
        updateParams({
            ...body,
            [name]: value,
        });
        updateErrors({
            ...errors,
            ...additionalError,
            [name]: false,
        });
    };

    const onSubmit = e => {
        e.preventDefault();
        const { Name, ID } = body;

        updateErrors({
            Name: !Name,
            ID: !ID,
        });

        if (Name && ID) {
            // console.log('success');
            let payload = {
                modelName: Name,
                modelId: ID,
                owner: model.CreatedBy,
                notify: body.NotifyUser ? true : false,
                orientation: body.OrientationCorrection ? true : false,
                tilt: body.TitlCorrection ? true : false,
                autoSplit: body.AutoSplit ? true : false,
            };

            updateModelSettings(payload).then(res => {
                if (res.error) {
                    setSettingChangeToast({ msg: SITE_TEXT.APIERROR, type: 'error' });
                    setOpenToast(true);
                } else {
                    setSettingChangeToast({
                        msg: res?.data?.Message ? res.data.Message : SITE_TEXT.SettingsUpdateSuccess,
                        type: 'success',
                    });
                    setOpenToast(true);
                }
            });
        }
    };

    return (
        <div className="settingsContentWrapper">
            <div id="subHead" className="subHead">
                <h2>{SITE_TEXT.GeneralSettings}</h2>
            </div>
            <div className="mainContainer modelSettingContainer">
                <div className="clearfix">
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Name,
                        })}>
                        <label>{SITE_TEXT.ModelName}</label>
                        <TextField
                            fullWidth
                            name="Name"
                            value={body.Name}
                            // onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.ModelNamePlaceholder}
                            inputProps={{ maxLength: 100 }}
                        />
                        {errors.Name && <div className="warning">{SITE_TEXT.InvalidModelName}</div>}
                    </div>
                </div>
                <div className="clearfix">
                    <div className="inputWrap">
                        <label>{SITE_TEXT.ModelId}</label>
                        <TextField
                            fullWidth
                            name="Name"
                            value={body.ID}
                            placeholder={SITE_TEXT.ModelNamePlaceholder}
                            inputProps={{ maxLength: 100 }}
                        />
                    </div>
                </div>
                <h3 className="assignedUserHeader">{SITE_TEXT.AssignedUsers}</h3>
                <div className="clearfix">
                    <div className="inputWrap modelSettingsRadio">
                        <label>{SITE_TEXT.NotifyUsers}</label>
                        <Switch
                            checked={body.NotifyUser}
                            onChange={({ target }) => handleData({ name: 'NotifyUser', value: target.checked })}
                        />
                    </div>
                </div>
                <h3 className="assignedUserHeader">{SITE_TEXT.PreProcessing}</h3>

                <div className="clearfix">
                    <div className="inputWrap modelSettingsRadio">
                        <span>
                            <label>{SITE_TEXT.OrientationCorrection}</label>
                            <div>{SITE_TEXT.AutoAdjust}</div>
                        </span>
                        <Switch
                            checked={body.OrientationCorrection}
                            onChange={({ target }) =>
                                handleData({ name: 'OrientationCorrection', value: target.checked })
                            }
                        />
                    </div>
                </div>
                <div className="clearfix">
                    <div className="inputWrap modelSettingsRadio">
                        <span>
                            <label>{SITE_TEXT.TitlCorrection}</label>
                            <div>{SITE_TEXT.AutoAdjustSkew}</div>
                        </span>
                        <Switch
                            checked={body.TitlCorrection}
                            onChange={({ target }) => handleData({ name: 'TitlCorrection', value: target.checked })}
                        />
                    </div>
                </div>
                <div className="clearfix">
                    <div className="inputWrap modelSettingsRadio">
                        <span>
                            <label>{SITE_TEXT.DocAutoSplit}</label>
                            <div>{SITE_TEXT.SplitPage}</div>
                        </span>
                        <Switch
                            checked={body.AutoSplit}
                            onChange={({ target }) => handleData({ name: 'AutoSplit', value: target.checked })}
                        />
                    </div>
                </div>
                <Button onClick={onSubmit}>{SITE_TEXT.SaveChanges}</Button>
            </div>
            <Toastr
                message={settingChangeToast.msg}
                open={openToast}
                setOpen={setOpenToast}
                type={settingChangeToast.type}
                autoHideDuration={4000}
            />
        </div>
    );
};
export default GeneralModelSettings;

GeneralModelSettings.propTypes = {
    model: PropTypes.any.isRequired,
};
