import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

function ErrorHandler({ errorData = { message: '', errors: [] } }) {
    const { message, errors } = errorData;
    return (
        <div className="errorPage">
            <h1>{message}</h1>
            {errors && errors.length > 0 ? (
                <React.Fragment>
                    {errors.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </React.Fragment>
            ) : null}
        </div>
    );
}

ErrorHandler.propTypes = {
    errorData: PropTypes.shape({
        message: PropTypes.string,
        errors: PropTypes.arrayOf(PropTypes.string),
    }),
};

export default React.memo(ErrorHandler);
