import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import docLogo from '../../assets/docLogo.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-right.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { ReactComponent as Logout } from '../../assets/log-out.svg';
import { ReactComponent as Activity } from '../../assets/activity.svg';
import { ReactComponent as Coin } from '../../assets/coins.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right.svg';
// import { ReactComponent as Book } from '../../assets/book.svg';
import { ROUTES_CONTENT, ROUTES_CONTENT_USER, SITE_TEXT } from '../../utils/constants';
import './index.scss';
import Avatar from '../common/Avatar';
import Popover from '@mui/material/Popover';
import AuthStore from './../../components/common/AuthStore';
import { useGetUserDetailsMutation, useRefreshTokenMutation } from '../../containers/Settings/apiService';
import Skeleton from '../common/Skeleton';
import Loader from '../Loader';
import RequestCreditDialog from './RequestCreditDialog';

export default function LayoutContent({ title, headerItemsLeft, headerItemsRight, children }) {
    const { currentModel } = useSelector(state => state.docModelsData);
    const { user, loadingRefreshToken, credits } = useSelector(state => state.userData);

    const [getUserDetails, { isLoading }] = useGetUserDetailsMutation();
    const [refreshToken] = useRefreshTokenMutation();

    const location = useLocation();
    const { pathname } = location;
    const showInnerDocs = pathname.includes('/documents/');

    const [menuOpen, setMenuOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openRequestCreditDialog, setOpenRequestCreditDialog] = React.useState(false);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const logout = () => {
        AuthStore.deleteAccessToken();
        AuthStore.deleteIdToken();
        AuthStore.deleteUserName();
        window.location.reload();
    };
    useEffect(() => {
        const tempFn = async () => {
            if (user.FirstName) return;
            const userDetails = await getUserDetails();

            if (userDetails?.error?.status == 'FETCH_ERROR') {
                refreshToken();
            }
        };
        tempFn();
    }, []);

    const creditExhausted = false;
    const showCreditFlow = false;

    return (
        <>
            {showCreditFlow && creditExhausted && (
                <div className="helloBarCreditExhausted">
                    {SITE_TEXT?.CreditExhausted}&nbsp;
                    <span onClick={() => setOpenRequestCreditDialog(true)}>
                        {SITE_TEXT?.RequestCredit} <ArrowRight />
                    </span>
                </div>
            )}
            <main className="layoutContent clearfix" style={{ height: creditExhausted ? 'calc(100% - 44px)' : '100%' }}>
                {/* {showLoader && <Loader />} */}
                {loadingRefreshToken && <Loader position="fixed" />}

                <aside>
                    <div>
                        <div className="upperHead">
                            <img src={docLogo} alt="" />
                        </div>
                        {ROUTES_CONTENT.map(routeKey => {
                            const { path, name } = routeKey;
                            const isActive = showInnerDocs && path === '/';
                            return (
                                <React.Fragment key={path}>
                                    <NavLink
                                        to={path}
                                        className={() => (isActive || pathname === path ? 'active' : '')}>
                                        {<routeKey.imgSrc />}
                                        {name}
                                    </NavLink>
                                    {showInnerDocs && path === '/' && currentModel && (
                                        <div className="currentModel">
                                            <Arrow />
                                            {currentModel}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div>
                        {ROUTES_CONTENT_USER.map(routeKey => {
                            const { path, name } = routeKey;
                            const isActive = showInnerDocs && path === '/';
                            return (
                                <React.Fragment key={path}>
                                    <NavLink
                                        to={path}
                                        className={() => (isActive || pathname === path ? 'active' : '')}>
                                        {<routeKey.imgSrc />}
                                        {name}
                                    </NavLink>
                                </React.Fragment>
                            );
                        })}
                        <div className="userCard">
                            <div className="details">
                                {isLoading ? (
                                    <Skeleton variant="circular" width={40} height={40} sx={{ background: '#fff' }} />
                                ) : (
                                    <Avatar
                                        height={40}
                                        width={40}
                                        name={`${user?.FirstName ? user?.FirstName?.slice(0, 1) : ''}${
                                            user?.LastName ? user?.LastName?.slice(0, 1) : ''
                                        }`}
                                    />
                                )}
                                <div>
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            width={100}
                                            height={20}
                                            sx={{ background: '#fff', borderRadius: '' }}
                                        />
                                    ) : (
                                        <div className="name">{`${user?.FirstName ? user?.FirstName : ''} ${
                                            user?.LastName ? user?.LastName : ''
                                        }`}</div>
                                    )}
                                    {isLoading ? (
                                        <Skeleton
                                            variant="text"
                                            width={100}
                                            height={20}
                                            sx={{ background: '#fff', borderRadius: '' }}
                                        />
                                    ) : (
                                        <div className="role">{user?.role ? user?.role : ''}</div>
                                    )}
                                </div>
                            </div>

                            <ChevronDown className={`icon ${menuOpen && 'open'}`} onClick={handleClick} />
                        </div>
                    </div>
                </aside>
                <section className="mainContent">
                    {title && (
                        <header>
                            <div>
                                <span>{title ? title : 's'}</span>
                                {headerItemsLeft}
                            </div>
                            <div>{headerItemsRight}</div>
                        </header>
                    )}
                    <div className="children">{children}</div>
                </section>
                <Popover
                    id="user-menu-popover"
                    open={menuOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <div className="menuWrapper">
                        {/* add logic for credits  */}
                        {showCreditFlow && (
                            <div className={`creditsMenu ${creditExhausted ? 'exhausted' : ''}`}>
                                <div className="creditDetails">
                                    <div className="creditIcon">
                                        <Coin />
                                    </div>
                                    <div className="credits">
                                        <div className="creditText">{SITE_TEXT.Credits}</div>
                                        <div className="creditValue">{credits?.credits ? credits?.credits : 0}/100</div>
                                    </div>
                                </div>
                                <ChevronDown className="cIcon" onClick={() => setOpenRequestCreditDialog(true)} />
                            </div>
                        )}
                        {user?.role?.toLowerCase() !== 'member' && (
                            <NavLink
                                to={'/activity-log'}
                                className={`menuItems  ${pathname === 'activity-logs' ? 'active' : ''}`}>
                                <Activity />
                                {SITE_TEXT.ActivityLogs}
                            </NavLink>
                        )}
                        <div></div>
                        <div className="menuItems logout" onClick={logout}>
                            <Logout />
                            Logout
                        </div>
                    </div>
                </Popover>
            </main>
            <RequestCreditDialog open={openRequestCreditDialog} onClose={() => setOpenRequestCreditDialog(false)} />
        </>
    );
}

LayoutContent.propTypes = {
    children: PropTypes.node.isRequired,
    showLoader: PropTypes.bool,
    headerItemsLeft: PropTypes.any,
    headerItemsRight: PropTypes.any,
    title: PropTypes.string,
};
