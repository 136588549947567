import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';
import PropTypes from 'prop-types';

const RequestCreditDialog = ({ open, onClose }) => {
    const closeDialog = () => {
        onClose(false);
    };

    const selected = 100;

    return (
        <Dialog open={open} maxWidth="sm" fullWidth className="requestCreditDialog" onClose={closeDialog}>
            <DialogTitle className="header">
                {SITE_TEXT.ReqCredits}
                <span onClick={closeDialog} style={{ position: 'absolute', right: 24, top: 12, cursor: 'pointer' }}>
                    &times;
                </span>
            </DialogTitle>
            <DialogContent className="content" style={{ paddingBottom: 0 }}>
                <div className="creditInfo">{SITE_TEXT.AddCreditInfo}</div>
                <div className="inputWrap">
                    <label>{SITE_TEXT.NoOfDoc}</label>
                    <TextField
                        fullWidth
                        name="Credits"
                        value={100}
                        placeholder={SITE_TEXT.NoOfDocPlaceholder}
                        inputProps={{ maxLength: 100 }}
                    />
                </div>
                <div className="chipGroup">
                    {/* 100
                    500
                    1000
                    2000
                    5000 */}
                    <div className={`chipItem ${selected == 100 ? 'selected' : ''}`}>100</div>
                    <div className={`chipItem ${selected == 500 ? 'selected' : ''}`}>500</div>
                    <div className={`chipItem ${selected == 1000 ? 'selected' : ''}`}>1000</div>
                    <div className={`chipItem ${selected == 2000 ? 'selected' : ''}`}>2000</div>
                    <div className={`chipItem ${selected == 5000 ? 'selected' : ''}`}>5000</div>
                </div>
            </DialogContent>
            <DialogActions className="footer">
                <Button fullWidth className="secondary" onClick={closeDialog}>
                    {SITE_TEXT.Cancel}
                </Button>
                <Button fullWidth>{SITE_TEXT.Confirm}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RequestCreditDialog;

RequestCreditDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};
