import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as Cross } from '../../assets/x.svg';

const DataTable = ({ tableData, highLightKeyValCell, closeTable = () => {} }) => (
    <section className="bottomTable">
        <span onClick={closeTable} className="close">
            <Cross />
        </span>
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {tableData[0].map(({ BoundingBox, Confidence, Text, Hidden }, index) => {
                            if (Hidden) return null;
                            return (
                                <TableCell
                                    onClick={() =>
                                        highLightKeyValCell(
                                            {
                                                valueInfo: {
                                                    BoundingBox,
                                                    Confidence,
                                                    text: Text,
                                                },
                                                valId: `0-${index}`,
                                            },
                                            false
                                        )
                                    }
                                    key={`${index}-${Text}`}>
                                    {Text}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.slice(1).map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                            <TableCell>{rowIndex + 1}</TableCell>
                            {row.map(({ BoundingBox, Confidence, Text, Hidden }, cellIndex) => {
                                if (Hidden) return null;
                                return (
                                    <TableCell
                                        onClick={() =>
                                            highLightKeyValCell(
                                                {
                                                    valueInfo: {
                                                        BoundingBox,
                                                        Confidence,
                                                        text: Text,
                                                    },
                                                    valId: `${rowIndex + 1}-${cellIndex}`,
                                                },
                                                false
                                            )
                                        }
                                        key={cellIndex}>
                                        {Text}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </section>
);

DataTable.propTypes = {
    tableData: PropTypes.array.isRequired,
    highLightKeyValCell: PropTypes.func.isRequired,
    closeTable: PropTypes.func,
};

export default DataTable;
