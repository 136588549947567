import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { API_BASE_PATH as baseUrl, API_TOKEN as token } from '../../constants/env';
import Endpoints from '../../Endpoints';

const staggeredBaseQuery = retry(
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    {
        maxRetries: 0,
    }
);

export const loginManagementApi = createApi({
    reducerPath: 'loginManagementApi',
    baseQuery: staggeredBaseQuery,
    endpoints: builder => ({
        loginStatus: builder.mutation({
            query: ({ body }) => {
                const { loginPath: url } = Endpoints;
                return {
                    url,
                    method: 'POST',
                    body,
                };
            },
        }),
        signUpStatus: builder.mutation({
            query: ({ body }) => {
                const { signUpPath: url } = Endpoints;
                return {
                    url,
                    method: 'POST',
                    body,
                };
            },
        }),
        updateTempPass: builder.mutation({
            query: body => {
                const { updatePassword: url } = Endpoints;
                return {
                    url,
                    method: 'POST',
                    body,
                };
            },
        }),
        otpStatus: builder.mutation({
            query: ({ body }) => {
                const { authenticate: url } = Endpoints;
                return {
                    url: `${url}?Email=${body.Email}&confirmation_code=${body.confirmation_code}`,
                    method: 'POST',
                    // body,
                };
            },
        }),
        resendCode: builder.mutation({
            query: ({ body }) => {
                const { resendCode: url } = Endpoints;
                return {
                    url: `${url}?email=${body.email}`,
                    method: 'POST',
                    // body,
                };
            },
        }),

        // forgotPassAPIs
        sendVerificationCode: builder.mutation({
            query: ({ email }) => {
                const { forgotPassword: url } = Endpoints;
                return {
                    url: `${url}?email=${email}`,
                    method: 'POST',
                };
            },
        }),
        verifyOtp: builder.mutation({
            query: ({ email, verification_code, new_password }) => {
                const { confirmForgotPassword: url } = Endpoints;
                return {
                    url: `${url}?email=${email}&verification_code=${verification_code}&new_password=${new_password}`,
                    method: 'POST',
                };
            },
        }),
    }),
});

export const {
    useLoginStatusMutation,
    useSignUpStatusMutation,
    useOtpStatusMutation,
    useResendCodeMutation,
    useUpdateTempPassMutation,
    useSendVerificationCodeMutation,
    useVerifyOtpMutation,
} = loginManagementApi;
