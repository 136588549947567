/* eslint-disable */

import React, { useState } from 'react';
import { MenuItem, InputAdornment, Select, OutlinedInput } from '@mui/material';
import { COUNTRY_CODE } from '../../utils/CountryCodes';
import './../../sass/base.scss';

const PhoneNumber = ({ name, onChange, placeHolder, fullWidth, ...props }) => {
    const [countryCode, setCountryCode] = useState('+1');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleCountryCodeChange = event => {
        setCountryCode(event.target.value);
    };

    const handlePhoneNumberChange = event => {
        const value = event.target.value;
        setPhoneNumber(value);
        onChange({ target: { name, value: `${countryCode} ${value}` } });
    };

    return (
        <div className="phone-number-input">
            <OutlinedInput
                id="phone-number-input"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                fullWidth={fullWidth}
                startAdornment={
                    <InputAdornment position="start">
                        <Select
                            labelId="country-code-select-label"
                            id="country-code-select"
                            value={countryCode}
                            onChange={handleCountryCodeChange}
                            label="Country Code">
                            {COUNTRY_CODE?.map((c, i) => (
                                <MenuItem key={c.code} value={c.code}>
                                    {c.iso}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                }
            />
        </div>
    );
};

export default PhoneNumber;
