import { ReactComponent as DocModels } from '../assets/docModels.svg';
import { ReactComponent as AllDocs } from '../assets/allDocs.svg';
import { ReactComponent as Settings } from '../assets/setting.svg';

export const SITE_TEXT = {
    NotFoundText: '404 - Page not found',
    LoginText: 'Login to Docscience',
    LoginBtnText: 'Login',
    SignupBtnText: 'Signup',
    FnameText: 'First name',
    FnamePlaceholder: 'Enter First name',
    LnameText: 'Last name',
    LnamePlaceholder: 'Enter Last name',
    EmailText: 'Email',
    DesignationText: 'Designation',
    CountryText: 'Country',
    InvalidEmail: 'Please provide a valid email ID',
    InvalidFName: 'Please enter First name',
    InvalidLName: 'Please enter Last name',
    InvalidPassword: 'Please enter password',
    InvalidPhone: 'Please enter phone',
    InvalidCompany: 'Please enter company name',
    InvalidPassMatch: 'Password and Re-password do not match',
    InvalidPassMatchRegex:
        'Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (@$!%*?&).',
    InvalidDesignation: 'Please enter designation',
    InvalidCountry: 'Please enter country',
    InvalidCustom: 'Please enter custom',
    EmailPlaceholder: 'Enter Email',
    PasswordText: 'Password',
    RePasswordText: 'Re-enter password',
    CompanyText: 'Company',
    NextText: 'Next',
    CustomText: 'Custom',
    FinishText: 'Finish',
    PhoneText: 'Phone',

    DocumentType: 'Choose your document type',
    ChooseDocumentPlaceholder: 'Choose document type',
    InvalidDocument: 'Please select document type',
    NoOfDoc: 'Choose the number of documents per month',
    NoOfDocPlaceholder: 'Choose number of documents',
    InvalidNoOfDoc: 'Please select number of document',
    AdditionalInfoText: 'Additional info',
    AdditionalInfoPlaceholder: 'Give us additional info about your requirement',

    PasswordPlaceholder: 'Enter Password',
    CountryPlaceholder: 'Enter Company',
    DesignationPlaceholder: 'Enter Designation',
    CustomPlaceholder: 'Type the custom document type name',
    PhonePlaceholder: 'Enter Phone',
    EnterCompanyDetails: 'Enter your company details',
    SingUpText: 'Signup with Docscience',
    docModels: 'Document models',
    bankDocs: 'Bank Documents',
    allDocs: 'My documents',
    RenameModel: 'Rename model',
    RenameDocument: 'Rename Document',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Hide: 'Hide',
    Show: 'Show',
    Download: 'Download',
    CustomizedFields: 'Customize fields',
    Confirm: 'Confirm',
    SaveChanges: 'Save changes',
    AddNewModel: 'Add new model',
    UploadFilesHeader: 'Upload files',
    AddModelDesc: 'Choose from the list of available models',
    NewModel: 'New model',
    AddNewModelText: 'Add a new model',
    DELETE_MODEL_HEADER: 'Delete model',
    DELETE_DOC_HEADER: 'Delete document',
    ENTER_MODEL_NAME: 'Enter the name for the model',
    DELETE_MODEL_BODY: 'Are you sure you want to delete the model? This action cannot be reversed',
    DELETE_DOC_BODY: 'Are you sure you want to delete the document? This action cannot be reversed',
    Fields: 'Fields',
    Tables: 'Tables',
    noModelText: 'No document models found!',
    noDocText: 'No documents found!',
    uploadDocsText: 'Upload documents by clicking on the upload button on the top',
    reviewText: 'Review',
    uploadText: 'Upload',
    downloadDataText: 'Download data',
    dNdFiles: 'Drag & drop files',
    supportedDocs: 'Supported formats: JPEG, PNG, PDF',
    FieldsVerification: 'Fields require verification',
    Page: 'Page',
    ChooseFormat: 'Choose a format',
    DownloadCSV: 'Download CSV',
    DownloadJSON: 'Download JSON',
    OriginalFileDownload: 'Download original file',
    FieldName: 'Field name',
    DataType: 'Data type',
    Settings: 'Settings',
    ConfidenceScore: 'Confidence score',
    selectModel: 'Select Document model',
    Geography: 'Geography',
    ChooseModel: 'Choose a document model',
    AddField: 'Add a field',
    EnterField: 'Enter Field name',
    EnterValue: 'Enter Value',
    RejectFile: 'Reject file',
    ApproveFile: 'Approve file',
    ApproveFileWithError: 'Approve with error',
    FlagFile: 'Flag file',
    VerifyAccount: 'Verify your account',
    VerifyText: 'Verify',
    SettingsText: 'Settings',
    AccountSettingsText: 'Account settings',
    TeamMembersText: 'Team members',
    NameText: 'Name',
    RoleText: 'Role',
    ProfilePictureText: 'Profile Picture',
    AddUserText: 'Add User',
    EditUserText: 'Edit User',
    DocModelAccess: 'Document model access',
    InvalidRole: 'Please select Role',
    Welcone: 'Welcome to Docscience!',
    WelcomeCreatePass: 'You’ve been added to Docscience by John Doe. Please create your password below to get started.',
    UploadComplete: 'Upload Complete',
    ActivityLogs: 'Activity Logs',
    UserAddedSuccess: 'User has been added successfully',
    APIERROR: 'Something went wrong. Please try again later',
    UpdatePassword: 'Update Password',
    NewPassword: 'New Password',
    EnterNewPassword: 'Enter New Password',
    NewPasswordError: 'Please enter new password',
    RepeatNewPassword: 'Repeat New Password',
    EnterRepeatNewPassword: 'Enter Repeat New Password',
    RepeatPasswordError: 'Please enter repeat password',
    CurrentPassword: 'Current Password',
    InvalidPassMatchChangePass: 'New Password and Repeat New Password do not match',
    InvalidPassMatchRegexChangePass:
        'New Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (@$!%*?&).',
    Edit: 'Edit',
    ReviewAgain: 'Review Again',
    ModelSettings: 'Model Settings',
    GeneralSettings: 'General Settings',
    FieldSettings: 'Field Settings',
    ModelName: 'Model name',
    ModelNamePlaceholder: 'Enter Model name',
    InvalidModelName: 'Please enter First name',
    ModelId: 'Model ID',
    AssignedUsers: 'Assigned Users',
    AssignedUsersPlaceholder: 'Select Assigned Users',
    NotifyUsers: 'Notify users when documents are ready',
    PreProcessing: 'Pre-processing',
    OrientationCorrection: 'Orientation correction',
    AutoAdjust: 'Automatically adjust the orientation of the documents',
    TitlCorrection: 'Title correction',
    AutoAdjustSkew: 'Automatically adjust the documents if it is skewed',
    DocAutoSplit: 'Document auto split',
    SplitPage: 'Split the documents based on the pages',
    DELETE_USER_HEADER: 'Delete user',
    DELETE_USER_BODY: 'Are you sure you want to remove this user?',
    ERROR_OCCURRED: 'An error occurred. Please try again later',
    USER_UPDATED_SUCCESS: 'User has been updated successfully',
    SettingsUpdateSuccess: 'Settings updated successfully',
    MandatoryField: 'Mandatory field',
    ConfidenceScorePlaceholder: 'Enter Confidence score',
    ValidationRules: 'Validation rules',
    ValidationRulesPlaceholder: 'Enter Validation rules',
    CustomPostProcessing: 'Custom post-processing',
    CustomPostProcessingPlaceholder: 'Enter Custom post-processing',
    ForgotPassword: 'Forgot Password?',
    VerifyEmail: 'Verify your email',
    EnterVerificationCode: 'Enter the verification code',
    ResetPass: 'Reset your password',
    VerifyEmailSubHeader: 'We will send a verification code to your email ID to help you gain access to your account',
    VerificationCodeSubHeader1: 'We’ve emailed you a six digit code to confirm your account at ',
    VerificationCodeSubHeader2: 'Please enter the code here to verify the email.',
    SendVerificationCode: 'Send verification code',
    UpdatePassAndLogin: 'Update password and login',
    Credits: 'Credits',
    CreditsExhausted: 'Credits exhausted',
    CreditExhausted: 'Your credits have been exhausted.',
    RequestCredit: 'Request more credits',
    ReqCredits: 'Request credits',
    AddCreditInfo: 'Enter the number of credits you require and our team will get in touch with you with the steps.',
};

export const ROUTES_CONTENT = [
    {
        path: '/',
        name: 'Document models',
        imgSrc: DocModels,
        hasChild: true,
    },
    {
        path: '/myDocuments',
        imgSrc: AllDocs,
        name: 'My documents',
    },
];
export const ROUTES_CONTENT_USER = [
    {
        path: '/settings',
        name: 'Settings',
        imgSrc: Settings,
    },
];

export const TABLE_HEADERS = ['Status', 'Model name', 'Date Created', 'Created by', ''];
export const DOCUMENT_TABLE_HEADERS = ['Status', 'File name', 'Document models', 'Upload date', 'Uploaded by', ''];
export const DOCUMENT_TABS = ['All files', 'For review', 'Flagged', 'Approved', 'Rejected', 'Failed'];
export const ACTIVITY_LOGS_TABS = ['All Activity', 'Document', 'User', 'Credit'];
export const DOC_STATUS = [
    '',
    'Pending_For_Review',
    'Flagged',
    'Approved',
    'Rejected',
    'Failed',
    'Approved_With_Error',
];
export const STRING_DATA_TYPE = ['String'];
export const TABLE_DATA_TYPE = ['Table'];
export const DIRECTIONS = ['n', 's', 'e', 'w'];

export const DocumentTypes = [
    { key: 'Other', value: 'Other' },
    { key: 'Identification', value: 'Identification' },
    { key: 'Mortgage', value: 'Mortgage' },
    { key: 'Billing', value: 'Billing' },
    { key: 'Income', value: 'Income' },
    { key: 'Tax Forms', value: 'Tax Forms' },
    { key: 'Govt Forms', value: 'Govt Forms' },
];
