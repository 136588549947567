import { combineReducers } from 'redux';

import loginData from '../containers/Login/reducer';
import userData from '../containers/Settings/reducer';
import { loginManagementApi } from '../containers/Login/apiService';
import docModelsData from '../containers/DocumentModels/reducer';
import { docModelsApi } from '../containers/DocumentModels/apiService';
import { docModelsUploadApi } from '../containers/DocumentModels/uploadService';
import { userManagementApi } from '../containers/Settings/apiService';
import { modelSettingsApi } from '../containers/ModelSettings/apiService';
import modelSettingsData from '../containers/ModelSettings/reducer';

const appReducer = combineReducers({
    loginData,
    [loginManagementApi.reducerPath]: loginManagementApi.reducer,
    userData,
    [userManagementApi.reducerPath]: userManagementApi.reducer,
    docModelsData,
    [docModelsApi.reducerPath]: docModelsApi.reducer,
    [docModelsUploadApi.reducerPath]: docModelsUploadApi.reducer,
    [modelSettingsApi.reducerPath]: modelSettingsApi.reducer,
    modelSettingsData,
});

export const reducer = (state, action) => appReducer(state, action);

export const middlewares = [
    docModelsApi.middleware,
    loginManagementApi.middleware,
    docModelsUploadApi.middleware,
    userManagementApi.middleware,
    modelSettingsApi.middleware,
];
