import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';

import { useGetDocumentsForModelMutation, useGetDocumentsTypeMutation } from '../DocumentModels/apiService';
import { useUploadDocumentsForModelMutation, useUploadStatusMutation } from '../DocumentModels/uploadService';
import LayoutContent from '../../components/LayoutContent';
import UploadDocModal from '../../components/UploadDocModal';
import { setCurrentModel, updateDocs } from '../DocumentModels/reducer';
import DocsTable from './DocsTable';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';
import { processPDFFile } from '../../utils/functions';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';

export default function Documents() {
    const { currentModel, documents } = useSelector(state => state.docModelsData);
    const { documentId } = useSelector(state => state.docModelsData);
    const [getDocumentsForModel, { isLoading }] = useGetDocumentsForModelMutation();
    const [getDocumentsType, { isLoading: modelLoader }] = useGetDocumentsTypeMutation();
    const [uploadDocumentsForModel, { isLoading: fileLoader }] = useUploadDocumentsForModelMutation();
    const [uploadStatus] = useUploadStatusMutation();
    const dispatch = useDispatch();
    const { modelId } = useParams();
    const [showLoader, toggleLoader] = React.useState(true);
    const [showAddNewModel, toggleAddNewModel] = React.useState(false);

    const getCurrentDocuments = modelName => {
        getDocumentsForModel({ currentModel: modelName });
    };

    React.useEffect(() => {
        if (currentModel) {
            getCurrentDocuments(currentModel);
        } else {
            getDocumentsType().then(({ data = {} }) => {
                const { documents: docModelsData } = data;
                const { Name = '' } = docModelsData?.find(({ id }) => id === parseInt(modelId)) || {};
                if (Name) {
                    dispatch(setCurrentModel(Name));
                    getCurrentDocuments(Name);
                }
            });
        }
        toggleLoader(false);
    }, []);

    const closeModal = () => {
        toggleAddNewModel(false);
    };

    const uploadNewDocCall = async files => {
        let formData = new FormData();
        let docInfo = {
            DocumentInfo: [],
        };

        for (let _ of files) {
            formData.append('files', _);
            const info = await processPDFFile(_);
            if (info) {
                docInfo.DocumentInfo.push(info);
            }
        }
        formData.append('document_types', currentModel);
        formData.append('docInfo', JSON.stringify(docInfo));

        uploadDocumentsForModel({ formData }).then(() => {
            closeModal();
            getCurrentDocuments(currentModel);
            uploadStatus(documentId);
        });
    };

    return (
        <LayoutContent
            showLoader={isLoading || modelLoader}
            title={currentModel}
            headerItemsRight={
                <div className="rightButtons">
                    <Button onClick={() => toggleAddNewModel(true)} className="secondaryBtn">
                        {SITE_TEXT.uploadText}
                    </Button>
                    <Button disabled className="secondaryBtn">
                        {SITE_TEXT.downloadDataText}
                    </Button>
                    <Button disabled>{SITE_TEXT.reviewText}</Button>
                </div>
            }
            headerItemsLeft={
                <NavLink to={'settings'}>
                    <Button className="secondaryBtn" sx={{ marginLeft: '16px', border: '1px solid #B4E8E4' }}>
                        <SettingsIcon /> {SITE_TEXT.Settings}
                    </Button>
                </NavLink>
            }>
            <div className="documentsWrap" key={modelId}>
                <DocsTable apiLoading={isLoading || showLoader} documents={documents} updateData={updateDocs} />
                {showAddNewModel && (
                    <UploadDocModal
                        showLoader={fileLoader}
                        onClose={closeModal}
                        leftButtonHandler={closeModal}
                        rightButtonHandler={uploadNewDocCall}
                    />
                )}
            </div>
        </LayoutContent>
    );
}
