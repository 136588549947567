import React from 'react';
import c from 'classnames';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useLoginStatusMutation } from './apiService';
import { resetError } from './reducer';
import Loader from '../../components/Loader';
import { SITE_TEXT } from '../../utils/constants';
import { validateEmail } from '../../utils/functions';
import './index.scss';
import UpdatePasswordDialog from './UpdatePasswordDialog';

import PasswordField from '../../components/common/PasswordField';
import packageJson from '../../../package.json';

export default function LoginContent({
    updateSignUp,
    setSignupStep,
    setOtpAuthenticated,
    setEmail,
    setShowForgetPass,
}) {
    const [loginStatus, { isLoading }] = useLoginStatusMutation();
    const { errorInfo } = useSelector(state => state.loginData);

    const [openResetPassDialog, setOpenResetPassDialog] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(resetError());
    }, []);

    const [body, updateParams] = React.useState({
        Email: '',
        Password: '',
    });

    const [errors, updateErrors] = React.useState({
        Email: false,
        Password: false,
    });

    const handleData = ({ name, value }) => {
        updateParams({
            ...body,
            [name]: value,
        });
        updateErrors({
            ...errors,
            [name]: false,
        });
    };

    const goToSignUp = () => updateSignUp(true);

    const callLoginApi = async e => {
        e.preventDefault();
        const { Email, Password } = body;
        const emailError = !validateEmail(Email);
        updateErrors({
            Email: emailError,
            Password: !Password,
        });

        if (Password && !emailError) {
            const resp = await loginStatus({ body });
            if (resp.data?.Output === 'User not confirmed!!') {
                setSignupStep(4);
                setOtpAuthenticated(false);
                updateSignUp(true);
                setEmail(body.Email);
            }
            if (resp.data?.Output === 'NEW_PASSWORD_REQUIRED') {
                setOpenResetPassDialog(true);
            }
        }
    };

    return (
        <>
            <h1>{SITE_TEXT.LoginText}</h1>

            <form onSubmit={callLoginApi}>
                {isLoading && <Loader position="fixed" />}

                <div
                    className={c('inputWrap', {
                        errorWrap: errors.Email,
                    })}>
                    <label>{SITE_TEXT.EmailText}</label>
                    <TextField
                        fullWidth
                        name="Email"
                        value={body.Email}
                        onChange={({ target }) => handleData(target)}
                        placeholder={SITE_TEXT.EmailPlaceholder}
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                    {errors.Email && <div className="warning">{SITE_TEXT.InvalidEmail}</div>}
                </div>
                <div
                    className={c('inputWrap', {
                        errorWrap: errors.Password,
                    })}>
                    <label>{SITE_TEXT.PasswordText}</label>
                    <PasswordField
                        fullWidth
                        name="Password"
                        value={body.Password}
                        onChange={({ target }) => handleData(target)}
                        placeholder={SITE_TEXT.PasswordPlaceholder}
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                    {errors.Password && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                </div>
                <div className="bottomMenu">
                    <div className="forgotPassword" onClick={() => setShowForgetPass(true)}>
                        {SITE_TEXT.ForgotPassword}
                    </div>
                </div>
                <Button type="submit" fullWidth>
                    {SITE_TEXT.LoginBtnText}
                </Button>
                {errorInfo && <h2>{errorInfo}</h2>}
                <div className="infoWrap">
                    Don’t have an account? <span onClick={goToSignUp}>Create an account</span>
                </div>
                <UpdatePasswordDialog
                    open={openResetPassDialog}
                    TemporaryPassword={body.Password}
                    UserName={body.Email}
                />
            </form>
            <div
                style={{
                    position: 'fixed',
                    bottom: '10px',
                    left: '0',
                    textAlign: 'center',
                    width: '100%',
                    fontSize: '10px',
                    color: '#a0a0a0',
                }}>
                Version: {packageJson.version}
            </div>
        </>
    );
}

LoginContent.propTypes = {
    updateSignUp: PropTypes.func.isRequired,
    setSignupStep: PropTypes.func.isRequired,
    setOtpAuthenticated: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    setShowForgetPass: PropTypes.func.isRequired,
};
