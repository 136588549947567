import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as pdfjsLib from 'pdfjs-dist/webpack';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const PdfViewer = ({ pdfUrl, currentPage, numPages, setNumPages }) => {
    const [pdfDoc, setPdfDoc] = useState(null);
    const scale = 1;
    const canvasRef = useRef();

    const renderPage = pageNum => {
        if (!pdfDoc || pageNum < 1 || pageNum > numPages) {
            return;
        }

        pdfDoc.getPage(pageNum).then(page => {
            const viewport = page.getViewport({ scale });
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: ctx,
                viewport: viewport,
            };
            page.render(renderContext).promise.then(() => {});
        });
    };

    useEffect(() => {
        // Use this effect to render the first page after the component has mounted
        renderPage(currentPage);
    }, [pdfDoc, currentPage, numPages]);

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const loadedPdfDoc = await pdfjsLib.getDocument(pdfUrl).promise;
                setPdfDoc(loadedPdfDoc);
                setNumPages(loadedPdfDoc.numPages);
            } catch (error) {
                // eslint-disable-next-line
                console.error('Error loading PDF', error);
            }
        };
        loadPdf();
    }, []);

    return (
        <div className="pdfViewer">
            <canvas ref={canvasRef}></canvas>
        </div>
    );
};
PdfViewer.propTypes = {
    pdfUrl: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    setNumPages: PropTypes.func.isRequired,
};

export default PdfViewer;
