import React, { useEffect } from 'react';
import './index.scss';
import { SITE_TEXT } from '../../utils/constants';
import { ReactComponent as Cross } from '../../assets/x.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UploadStatusPopover = () => {
    const [open, setOpen] = React.useState(true);
    const [showPopover, setShowPopover] = React.useState(true);
    const location = useLocation();

    const { uploadStatusList } = useSelector(state => state.docModelsData);

    const [uploading, setUploading] = React.useState(false);

    useEffect(() => {
        const status = uploadStatusList?.flatMap(_ => _.Status);
        if (status?.includes('Processing') || status?.includes('In_Queue')) {
            setUploading(true);
        } else {
            setUploading(false);
        }
    }, [uploadStatusList]);

    if (location.pathname.includes('/reviewDocument/')) {
        return null;
    }

    return showPopover && uploadStatusList?.length > 0 ? (
        <div className="uploadStatusPopoverContainer">
            <div className={`uploadStatusHeader ${open && 'open'}`}>
                {uploading
                    ? `Uploading ${uploadStatusList?.length} document${uploadStatusList?.length > 1 ? 's' : ''}`
                    : SITE_TEXT.UploadComplete}
                <div>
                    <ChevronDown onClick={() => setOpen(!open)} className={`chevron ${open && 'open'}`} />
                    <Cross onClick={() => setShowPopover(false)} />
                </div>
            </div>
            <div className={`uploadStatusBody ${open && 'open'}`}>
                {uploadStatusList?.map((_, i) => {
                    return (
                        <div className="bodyItem" key={i}>
                            <div className="name">{_.DocumentName}</div>
                            <div className={`statusWrap ${_.Status}`}>{_.Status}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    ) : null;
};

export default UploadStatusPopover;
