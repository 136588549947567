import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import './index.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { DocumentTypes, SITE_TEXT } from '../../utils/constants';
import { CustomSelect } from '../../components/common/Select';
import AutoComplete from '../../components/common/AutoComplete';
import { useAddUserMutation, useGetUserDetailsMutation, useUpdateUserMutation } from './apiService';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from '../../utils/functions';
import Loader from '../../components/Loader';
import { resetError } from './reducer';
import AuthStore from './../../components/common/AuthStore';
import Toastr from '../../components/common/Toastr';

const RolesList = [
    { key: 'Admin', value: 'Admin' },
    { key: 'Member', value: 'Member' },
];

const AddUserDialog = ({ open, setOpen, user, userEditMode = false }) => {
    const [addUser, { isLoading }] = useAddUserMutation();
    const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();
    const [getUserDetails] = useGetUserDetailsMutation();

    const { userAddError, userAddSuccess } = useSelector(state => state.userData);
    const dispatch = useDispatch();

    const [openToast, setOpenToast] = React.useState(false);

    const getSelectedModels = () => {
        if (userEditMode && user?.Models) {
            return DocumentTypes.filter(docType => user.Models.includes(docType.value));
        }
        return [];
    };

    const [body, updateParams] = React.useState({
        FirstName: userEditMode ? user?.FirstName : '',
        LastName: userEditMode ? user?.LastName : '',
        Email: userEditMode ? user?.Email : '',
        DocumentType: userEditMode ? getSelectedModels() : [],
        Role: RolesList[0].value,
        Company: '',
    });

    const [errors, updateErrors] = React.useState({
        FirstName: false,
        LastName: false,
        Email: false,
        DocumentType: false,
        Role: false,
        Company: false,
    });

    React.useEffect(() => {
        dispatch(resetError());
    }, []);

    const handleData = ({ name, value }) => {
        updateParams(old => ({
            ...old,
            [name]: value,
        }));
        updateErrors(old => ({
            ...old,
            [name]: false,
        }));
    };

    const handleRoleChange = e => {
        handleData({ name: 'Role', value: e });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const { FirstName, LastName, Email, Role, DocumentType, Company } = body;

        const emailError = !validateEmail(Email);
        updateErrors({
            FirstName: !FirstName,
            LastName: !LastName,
            Email: emailError,
            DocumentType: !DocumentType,
            Role: !Role,
            Company: !Company,
        });
        const { userName } = AuthStore;

        let tempBody = {
            FirstName: body.FirstName,
            LastName: body.LastName,
            Email: body.Email,
            DocumentType: body.DocumentType?.map(_ => _.value),
            JobRole: body.Role,
            Company: 'Test Company', // TODO: Need to update from user API
            Admin: userName,
        };
        if (
            tempBody.FirstName &&
            tempBody.LastName &&
            !emailError &&
            tempBody.DocumentType &&
            tempBody.JobRole &&
            tempBody.Company
        ) {
            const user = await addUser(tempBody);
            if (user?.data?.Output === 'Successfully Added New User!!') {
                updateParams({
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    DocumentType: [],
                    Role: RolesList[0].value,
                    Company: '',
                });
                handleClose();
                setOpenToast(true);
                getUserDetails();
            }
        }
    };

    const handleSubmitEdit = async e => {
        e.preventDefault();
        const { FirstName, LastName, Email, Role, DocumentType, Company } = body;

        const emailError = !validateEmail(Email);
        updateErrors({
            FirstName: !FirstName,
            LastName: !LastName,
            Email: emailError,
            DocumentType: !DocumentType,
            Role: !Role,
            Company: !Company,
        });
        const { userName } = AuthStore;

        function getRemovedValues(mainArray, newArray) {
            const newValues = newArray.map(item => item.value);
            return mainArray.filter(value => !newValues.includes(value));
        }

        let tempBody = {
            FirstName: body.FirstName,
            LastName: body.LastName,
            Email: body.Email,
            DocumentTypeAdd: body.DocumentType?.filter(item => !user.Models.includes(item.value))?.map(_ => _.value),
            DocumentTypeDelete: getRemovedValues(user.Models, body.DocumentType),
            JobRole: body.Role,
            Company: 'Test Company', // TODO: Need to update from user API
            Admin: userName,
        };
        if (tempBody.FirstName && tempBody.LastName && !emailError && tempBody.JobRole && tempBody.Company) {
            const user = await updateUser(tempBody);
            if (user?.data?.message) {
                updateParams({
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    DocumentType: [],
                    Role: RolesList[0].value,
                    Company: '',
                });
                handleClose();
                setOpenToast(true);
                getUserDetails();
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth className="addUserDialog">
                <DialogTitle onClose={handleClose} className="dialogTitle">
                    {userEditMode ? SITE_TEXT.EditUserText : SITE_TEXT.AddUserText}
                    {setOpen && (
                        <span
                            onClick={handleClose}
                            style={{ position: 'absolute', right: 24, top: 12, cursor: 'pointer' }}>
                            &times;
                        </span>
                    )}
                </DialogTitle>
                <DialogContent className="dialogContainer">
                    {(isLoading || updateLoading) && <Loader position="fixed" />}

                    <div className="halfWidthWrap clearfix">
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.FirstName,
                            })}>
                            <label>{SITE_TEXT.FnameText}</label>
                            <TextField
                                fullWidth
                                name="FirstName"
                                value={body.FirstName}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.FnamePlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.FirstName && <div className="warning">{SITE_TEXT.InvalidFName}</div>}
                        </div>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.LastName,
                            })}>
                            <label>{SITE_TEXT.LnameText}</label>
                            <TextField
                                fullWidth
                                name="LastName"
                                value={body.LastName}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.LnamePlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.LastName && <div className="warning">{SITE_TEXT.InvalidLName}</div>}
                        </div>
                    </div>

                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Email,
                        })}>
                        <label>{SITE_TEXT.EmailText}</label>
                        <TextField
                            fullWidth
                            name="Email"
                            value={body.Email}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.EmailPlaceholder}
                            inputProps={{ maxLength: 100 }}
                            disabled={userEditMode}
                        />
                        {errors.Email && <div className="warning">{SITE_TEXT.InvalidEmail}</div>}
                    </div>

                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Role,
                        })}>
                        <label>{SITE_TEXT.RoleText}</label>

                        <CustomSelect
                            options={RolesList}
                            handleChange={handleRoleChange}
                            showLabel={false}
                            showCircle={false}
                            fullWidth
                            answer={body.Role}
                        />
                        {errors.Role && <div className="warning">{SITE_TEXT.InvalidRole}</div>}
                    </div>

                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.DocumentType,
                        })}>
                        <label>{SITE_TEXT.DocModelAccess}</label>

                        <AutoComplete
                            updateValue={value => {
                                return handleData({ value, name: 'DocumentType' });
                            }}
                            options={DocumentTypes}
                            name="DocumentType"
                            placeholder={SITE_TEXT.ChooseDocumentPlaceholder}
                            value={body.DocumentType ? body.DocumentType : []}
                        />
                        {errors.DocumentType && <div className="warning">{SITE_TEXT.InvalidDocument}</div>}
                    </div>
                    {userAddError && <h2 className="error">{userAddError}</h2>}
                    {userAddSuccess && <h2 className="success">{userAddSuccess}</h2>}
                </DialogContent>
                <DialogActions style={{ padding: '24px', paddingTop: 0 }}>
                    <Button fullWidth variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button fullWidth onClick={userEditMode ? handleSubmitEdit : handleSubmit}>
                        {userEditMode ? SITE_TEXT.EditUserText : SITE_TEXT.AddUserText}
                    </Button>
                </DialogActions>
            </Dialog>
            <Toastr
                message={userEditMode ? SITE_TEXT.USER_UPDATED_SUCCESS : SITE_TEXT.UserAddedSuccess}
                open={openToast}
                setOpen={setOpenToast}
                type="success"
                autoHideDuration={4000}
            />
        </>
    );
};

export default AddUserDialog;

AddUserDialog.propTypes = {
    setOpen: PropTypes.func.isRequired,
    open: PropTypes.any.isRequired,
    user: PropTypes.any,
    userEditMode: PropTypes.bool,
};
