import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_TEXT } from '../../utils/constants';
import './index.scss';
import { Button } from '@mui/material';
import { ReactComponent as User } from '../../assets/user.svg';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '../../components/common/Avatar';
import { ReactComponent as Trash } from '../../assets/trash_grey.svg';
import { ReactComponent as Edit } from '../../assets/edit_grey.svg';
import AddUserDialog from './AddUserDialog';
import ConfirmModal from '../../components/ConfirmModal';
import { useDeleteUserMutation } from './apiService';
import Toastr from '../../components/common/Toastr';
import { setTeamList } from './reducer';

const TeamMembers = () => {
    const [membersCount, setMembersCount] = React.useState(0);
    const [openAddUserDialog, setOpenAddUserDialog] = React.useState(false);

    const [showConfirmModal, toggleConfirmModal] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState('');
    const [toast, setToast] = React.useState({
        msg: '',
        type: '',
    });
    const [openToast, setOpenToast] = React.useState(false);

    const [deleteUser, { isLoading: deleteUserLoading }] = useDeleteUserMutation();

    const [userEditMode, setUserEditMode] = React.useState(false);

    const { teamList } = useSelector(state => state.userData);

    const [rows, setRows] = React.useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setMembersCount(teamList?.length);
    }, []);

    useEffect(() => {
        setRows(
            teamList?.map((_, i) => {
                return {
                    ..._,
                    id: i,
                };
            })
        );
    }, [teamList]);

    const openEditPopup = user => {
        setUserEditMode(true);
        setSelectedUser(user);
        setOpenAddUserDialog(true);
    };

    const openDeleteModal = user => {
        toggleConfirmModal(true);
        setSelectedUser(user);
    };

    const closeModal = () => {
        toggleConfirmModal(false);
    };

    const deleteSelectedUser = async () => {
        deleteUser({ user: selectedUser.Email })
            .then(data => {
                if (data.error) {
                    setOpenToast(true);
                    setToast({
                        msg: data.error.data?.detail || SITE_TEXT.ERROR_OCCURRED,
                        type: 'error',
                    });
                    return;
                }
                closeModal();
                setOpenToast(true);
                setToast({
                    msg: data.data.Message,
                    type: 'success',
                });
                dispatch(setTeamList(teamList.filter(_ => _.Email !== selectedUser.Email)));
            })
            .catch(() => {
                setOpenToast(true);
                setToast({
                    msg: SITE_TEXT.ERROR_OCCURRED,
                    type: 'error',
                });
            });
    };

    const columns = [
        {
            headerName: 'Name',
            width: 300,
            renderCell: params => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <Avatar
                        name={`${params.row?.Name?.split(' ')[0]?.slice(0, 1)}${params.row?.Name?.split(' ')[1]?.slice(
                            0,
                            1
                        )}`}
                        height={40}
                        width={40}
                    />{' '}
                    {params.row.Name}
                </div>
            ),
        },
        { field: 'Role', headerName: 'Role', width: 150 },
        {
            field: 'Models',
            headerName: 'Models',
            flex: 1,
            renderCell: params => {
                const extra = params.row?.Models?.length - 2;
                return (
                    <div className="chipGroup">
                        {params.row?.Models[0] && <span className="chip first">{params.row?.Models[0]}</span>}
                        {params.row?.Models[1] && <span className="chip second">{params.row?.Models[1]}</span>}
                        {extra > 0 && <span className="chip extra">+{extra}</span>}
                    </div>
                );
            },
        },
        {
            field: 'edit',
            headerName: '',
            width: 90,
            renderCell: params => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}>
                    <Edit className="iconsAction" onClick={() => openEditPopup(params.row)} />
                    <Trash className="iconsAction" onClick={() => openDeleteModal(params.row)} />
                </div>
            ),
        },
    ];

    return (
        <div className="settingsContentWrapper teamMember">
            <div id="subHead" className="subHead">
                <h2>
                    {SITE_TEXT.TeamMembersText}
                    <span className="countMembers">{membersCount} users</span>
                </h2>
                <Button
                    onClick={() => {
                        setUserEditMode(false);
                        setOpenAddUserDialog(true);
                    }}>
                    <User />
                    {SITE_TEXT.AddUserText}
                </Button>
            </div>
            <div className="mainContainer">
                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        // checkboxSelection
                        disableColumnResize
                        disableColumnMenu
                        disableColumnSorting
                    />
                </div>
            </div>
            {openAddUserDialog && (
                <AddUserDialog
                    open={openAddUserDialog}
                    setOpen={setOpenAddUserDialog}
                    user={selectedUser}
                    userEditMode={userEditMode}
                />
            )}
            {showConfirmModal && (
                <ConfirmModal
                    showLoader={deleteUserLoading}
                    onClose={closeModal}
                    modalHeader={SITE_TEXT.DELETE_USER_HEADER}
                    modalDescription={SITE_TEXT.DELETE_USER_BODY}
                    leftButtonText={SITE_TEXT.Cancel}
                    leftButtonHandler={closeModal}
                    rightButtonText={SITE_TEXT.Delete}
                    rightButtonHandler={deleteSelectedUser}
                />
            )}
            <Toastr
                message={toast.msg}
                open={openToast}
                setOpen={setOpenToast}
                type={toast.type}
                autoHideDuration={4000}
            />
        </div>
    );
};

export default TeamMembers;
