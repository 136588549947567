import React from 'react';
import c from 'classnames';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import Loader from '../../../components/Loader';
import { SITE_TEXT } from '../../../utils/constants';
import './../index.scss';
import loginHead from '../../../assets/logo.svg';
import { isPasswordValid, validateEmail } from '../../../utils/functions';
import PhoneNumber from '../../../components/common/PhoneNumberField';
import PasswordField from '../../../components/common/PasswordField';

export default function SignupStep1({
    updateSignUp,
    signUpError,
    body,
    handleData,
    setSignupStep,
    isLoading,
    errors,
    updateErrors,
}) {
    const goToSignUp = () => updateSignUp(false);

    const onSubmit = e => {
        e.preventDefault();
        const { FirstName, LastName, Email, Password, RePassword, Phone } = body;

        const emailError = !validateEmail(Email);
        const passNoMatch = Password !== RePassword;
        const invalidPass = !isPasswordValid(Password);

        updateErrors({
            FirstName: !FirstName,
            LastName: !LastName,
            Email: emailError,
            Password: !Password,
            RePassword: !RePassword,
            Phone: !Phone,
            passNoMatch,
            invalidPass,
        });

        if (FirstName && LastName && !emailError && Password && RePassword && Phone && !passNoMatch && !invalidPass) {
            setSignupStep(2);
        }
    };

    return (
        <main className="wrapper">
            <div className="loginWrapper">
                <div className="loginHead">
                    <img src={loginHead} alt="" />
                </div>
                <h1>{SITE_TEXT.SingUpText}</h1>
                <form onSubmit={onSubmit}>
                    {isLoading && <Loader position="fixed" />}

                    <div className="halfWidthWrap clearfix">
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.FirstName,
                            })}>
                            <label>{SITE_TEXT.FnameText}</label>
                            <TextField
                                fullWidth
                                name="FirstName"
                                value={body.FirstName}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.FnamePlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.FirstName && <div className="warning">{SITE_TEXT.InvalidFName}</div>}
                        </div>
                        <div
                            className={c('inputWrap', {
                                errorWrap: errors.LastName,
                            })}>
                            <label>{SITE_TEXT.LnameText}</label>
                            <TextField
                                fullWidth
                                name="LastName"
                                value={body.LastName}
                                onChange={({ target }) => handleData(target)}
                                placeholder={SITE_TEXT.LnamePlaceholder}
                                inputProps={{ maxLength: 100 }}
                            />
                            {errors.LastName && <div className="warning">{SITE_TEXT.InvalidLName}</div>}
                        </div>
                    </div>
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Email,
                        })}>
                        <label>{SITE_TEXT.EmailText}</label>
                        <TextField
                            fullWidth
                            name="Email"
                            value={body.Email}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.EmailPlaceholder}
                            inputProps={{ maxLength: 100 }}
                        />
                        {errors.Email && <div className="warning">{SITE_TEXT.InvalidEmail}</div>}
                    </div>
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Phone || errors.passNoMatch,
                        })}>
                        <label>{SITE_TEXT.PhoneText}</label>
                        <PhoneNumber
                            fullWidth
                            name="Phone"
                            value={body.Phone}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.PhonePlaceholder}
                            inputProps={{ maxLength: 100 }}
                        />
                        {errors.Phone && <div className="warning">{SITE_TEXT.InvalidPhone}</div>}
                    </div>
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.Password,
                        })}>
                        <label>{SITE_TEXT.PasswordText}</label>
                        <TextField
                            fullWidth
                            type="password"
                            name="Password"
                            value={body.Password}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.PasswordPlaceholder}
                            inputProps={{ maxLength: 100 }}
                        />
                        {errors.Password && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                    </div>
                    <div
                        className={c('inputWrap', {
                            errorWrap: errors.RePassword || errors.passNoMatch,
                        })}>
                        <label>{SITE_TEXT.RePasswordText}</label>
                        <PasswordField
                            fullWidth
                            type="password"
                            name="RePassword"
                            value={body.RePassword}
                            onChange={({ target }) => handleData(target)}
                            placeholder={SITE_TEXT.RePasswordText}
                            inputProps={{ maxLength: 100 }}
                        />
                        {errors.RePassword && <div className="warning">{SITE_TEXT.InvalidPassword}</div>}
                        {!errors.RePassword && errors.passNoMatch && (
                            <div className="warning">{SITE_TEXT.InvalidPassMatch}</div>
                        )}

                        {!errors.RePassword && errors.invalidPass && (
                            <div className="warning">{SITE_TEXT.InvalidPassMatchRegex}</div>
                        )}
                    </div>
                    <Button type="submit" onClick={onSubmit} fullWidth>
                        {SITE_TEXT.SignupBtnText}
                    </Button>
                    {signUpError && <h2>{signUpError}</h2>}
                    <div className="infoWrap">
                        Already have an account? <span onClick={goToSignUp}>Login here</span>
                    </div>
                </form>
            </div>
        </main>
    );
}

SignupStep1.propTypes = {
    updateSignUp: PropTypes.func.isRequired,
    signUpError: PropTypes.any.isRequired,
    body: PropTypes.any.isRequired,
    handleData: PropTypes.any.isRequired,
    setSignupStep: PropTypes.any.isRequired,
    signUpStatus: PropTypes.any.isRequired,
    isLoading: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    updateErrors: PropTypes.any.isRequired,
};
