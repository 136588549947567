import { API_BASE_PATH } from './constants/env';

const Endpoints = {
    apiBasePath: API_BASE_PATH,
    loginPath: '/user/login',
    signUpPath: '/user/signup',
    getDocumentsTypePath: '/getDocumentsType',
    extractDocPath: '/extract',
    extractDocInfoPath: '/extractInfo',
    getDocumentsPath: '/getDocuments',
    getAllDocumentsPath: '/allDocuments',
    doUploadPath: '/document/upload',
    updateDocTypePath: '/documentTypesUpdate',
    getDocumentModelsPath: '/getDocumentModels',
    deleteDocumentModelsPath: '/deleteDocumentModels',
    deleteDocumentPath: '/deleteDocument',
    updateCustomizedFieldsPath: '/update/customizedfields',
    updateStatusForDocPath: '/statusUpdate',
    authenticate: '/user/authenticate',
    resendCode: '/resendCode',
    addUser: '/user/add',
    updatePassword: '/user/updatePassword',
    userDetails: '/user/details',
    refreshToken: '/user/refreshToken',
    activityLogs: '/activityLogs',
    documentStatus: '/user/documentStatus',
    resetPassword: '/user/resetPassword',
    editModel: '/editDocumentModel',
    editDoc: '/editDocumentName',
    getModelSettings: '/user/getSettings',
    modelSettings: '/user/modelSettings',
    fieldSettings: '/user/fieldSettings',
    deleteUser: '/user/delete',
    editUserDetails: '/user/editUserDetails',
    forgotPassword: '/user/forgotPassword',
    confirmForgotPassword: '/user/confirmForgotPassword',
};

export default Endpoints;
