import React from 'react';

import loginHead from '../../assets/logo.svg';
import LoginContent from './LoginContent';

import './index.scss';
import SignUpContent from './Signup/SignUpContent';
import ForgotPassword from './ForgotPassword';

export default function LoginContainer() {
    const [showSignUp, updateSignUp] = React.useState(false);
    const [showForgotPass, setShowForgetPass] = React.useState(false);

    const [email, setEmail] = React.useState('');
    const [signupStep, setSignupStep] = React.useState(1);
    const [otpAuthenticated, setOtpAuthenticated] = React.useState(false);

    return showSignUp ? (
        <SignUpContent
            updateSignUp={updateSignUp}
            signupStep={signupStep}
            setSignupStep={setSignupStep}
            otpAuthenticated={otpAuthenticated}
            setOtpAuthenticated={setOtpAuthenticated}
            email={email}
        />
    ) : showForgotPass ? (
        <ForgotPassword setShowForgetPass={setShowForgetPass} />
    ) : (
        <main className="wrapper">
            <div className="loginWrapper">
                <div className="loginHead">
                    <img src={loginHead} alt="" />
                </div>
                <LoginContent
                    updateSignUp={updateSignUp}
                    setShowForgetPass={setShowForgetPass}
                    setSignupStep={setSignupStep}
                    setOtpAuthenticated={setOtpAuthenticated}
                    setEmail={setEmail}
                />
            </div>
        </main>
    );
}
