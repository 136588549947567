/* eslint-disable react/prop-types */
import * as React from 'react';
// import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip } from '@mui/material';

// option format
// [
//     { key: 'Other', value: 'Other' },
//     { key: 'Identification', value: 'Identification' },
// ]

export default function AutoComplete({ value, updateValue, options, placeholder }) {
    const handleChange = (event, newValue) => {
        updateValue(newValue);
    };

    return (
        <Autocomplete
            multiple
            id="checkboxes-tags"
            options={options}
            disableCloseOnSelect
            getOptionLabel={option => option.key}
            renderOption={(props, option) => (
                <li {...props}>
                    {/* <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} /> */}
                    {option.key}
                </li>
            )}
            fullWidth
            renderInput={params => <TextField {...params} style={{ height: '32px' }} placeholder={placeholder} />}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={index}
                        variant="outlined"
                        label={option.key}
                        {...getTagProps({ index })}
                        onDelete={null}
                        style={{
                            backgroundColor: '#EEFBFA',
                            color: '#038E83',
                            height: '22px',
                            border: 0,
                            fontSize: '12px',
                            lineHeight: '18px',
                        }}
                    />
                ))
            }
            onChange={handleChange}
            value={value}
            limitTags={3}
            size="small"
            style={{ height: '44px' }}
        />
    );
}
