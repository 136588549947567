import React from 'react';
import { Switch as MUISwitch } from '@mui/material';
import styled from 'styled-components';

export const Switch = styled(props => <MUISwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    () => ({
        width: 36,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#04B2A4',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#04B2A4',
                border: '4px solid #fff',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: 'background-color 500 linear',
        },
    })
);
